import * as states from './states'
// import * as MT from './mutation-types'

function capitalize(string) {
  return string.charAt(0).toUpperCase() + string.slice(1)
}

export default {
  state: () => ({
    regions: states.regions,
    selectedRegions: states.defaultVal.region,

    specializations: states.specializations,
    selectedSpecializations: states.defaultVal.specialization,

    cubeSpecs: states.cubeSpecs,
    selectedCubeSpecs: states.defaultVal.cubeSpec
  }),

  mutations: {
    init: (state, { name, values }) => {
      const allItems = state[name + 's']
      const selectedItems = 'selected' + capitalize(name) + 's'

      state[selectedItems] = allItems.filter((item) =>
        values.includes(item.value)
      )
    },

    toggleOne: (state, { name, val }) => {
      const selectedItems = 'selected' + capitalize(name) + 's'
      const alreadySelected = state[selectedItems]
        .map((item) => item.value)
        .includes(val)

      if (alreadySelected) {
        state[selectedItems] = state[selectedItems].filter(
          (item) => item.value !== val
        )
      } else {
        state[selectedItems] = [
          ...state[selectedItems],
          state[name + 's'].find((item) => item.value === val)
        ]
      }
    },

    selectAll: (state, name) => {
      const selectedItems = 'selected' + capitalize(name) + 's'
      const allItems = state[name + 's']
      state[selectedItems] = allItems
    },

    selectNone: (state, name) => {
      const selectedItems = 'selected' + capitalize(name) + 's'
      state[selectedItems] = []
    },

    resetSelection: (state, name) => {
      const selectedItems = 'selected' + capitalize(name) + 's'
      state[selectedItems] = states.defaultVal[name]
    }
  }
}
