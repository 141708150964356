
<script>
import mixins from '../mixins'

export default {

  name: 'Consent',
  mixins: [mixins],

  data() {
    return {

      show: true,
      showDetails: false,
      closed: false
    }
  },

  watch: {

    $route(to, from) {
      this.closed = false
    }
  },

  mounted() {
    this.show = !this.analytics
  },

  computed: {

    analytics: {

      get() {
        return this.$store.getters.analyticsConsent
      },

      set(value) {
        this.$store.dispatch('analyticsConsent', value)
      }
    }
  },

  methods: {

    setAll() {
      this.analytics = true
      this.show = false
    },

    setRequired() {
      this.analytics = false
      this.show = false
    }
  }

}

</script>

<template>

  <div>

    <transition name="fade">
      <div class="consent" v-if="show && !closed">

        <div class="close" @click="closed=true"><div class="fa fa-times"></div></div>

        <div v-if="showDetails" class="container py-4">
          <div class="d-flex flex-row align-items-start">
            <div class="mr-auto">
              <h2 class="mb-1">Tracking-/Cookie-Einstellungen</h2>
              <p>Hier finden Sie eine Übersicht über alle Kategorien. Sie können Ihre Zustimmung zu einzelnen Kategorien auswählen.</p>
            </div>
            <div>
              <span class="btn btn-primary" @click="show=false">Auswahl speichern</span>
            </div>
          </div>
          <div class="d-flex flex-row bg-gray p-3 mb-2">
            <div class="mr-auto">
              <strong>Notwendige Cookies</strong><br>Diese Cookies sind für die korrekte Anzeige und Funktion unserer Webseite technisch notwendig und können nicht abgewählt werden.
            </div>
            <div class="ml-2">
              <label class="switch mb-0 disabled" for="switch_required">
                <input type="checkbox" id="switch_required" checked disabled>
                <span class="toggle"></span>
              </label>
            </div>
          </div>
          <div class="d-flex flex-row bg-gray p-3 mb-2">
            <div class="mr-auto">
              <strong>Analyse Cookies</strong><br>Diese Cookies ermöglichen es uns die Analyse, wie Besucher mit unseren Webseiten interagieren, indem Informationen anonymisiert gesammelt und ausgewertet werden.
            </div>
            <div class="ml-2">
              <label class="switch mb-0" for="switch_analytics">
                <input type="checkbox" id="switch_analytics" v-model="analytics">
                <span class="toggle"></span>
              </label>
            </div>
          </div>
          <div class="d-flex flex-row">
            <div class="mr-auto">
              <router-link :to="{name:'imprint'}" class="mr-2">Impressum</router-link>
              <router-link :to="{name:'privacy'}">Datenschutzerklärung</router-link>
            </div>
            <div>
              <span class="btn btn-outline-primary btn-sm" @click="showDetails=false">Einstellungen schließen</span>
            </div>
          </div>
        </div>

        <div v-else class="container py-4">

          <div class="d-flex flex-row align-items-start">
            <div class="mr-auto">
              <h2>Tracking-/Cookie-Einstellungen</h2>
            </div>
            <span class="btn btn-primary" @click="setAll">Alle akzeptieren</span>
          </div>

          <p>Wir setzen auf unserer Website Cookies und andere Tracking-Technologien ein. Einige von ihnen sind technisch notwendig, während andere uns helfen unser Onlineangebot zu verbessern und wirtschaftlich zu betreiben sowie Ihnen weitere personalisierte Services von intermedix anbieten zu können. Sie können die technisch nicht notwendigen Cookies akzeptieren oder per Klick auf “Nur notwendige Cookies akzeptieren" ablehnen sowie diese Einstellungen jederzeit nachträglich in der <router-link :to="{name:'privacy', hash:'#analytics'}">Datenschutzerklärung</router-link> abwählen.</p>

          <div class="d-flex flex-row">
            <div class="mr-auto">
              <router-link :to="{name:'imprint'}" class="mr-2">Impressum</router-link>
              <router-link :to="{name:'privacy'}">Datenschutzerklärung</router-link>
            </div>
            <div>
              <span class="btn btn-outline-primary btn-sm mr-2" @click="setRequired">Nur notwendige Cookies akzeptieren</span>
              <span class="btn btn-outline-primary btn-sm" @click="showDetails=true">Individuelle Einstellungen</span>
            </div>
          </div>

        </div>

      </div>
    </transition>
  </div>

</template>
