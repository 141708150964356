import { SpecializationGroup as SG } from '../data'

export const regions = [
  { value: 'Baden-Württemberg', desc: 'Baden-Württemberg' },
  { value: 'Bayern', desc: 'Bayern' },
  { value: 'Berlin', desc: 'Berlin' },
  { value: 'Brandenburg', desc: 'Brandenburg' },
  { value: 'Bremen', desc: 'Bremen' },
  { value: 'Hamburg', desc: 'Hamburg' },
  { value: 'Hessen', desc: 'Hessen' },
  { value: 'Mecklenburg-Vorpommern', desc: 'Mecklenburg-Vorpommern' },
  { value: 'Niedersachsen', desc: 'Niedersachsen' },
  { value: 'Nordrhein', desc: 'Nordrhein' },
  { value: 'Rheinland-Pfalz', desc: 'Rheinland-Pfalz' },
  { value: 'Saarland', desc: 'Saarland' },
  { value: 'Sachsen', desc: 'Sachsen' },
  { value: 'Sachsen-Anhalt', desc: 'Sachsen-Anhalt' },
  { value: 'Schleswig-Holstein', desc: 'Schleswig-Holstein' },
  { value: 'Thüringen', desc: 'Thüringen' },
  { value: 'Westfalen-Lippe', desc: 'Westfalen-Lippe' }
]

export const cubeSpecs = [
  {
    value: SG.HAPI,
    desc: 'HAPI',
    since: 'Januar 2020'
  },
  {
    value: SG.FRAUENHEILKUNDE,
    desc: 'Frauenheilkunde',
    since: 'Januar 2020'
  },
  {
    value: SG.HNO,
    desc: 'Hals-Nasen-Ohrenheilkunde',
    since: 'Januar 2020'
  },
  {
    value: SG.GESCHLECHTSKRANKHEITEN_UND_DERMATOLOGIE,
    desc: 'Haut- und Geschlechtskrankheiten',
    since: 'Januar 2020'
  },
  {
    value: SG.KINDERARZT,
    desc: 'Kinderarzt (Hausarzt)',
    since: 'Januar 2020'
  },
  {
    value: SG.ORTHOPAEDIE,
    desc: 'Orthopädie',
    since: 'Januar 2020'
  },
  {
    value: SG.UROLOGIE,
    desc: 'Urologie',
    since: 'Januar 2020'
  }
]

export const specializations = [
  {
    value: SG.HAPI,
    desc: 'HAPI',
    since: 'Januar 2021'
  },
  {
    value: SG.FRAUENHEILKUNDE,
    desc: 'Frauenheilkunde',
    since: 'Januar 2021'
  },
  {
    value: SG.AUGENHEILKUNDE,
    desc: 'Augenheilkunde (beta)',
    since: 'November 2021'
  },
  {
    value: SG.GESCHLECHTSKRANKHEITEN_UND_DERMATOLOGIE,
    desc: 'Haut- und Geschlechtskrankheiten (beta)',
    since: 'November 2021'
  },
  {
    value: SG.ORTHOPAEDIE,
    desc: 'Orthopädie (beta)',
    since: 'November 2021'
  },
  {
    value: SG.PNEUMOLOGIE,
    desc: 'Pneumologie (beta)',
    since: 'Juli 2021'
  }
]

export const defaultVal = {
  specialization: [specializations[0]],
  cubeSpec: [cubeSpecs[0]],
  region: regions
}
