
<script>

import Page from './Page'
import ContactForm from '../components/ContactForm'

export default {

  name: 'Studies',
  components: { ContactForm },
  extends: Page
}

</script>

<template>

  <div class="page">
    <div class="screen bg-gradient form-light flex-center text-left">
      <div class="container my-4">
        <div class="row">
          <div class="col-12 col-lg-6">
            <h2>Vielen Dank für Ihr Interesse an unserem maßgeschneiderten Studienangebot. Nehmen Sie hier Kontakt mit uns auf, wenn Sie an Spezialanalysen unserer Daten interessiert sind.</h2>
          </div>
          <div class="col-12 col-lg-6">
            <ContactForm :showDemoOption="false"></ContactForm>
          </div>
        </div>
      </div>
    </div>
  </div>

</template>
