
<script>

export default {

  name: 'Slide',

  props: ['name', 'open'],

  data() {
    return {

      isOpen: false,
      transition: null
    }
  },

  created() {
    this.isOpen = this.open
  }

}

</script>

<template>

  <transition :name="transition">
    <div class="slide" v-if="isOpen">
      <slot></slot>
    </div>
  </transition>

</template>
