
<script>

import { mapGetters, mapState } from 'vuex'
import debounce from 'lodash.debounce'
import moment from 'moment'
import XLSX from 'xlsx'
import JsPDF from 'jspdf'
import html2canvas from 'html2canvas'
import Highcharts from 'highcharts'
import HighchartsMore from 'highcharts/highcharts-more'

import Api from '../Api'
import Page from './Page'
import theme from '../theme'
import { addFilterToExport } from './StatisticDetail/utils'
import BenchmarkSearch from '../components/BenchmarkSearch'
import MultiChoiceFilter from '../components/filters/MultiChoiceFilter'
import SpecInfo from '../components/SpecInfo'
import FavoriteBtn from '../components/FavoriteBtn'

require('highcharts/modules/exporting')(Highcharts)
HighchartsMore(Highcharts)

const MIN_DATE = '2020-01-01'

export default {
  name: 'BenchmarksDetail',
  components: { FavoriteBtn, MultiChoiceFilter, BenchmarkSearch, SpecInfo },
  extends: Page,

  data() {
    return {
      entity: null,
      showSearch: false,
      chartType: 'line',
      showExport: false,
      instance: null,
      isLoading: false,
      isEmpty: false,
      minDate: MIN_DATE,

      agegroup: null,
      gender: null,
      timeinterval: 'month',
      startDate: null,
      endDate: null,
      groupby: 'provider_id',
      extrapolate: true,
      type: 'units',
      salesRegulation: null,
      prescription_type: null,
      items: [],

      icds: [],
      selectedIcds: [],
      icdSearch: null,

      groups: ['provider_id', 'combined_product_id', 'product_id', 'pzn', 'atc3', 'atc4', 'atc5', 'atc7'],
      types: ['units', 'revenue'],
      prescription_types: ['Arztempfehlung/Grünes Rezept', 'GKV mit Zuzahlung', 'GKV ohne Zuzahlung', 'Normalverkauf', 'PC/Sprechstundenbedarf', 'PKV'],
      chartData: null
    }
  },

  mounted() {
    Highcharts.setOptions(theme)

    this.entity = this.$route.params.entity.toLowerCase()

    const filter = this.$route.query.filter
    this.filter = filter ? this.decodeHash(filter) : {}

    if (!this.items.length) {
      this.showSearch = true
    }

    this.setMinDate()
  },

  watch: {
    chartType(val) {
      if (val !== 'table') {
        this.updateChartData()
      }
    },

    filter: function() {
      this.updateChartData()
    },

    icdSearch: function() {
      this.searchIcd()
    }
  },

  computed: {
    user() {
      return this.$store.state.user.instance
    },
    ...mapGetters(['agegroups', 'genders', 'timeintervals']),

    ...mapState({
      selectedRegions: state => state.filter.selectedRegions,
      selectedSpecializations: state => state.filter.selectedSpecializations
    }),

    date1() {
      return this.startDate ? moment(this.startDate).format('YYYY-MM-DD') : null
    },

    date2() {
      return this.endDate ? moment(this.endDate).format('YYYY-MM-DD') : null
    },

    filter: {

      get() {
        if (this.entity === 'sales') {
          return {
            region: this.selectedRegions.map(item => item.value).join(','),
            date1: this.date1,
            date2: this.date2,
            timeinterval: this.timeinterval,
            items: this.items,
            groupby: this.groupby,
            extrapolate: this.extrapolate,
            type: this.type,
            salesregulation: this.salesRegulation,
            prescription_type: this.prescription_type
          }
        } else if (this.entity === 'prescription') {
          return {
            specialization: this.selectedSpecializations.map(item => item.value).join(','),
            agegroup: this.agegroup,
            gender: this.gender,
            date1: this.date1,
            date2: this.date2,
            timeinterval: this.timeinterval,
            items: this.items,
            groupby: this.groupby,
            salesregulation: this.salesRegulation,
            icd: this.selectedIcds.length ? this.selectedIcds.join(',') : null
          }
        } else { // diagnosis
          return {
            specialization: this.selectedSpecializations.map(item => item.value).join(','),
            agegroup: this.agegroup,
            gender: this.gender,
            date1: this.date1,
            date2: this.date2,
            timeinterval: this.timeinterval,
            items: this.items,
            groupby: this.groupby
          }
        }
      },

      set(val) {
        this.agegroup = val.agegroup || null
        this.gender = val.gender || null
        this.startDate = val.date1 || '2021-01-01'
        this.endDate = val.date2 || moment().isoWeekday(0).format('YYYY-MM-DD')
        this.timeinterval = val.timeinterval || 'month'
        this.items = val.items || []
        this.extrapolate = val.extrapolate || true
        this.type = val.type || 'units'
        this.icd = val.icd || null
        this.selectedIcds = val.icd?.split(',') || []
        this.salesRegulation = val.salesregulation || null
        this.prescription_type = val.prescription_type || null
      }
    },

    today() {
      return moment().format('YYYY-MM-DD')
    },

    formattedStartDate() {
      return this.startDate ? moment(this.startDate).format('DD.MM.YYYY') : null
    },

    formattedEndDate() {
      return this.endDate ? moment(this.endDate).format('DD.MM.YYYY') : null
    },

    title() {
      return this.$t('benchmarks.titles.' + this.entity)
    },

    subtitle() {
      return this.$t('benchmarks.subtitles.' + this.entity)
    },

    groupTitle() {
      if (this.entity === 'diagnosis') {
        return 'ICD'
      } else {
        return this.$t('benchmarks.groups.' + this.groupby)
      }
    },

    tableData() {
      let head = []
      const body = []

      if (this.entity === 'diagnosis') {
        head = [this.groupTitle, ...this.chartData.xAxis.categories]

        this.chartData.series.forEach(serie => {
          body.push([serie.name, ...serie.data])
        })
      } else {
        head = [this.groupTitle, ...this.chartData.xAxis.categories]

        this.chartData.series.forEach(serie => {
          body.push([serie.name, ...serie.data])
        })
      }

      return { head, body }
    },

    icdSearchTitle() {
      if (!this.selectedIcds.length) {
        return this.$t('filter.labels.none')
      }

      if (this.selectedIcds.length === this.icds.length) {
        return this.$t('filter.labels.all') + ' (' + this.selectedIcds.length + ')'
      }

      if (this.selectedIcds.length === 1) {
        return this.selectedIcds[0]
      }

      return this.$t('filter.labels.some') + ' (' + this.selectedIcds.length + ')'
    }
  },

  methods: {

    searchIcd: debounce(function() {
      if (!this.icdSearch || this.icdSearch.length < 2) {
        this.icds = []
        return
      }

      const data = {

        q: this.icdSearch,
        entity: 'ICD',
        size: 200
      }

      Api.search.suggest(data).then(response => {
        this.icds = response.data
      })
    }, 150),

    setItems(data) {
      this.items = data.items
      this.showSearch = false

      this.updateChartData()
    },

    updateChartData() {
      if (this.isLoading) return
      if (!this.items.length) return

      const invalidDateRange = this.filter.date1 > this.filter.date2
      if (invalidDateRange) return

      const isTable = this.chartType === 'table'
      if (isTable) {
        this.chartType = 'line'
      }

      this.isLoading = true

      if (this.instance) {
        this.instance.destroy()
        this.instance = null
      }

      const filter = Object.assign({}, this.filter)

      if (this.entity === 'sales') {
        if (!filter.region) {
          this.isLoading = false
          this.isEmpty = true
          return
        }
      }

      if (this.entity !== 'sales') {
        if (!filter.specialization) {
          this.isLoading = false
          this.isEmpty = true
          return
        }
      }

      if (this.entity === 'diagnosis') {
        filter.icd = filter.items.join(',')
        delete filter.groupby
      } else {
        filter.pzn = filter.items.join(',')
      }

      delete filter.items

      Api.benchmarks.chart(this.entity, filter).then(response => {
        this.$router.replace({ query: { filter: this.encodeHash(this.filter) } })
          .catch(() => {})

        this.chartData = response.data
        this.isEmpty = !response.data || !response.data.series.length

        this.isLoading = false

        const options = {
          chart: {
            type: this.chartType,
            height: 600
          },
          credits: {
            text: 'Quelle: docmetric'
          },
          yAxis: {
            min: 0
          },
          series: response.data.series,
          xAxis: response.data.xAxis,
          tooltip: {
            valueDecimals: 0
          }
        }

        if (this.chartType === 'column') {
          options.plotOptions = {
            series: {
              stacking: 'normal'
            }
          }
        }

        options.xAxis.type = 'category'

        const colors = ['rgb(90,137,205)', '#19355c', '#0e3540', '#1d4b85', '#185959', '#3d67a4', '#348785', '#6289c6', '#74ada9', '#a3c9ec', '#abdae0', '#e7e7e8']

        options.series.forEach((item, i) => {
          item.color = colors[i % colors.length]
          if (this.chartType === 'column') {
            item.data = item.data.map(num => +num.toFixed())
          }
        })

        this.instance = Highcharts.chart(this.$refs.placeholder, options)

        if (isTable) this.chartType = 'table'
      }).catch(err => {
        this.isEmpty = true
        console.error(err)
      })
    },

    formatNumber(val) {
      return new Intl.NumberFormat('de-DE', { style: 'decimal', minimumFractionDigits: 0, maximumFractionDigits: 0 }).format(val)
    },

    exportXls() {
      const filterCopy = { ...this.filter }
      if (this.entity === 'diagnosis') delete filterCopy.groupby
      const filters = addFilterToExport(
        filterCopy,
        this.selectedSpecializations,
        this.selectedRegions
      )

      const data = [[this.title], [this.subtitle], [], filters.head, ...filters.body, [], this.tableData.head, ...this.tableData.body]

      const xls = XLSX.utils.book_new()

      const sheet = XLSX.utils.json_to_sheet(data, { skipHeader: true })
      sheet['!cols'] = [{ wch: 90 }]

      XLSX.utils.book_append_sheet(xls, sheet, this.title.replace(/[^\w\s]/gi, '').substring(0, 30))
      XLSX.writeFile(xls, 'ixsight_benchmarks.xlsx')
    },

    exportCsv() {
      const filters = addFilterToExport(
        this.filter,
        this.selectedSpecializations,
        this.selectedRegions
      )

      const data = [filters.head, ...filters.body, [], this.tableData.head, ...this.tableData.body]

      let csv = ''

      data.forEach(row => {
        row.forEach(cell => {
          csv += (cell || '') + ';'
        })

        csv += '\n'
      })

      const link = this.$refs.exportLink

      link.href = 'data:text/csv;charset=utf-8,' + encodeURI(csv)
      link.download = 'ixsight_benchmarks.csv'
      link.click()
    },

    exportCustom(format) {
      this.showExport = true

      const margin = 40
      const outerWidth = 1600
      const outerHeight = (format === 'pdf') ? (842 * 2) : 860
      const innerWidth = outerWidth - (margin * 2)
      const innerHeight = outerHeight - (margin * 2)

      const filterWidth = Math.floor(innerWidth * 0.20)
      const filterHeight = 720

      const chartWidth = Math.floor(innerWidth * 0.60) - (margin * 2)
      const chartHeight = 720

      const entityTypeWidth = Math.floor(innerWidth * 0.20) - (margin * 2)
      const entityTypeHeight = 720

      const tableWidth = innerWidth - (margin * 2)
      const tableHeight = 480

      this.$nextTick(() => {
        this.$refs.exportTable.style.width = tableWidth + 'px'

        const canvas = this.$refs.exportCanvas
        const link = this.$refs.exportLink
        const ctx = canvas.getContext('2d')
        const dom = window.URL || window.webkitURL || window

        canvas.width = innerWidth
        canvas.height = innerHeight

        ctx.fillStyle = 'white'
        ctx.fillRect(0, 0, innerWidth, innerHeight)

        const options = {
          chart: {
            width: chartWidth,
            height: chartHeight,
            spacing: 0
          },
          title: {
            text: this.title + ' | ' + this.groupTitle
          },
          subtitle: {
            enabled: true,
            text: this.subtitle
          }
        }

        let chart = this.instance.getSVG(options)
        chart = new Blob([chart], { type: 'image/svg+xml' })
        chart = dom.createObjectURL(chart)

        const chartImg = new Image()
        chartImg.src = chart

        chartImg.onload = () => {
          ctx.drawImage(chartImg, filterWidth + margin, margin, chartWidth, chartHeight)
          dom.revokeObjectURL(chart)

          const filename = 'ixsight_benchmarks'

          html2canvas(
            this.$refs.exportFilter,
            { width: filterWidth, height: filterHeight, scrollX: 0, scrollY: 0 }
          ).then(filterCanvas => {
            ctx.drawImage(filterCanvas, margin, margin, filterWidth, filterHeight)

            html2canvas(
              this.$refs.entityType,
              { width: entityTypeWidth, height: entityTypeHeight, scrollX: 0, scrollY: 0 }
            ).then(entityTypeCanvas => {
              ctx.drawImage(entityTypeCanvas, margin * 2 + filterWidth + chartWidth, margin, entityTypeWidth, entityTypeHeight)

              if (format === 'pdf') {
                html2canvas(
                  this.$refs.exportTable,
                  { width: tableWidth, height: tableHeight, scrollX: 0, scrollY: 0 }
                ).then(res => {
                  ctx.drawImage(res, margin, chartHeight + margin + margin, tableWidth, tableHeight)

                  const doc = new JsPDF({
                    format: [outerWidth / 2, outerHeight / 2],
                    unit: 'px',
                    compression: 'NONE'
                  })

                  doc.addImage(canvas.toDataURL('image/png'), 'PNG', 0, 0, outerWidth / 2, outerHeight / 2)
                  doc.save(filename + '.pdf')
                })
              }

              if (format === 'png') {
                link.href = canvas.toDataURL('image/png')
                link.download = filename + '.png'
                link.click()
              }

              if (format === 'jpg') {
                link.href = canvas.toDataURL('image/jpeg')
                link.download = filename + '.jpg'
                link.click()
              }

              this.showExport = false
            })
          })
        }
      })
    },

    handleSalesSwitch(type) {
      this.type = type
      this.setMinDate()
    },

    setDisabledEndDate(date) {
      return date < new Date(this.startDate) || date > new Date()
    },

    setDisabledStartDate(date) {
      return date < new Date(this.minDate) || date > new Date(this.endDate)
    },

    setMinDate() {
      if (this.entity === 'sales') {
        this.minDate = MIN_DATE
        if (!this.startDate) this.startDate = this.minDate
      }
    }
  }

}

</script>

<template>

  <LayoutDetail class="benchmarks" v-if="user.check">

    <template v-slot:side>

      <div class="text-right mb-4">
        <router-link :to="{name:'benchmarks.index'}" class="btn btn-outline-secondary btn-sm">
          <i class="fa fa-angle-left mr-1"></i>{{ $t('labels.back') }}
        </router-link>
      </div>

      <div class="flex-row mb-4">
        <h3 class="m-0">{{ $t('statistics.filter') }}</h3>
      </div>

      <span class="btn btn-secondary btn-block" @click="showSearch=true">{{ $t('benchmarks.open_search') }} ({{ items.length }})</span>

      <br>

      <div class="form-group mt-4" v-if="entity==='sales'">
        <label class="switch" for="extrapolate">
          <input type="checkbox" id="extrapolate" v-model="extrapolate">
          <span class="toggle"></span>
        </label>
        <div>
          <span class="text nowrap">{{ $t('filter.labels.extrapolate') }}</span>
          <span class="help ml-1" :title="$t('filter.infos.extrapolate')">
            <i class="fa fa-question-circle"></i>
          </span>
        </div>
      </div>

      <div class="form-group">
        <label for="select_enddate">{{ $t('filter.labels.startdate') }}:</label>
        <date-picker
          v-if="isSafari"
          v-model="startDate"
          :append-to-body="false"
          :clearable="false"
          :disabled-date="setDisabledStartDate"
          format="YYYY-MM-DD"
          input-class="form-control"
          lang="de"
          required
          valueType="format"
        />
        <input
          v-else
          v-model="startDate"
          class="form-control"
          id="select_startdate"
          :min="minDate"
          :max="endDate"
          required
          type="date"
        >
      </div>

      <div class="form-group">
        <label for="select_enddate">{{ $t('filter.labels.enddate') }}:</label>
        <date-picker
          v-if="isSafari"
          v-model="endDate"
          :append-to-body="false"
          :clearable="false"
          :disabled-date="setDisabledEndDate"
          format="YYYY-MM-DD"
          input-class="form-control"
          lang="de"
          required
          valueType="format"
        />
        <input
          v-else
          v-model="endDate"
          type="date"
          class="form-control"
          id="select_enddate"
          :min="startDate"
          :max="today"
          required
        >
      </div>

      <div class="form-group">
        <label for="select_timeinterval">{{ $t('filter.labels.timeinterval') }}:</label>
        <div class="custom-select-wrapper">
          <select class="custom-select" id="select_timeinterval" v-model="timeinterval">
            <option
              v-for="value in timeintervals"
              :value="value"
              :key="value"
            >{{ $t('filter.timeintervals.'+value) }}</option>
          </select>
        </div>
      </div>

      <br>

      <div class="form-group" v-if="entity!=='sales'">
        <MultiChoiceFilter name="specialization" />
      </div>

      <div class="form-group" v-if="entity==='prescription'">
        <label>{{ $t('filter.labels.icd') }}:</label>
        <Dropdown dropdownId="icdDD" :title="icdSearchTitle" :preventClose="true" look="form-control select-dropdown">
          <div class="d-flex flex-row justify-content-around">
            <span class="lightblue text-small text-link" @click="selectedIcds=icds.map(item => item.code)">{{ $t('filter.labels.select_all') }}</span>
            <span class="lightblue text-small text-link" @click="selectedIcds=[]">{{ $t('filter.labels.select_none') }}</span>
          </div>
          <div class="form-white mt-2">
            <input type="text" class="form-control form-control-sm" v-model="icdSearch" placeholder="Suche...">
          </div>
          <div class="custom-control custom-checkbox" v-for="icd in icds" :key="icd.code">
            <input type="checkbox" class="custom-control-input" :id="'check_'+icd.code" :value="icd.code" v-model="selectedIcds">
            <label class="custom-control-label" :for="'check_'+icd.code">{{ icd.code }} | {{ icd.name }}</label>
          </div>
        </Dropdown>
      </div>

      <div class="form-group" v-if="entity==='sales'">
        <MultiChoiceFilter name="region" />
      </div>

      <div class="form-group" v-if="entity!=='sales'">
        <label for="select_agegroup">{{ $t('filter.labels.agegroup') }}:</label>
        <div class="custom-select-wrapper">
          <select class="custom-select" id="select_agegroup" v-model="agegroup">
            <option :value="null">{{ $t('filter.labels.all') }}</option>
            <option v-for="value in agegroups" :value="value" :key="value">{{ $t('filter.agegroups.'+value) }}</option>
          </select>
        </div>
      </div>

      <div class="form-group" v-if="entity!=='sales'">
        <label for="select_gender">{{ $t('filter.labels.gender') }}:</label>
        <div class="custom-select-wrapper">
          <select class="custom-select" id="select_gender" v-model="gender">
            <option :value="null">{{ $t('filter.labels.all') }}</option>
            <option v-for="value in genders" :value="value" :key="value">{{ $t('filter.genders.'+value) }}</option>
          </select>
        </div>
      </div>

      <div class="form-group" v-if="entity!=='diagnosis'">
        <label for="select_salesregulation">{{ $t('filter.labels.salesRegulation') }}:</label>
        <div class="custom-select-wrapper">
          <select class="custom-select" id="select_salesregulation" v-model="salesRegulation">
            <option :value="null">{{ $t('filter.labels.all') }}</option>
            <option v-for="value in $store.getters.salesRegulations" :value="value" :key="value">{{ $t('filter.salesRegulations.'+value) }}</option>
          </select>
        </div>
      </div>

      <div class="form-group" v-if="entity==='sales'">
        <label for="select_prescription_type">{{ $t('filter.labels.prescription_type') }}:</label>
        <div class="custom-select-wrapper">
          <select class="custom-select" id="select_prescription_type" v-model="prescription_type">
            <option :value="null">{{ $t('filter.labels.all') }}</option>
            <option v-for="value in prescription_types" :value="value" :key="value">{{ value }}</option>
          </select>
        </div>
      </div>

    </template>

    <template v-slot:main v-if="entity">

      <a ref="exportLink"></a>
      <div class="export-container" v-if="showExport">
        <div ref="exportFilter">

          <img src="../assets/images/ixsightnow-logo.png" alt="ixsight" width="250">
          <!--<div class="claim">ixsight Benchmarks</div>-->

          <h3>Filter:</h3>

          <div class="form-group" v-if="entity==='sales' && extrapolate">
            <div class="value">{{ $t('filter.labels.extrapolate') }} aktiv</div>
          </div>

          <div class="form-group" v-if="entity==='prescription' && icd">
            <div class="label">{{ $t('filter.labels.icd') }}:</div>
            <div class="value">{{ icd.code }}</div>
          </div>

          <div class="form-group">
            <div class="label">{{ $t('filter.labels.startdate') }}:</div>
            <div class="value">{{ formattedStartDate }}</div>
          </div>

          <div class="form-group">
            <div class="label">{{ $t('filter.labels.enddate') }}:</div>
            <div class="value">{{ formattedEndDate }}</div>
          </div>

          <div class="form-group">
            <div class="label">{{ $t('filter.labels.timeinterval') }}:</div>
            <div class="value">{{ $t('filter.timeintervals.'+timeinterval) }}</div>
          </div>

          <div class="form-group" v-if="entity!=='sales'">
            <div class="label">{{ $t('filter.labels.specialization') }}:</div>
            <div class="value multi-val">
              {{ selectedSpecializations.join(', ') }}
            </div>
          </div>

          <div class="form-group" v-if="entity==='sales'">
            <div class="label">{{ $t('filter.labels.region') }}:</div>
            <div class="value multi-val">
              {{ selectedRegions.join(', ') }}
            </div>
          </div>

          <div class="form-group" v-if="entity!=='sales'">
            <div class="label">{{ $t('filter.labels.agegroup') }}:</div>
            <div class="value">{{ agegroup ? $t('filter.agegroups.'+agegroup) : $t('filter.labels.all') }}</div>
          </div>

          <div class="form-group" v-if="entity!=='sales'">
            <div class="label">{{ $t('filter.labels.gender') }}:</div>
            <div class="value">{{ gender ?  $t('filter.genders.'+gender) : $t('filter.labels.all') }}</div>
          </div>

        </div>
        <canvas ref="exportCanvas"></canvas>
        <div ref="exportTable">
          <div class="export-table">
            <div class="box">
              <table class="table table-sm table-striped">
                <thead>
                <tr>
                  <th :class="!i ? 'title' : 'value'" v-for="(cell, i) in tableData.head" :key="i">{{ cell }}</th>
                </tr>
                </thead>
                <tbody>
                <tr v-for="(row, j) in tableData.body" :key="j">
                  <td :class="!i ? 'title' : 'value'" v-for="(cell,i) in row" :key="i">
                    <span v-if="!i">{{ cell }}</span>
                    <span v-else>{{ formatNumber(cell) }}</span>
                  </td>
                </tr>
                </tbody>
                <tfoot>
                <tr>
                  <td :class="!i ? 'title' : 'value'" v-for="(cell, i) in tableData.foot" :key="i">
                    <span v-if="!i">{{ cell }}</span>
                    <span v-else>{{ formatNumber(cell) }}</span>
                  </td>
                </tr>
                </tfoot>
              </table>
            </div>
          </div>
        </div>
        <div ref="entityType" class="export-entities">
          <h3>Was Sie sehen:</h3>

          <div class="export-entities__sales" v-if="entity==='sales'">
            <span :class="{ 'export-active': type === 'units'}" >{{ $t('benchmarks.types.units') }}</span>
            <span :class="{ 'export-active': type === 'revenue'}" >{{ $t('benchmarks.types.revenue') }}</span>
          </div>
          <div class="export-entities__divider"></div>

          <div class="export-entities__list">
            <span
              v-for="(group, index) in groups"
              :key="'facets_' + index"
              :class="{ 'export-active': group === groupby }"
            >
              {{ $t('benchmarks.groups.' + group) }}
              <template v-if="chartData">({{ chartData.facets[group] }})</template>
            </span>
          </div>

        </div>
      </div>

      <div class="row">
        <div class="col-12 col-xl-8 col-xxl-9">

          <div class="loading flex-center" v-if="isLoading">
            <i class="fa fa-spinner fa-pulse fa-4x fa-fw"></i>
          </div>

          <div v-if="isEmpty" class="card card-body">
            <div class="text-center" >
              <i class="fa fa-search fa-3x mb-3"></i>
              <p class="lead">{{ $t('benchmarks.no_data') }}</p>
              <span class="btn btn-secondary" @click="showSearch=true">{{ $t('benchmarks.open_search') }}</span>
            </div>
          </div>

          <div v-show="chartData && !isEmpty" class="card card-body rel">

            <div class="flex-row align-items-start">
              <div class="mr-5">
                <h2 class="mb-2">{{ title }}</h2>
                <p class="text-gray">{{ subtitle }}</p>
              </div>
              <div class="ml-auto">
                <div class="btn-group">
                  <span
                    class="btn btn-outline-light btn-sm"
                    :class="{ active: chartType === 'table' }"
                    @click="chartType= 'table'"
                  ><i class="fa fa-table"></i>
                  </span>
                  <span
                    class="btn btn-outline-light btn-sm"
                    :class="{ active: chartType === 'line' }"
                    @click="chartType= 'line'"
                  >
                    <i class="fa fa-area-chart"></i>
                  </span>
                  <span
                    class="btn btn-outline-light btn-sm"
                    :class="{ active: chartType === 'column' }"
                    @click="chartType= 'column'"
                  >
                    <i class="fa fa-bar-chart"></i>
                  </span>
                </div>

                <br>
                <FavoriteBtn
                  :filters-str="JSON.stringify(filter)"
                  :title="title"
                />
              </div>
            </div>

            <div v-if="chartType === 'table'" class="data-table">
              <div class="table-responsive">
                <table class="table table-sm table-striped">
                  <thead>
                  <tr>
                    <th :class="!i ? 'title' : 'value'" v-for="(cell, i) in tableData.head" :key="i">{{ cell || '&nbsp;' }}</th>
                  </tr>
                  </thead>
                  <tbody>
                  <tr v-for="(row, i) in tableData.body" :key="i">
                    <td :class="!i ? 'title' : 'value'" v-for="(cell, i) in row" :key="i">
                      <span v-if="!i">{{ cell }}</span>
                      <span v-else>{{ formatNumber(cell) }}</span>
                    </td>
                  </tr>
                  </tbody>
                </table>
              </div>
            </div>

            <div v-show="chartType !== 'table'" class="highchart-container">
              <div ref="placeholder"></div>
            </div>

            <div class="flex-row mt-2">
              <div class="btn-group">
                <template v-if="!chartType">
                  <span class="btn btn-sm btn-outline-light" @click="exportCustom('pdf')"><i class="fa fa-file-pdf-o mr-1"></i> PDF</span>
                  <span class="btn btn-sm btn-outline-light" @click="exportCustom('png')"><i class="fa fa-file-image-o mr-1"></i> PNG</span>
                  <span class="btn btn-sm btn-outline-light" @click="exportCustom('jpg')"><i class="fa fa-file-image-o mr-1"></i> JPG</span>
                </template>
                <span class="btn btn-sm btn-outline-light" @click="exportXls"><i class="fa fa-file-excel-o mr-1"></i> XLSX</span>
                <span class="btn btn-sm btn-outline-light" @click="exportCsv"><i class="fa fa-file-code-o mr-1"></i> CSV</span>
              </div>
            </div>

          </div>
        </div>
        <div class="col-12 col-xl-4 col-xxl-3">

          <div class="card card-body" v-if="chartData && !isEmpty && entity!=='diagnosis'">
            <h3>{{ $t('benchmarks.group_title') }}</h3>

            <div class="btn-group bmd__switch" v-if="entity==='sales'">
              <span class="btn btn-outline-light" :class="{active: type === 'units' }" @click="handleSalesSwitch('units')">{{ $t('benchmarks.types.units') }}</span>
              <span class="btn btn-outline-light" :class="{active: type === 'revenue'}" @click="handleSalesSwitch('revenue')">{{ $t('benchmarks.types.revenue') }}</span>
            </div>

            <div class="btn-group-toggle d-flex flex-column">
              <label
                v-for="(g, i) in groups"
                class="btn btn-outline-light mt-2"
                :class="{
                  active: g === groupby,
                  disabled: chartData.facets[g] === 0
                }"
                :for="'group_'+g"
                :key="i"
              >
                <input type="radio" :id="'group_'+g" :value="g" v-model="groupby">
                {{ $t('benchmarks.groups.'+g) }} <span v-if="chartData">({{ chartData.facets[g] }})</span>
              </label>
            </div>
          </div>

          <div class="card mt-4">
            <p class="text-dark mb-0">{{ $t('benchmarks.info.'+entity) }}</p>
          </div>

          <SpecInfo v-if="entity === 'diagnosis' || entity === 'prescription'" />

        </div>
      </div>

      <transition name="fade" appear>
        <div class="modal" v-if="showSearch" @mousedown.self="showSearch=false">
          <div class="box box-benchmarks bg-gradient form-light">
            <div class="close" @click="showSearch=false"><i class="fa fa-times"></i></div>
            <BenchmarkSearch :entity="entity" :items="items" v-on:submit="setItems"></BenchmarkSearch>
          </div>
        </div>
      </transition>

    </template>

  </LayoutDetail>

</template>

<style scoped lang="scss">
@import "src/assets/sass/variables";

.disabled {
  pointer-events: none;
}

.export-entities {
  width: 210px;

  .export-entities__sales {
    font-size: 0;
    margin-bottom: 10px;

    span {
      border: 1px solid $darkblue;
      display: inline-block;
      font-size: initial;
      padding: 5px;
      text-align: center;
      width: 50%;
    }
  }

  .export-entities__divider {
    height: 1px;
    width: 100%;
    background-color: #aaa;
  }

  .export-entities__list {
    margin-top: 10px;

    span {
      display: block;
      padding: 5px
    }
  }

  .export-active {
    background-color: $darkblue;
    color: white;
  }
}

@media only screen and (max-width: 1540px) and (min-width: 1440px) {
  .bmd__switch {
    .btn {font-size: 13px;}
  }
}

@media only screen and (max-width: 1290px) and (min-width:1201px) {
  .bmd__switch {
    .btn {font-size: 14px;}
  }
}

</style>
