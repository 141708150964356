<template>
  <div class="favorite-modal" :style="styles">
    <label for="favTitle">Titel:</label>
    <input id="favTitle" ref="favTitle" class="form-control" type="text" v-model="inputVal" maxlength="512">
    <button class="btn btn-secondary-light mt-2 mr-2" @click="$emit('on-close')">Abbrechen</button>
    <button class="btn btn-secondary mt-2" @click="$emit('on-action', inputVal)">{{actionName}}</button>
  </div>
</template>

<script>
export default {
  props: ['name', 'actionName', 'styles'],

  data() {
    return {
      inputVal: this.name || ''
    }
  },

  mounted() {
    // this.$refs.favTitle.$el.focus()
  }
}
</script>

<style scoped lang="scss">
@import 'src/assets/sass/variables';

.favorite-modal {
  background-color: $darkblue;
  border-radius: 5px;
  padding: 20px;
  position: absolute;
  right: 3%;
  top: 11%;
  width: 500px;
  z-index: 10;

  label {
    color: white;
    display: block;
    text-align: left;
  }
}
</style>
