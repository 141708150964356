
<script>
import mixins from '../mixins'
import Alert from '../components/Alert'
import Collapse from '../components/Collapse'
import Dropdown from '../components/Dropdown'
import Tabs from '../components/Tabs'
import Tab from '../components/Tab'
import Slider from '../components/Slider'
import Slide from '../components/Slide'
import SearchForm from '../components/SearchForm'

import DatePicker from 'vue2-datepicker'
import 'vue2-datepicker/locale/de'

import LayoutDetail from '../layouts/LayoutDetail'
import LayoutFull from '../layouts/LayoutFull'
import LayoutOverview from '../layouts/LayoutOverview'

export default {

  name: 'Page',
  mixins: [mixins],
  components: { Collapse, Dropdown, Tabs, Tab, Slider, Slide, Alert, SearchForm, LayoutDetail, LayoutFull, LayoutOverview, DatePicker },

  data() {
    return {

      alert: null
    }
  },

  created() {

  },

  computed: {
    user() {
      return this.$store.state.user.instance
    },

    isSafari() {
      return /^((?!chrome|android).)*safari/i.test(navigator.userAgent)
    }
  },

  methods: {
    alertHttpError(error) {
      if (error.response && error.response.data) {
        this.alert = { type: 'danger', message: 'errors.' + error.response.data.error }
      } else {
        this.alert = { type: 'danger', message: 'Server-Error' }
        console.error(error)
      }
    },

    replaceItem(item, title) {
      const search = item.entity === 'product' ? 'Produkt' : item.entity.toUpperCase()

      title = title.replace(new RegExp('(\\[' + search + '\\])', 'g'), item.desc || '')

      if (item.entity === 'icd') {
        title = title.replace(new RegExp('(\\[ICD3-Code\\])', 'g'), item.code || '')
      }

      if (item.entity === 'atc') {
        title = title.replace(new RegExp('(\\[ATC-Code\\])', 'g'), item.code || '')
      }

      return title
    }
  }
}

</script>
