
<script>

import Page from './Page'
import { mapGetters } from 'vuex'

export default {

  name: 'Search',
  extends: Page,

  data() {
    return {

    }
  },

  computed: {

    ...mapGetters(['searchTags'])
  },

  methods: {

    searchHit(item) {
      this.$router.push({ name: 'statistics.index', params: { entity: item.entity.toLowerCase(), code: item.code } })
    }
  }
}

</script>

<template>

  <div class="page">

    <div class="screen bg-gradient form-light flex-center">
      <div class="container container-lg pt-5 pt-lg-0">
        <h1>{{ $t('search.title') }}</h1>
        <SearchForm v-on:hit="searchHit" :placeholder="$t('search.placeholder')"></SearchForm>
        <div class="tags">
          <router-link v-for="(tag,i) in searchTags" :key="'tag_'+i" :to="{name:'statistics.index',params:{entity:tag.entity,code:tag.code}}" class="tag">{{ tag.desc }}</router-link>
        </div>
      </div>
    </div>

  </div>

</template>
