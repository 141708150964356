
<script>

export default {

  name: 'LayoutDetail'
}

</script>

<template>

  <div class="layout-detail">
    <div class="row flex-fill no-gutters">
      <div class="col-12 col-xl-3 col-xxl-2">
        <div class="side-col form-gray">
          <slot name="side"></slot>
        </div>
      </div>
      <div class="col-12 col-xl-9 col-xxl-10">
        <div class="main-col">
          <slot name="main"></slot>
        </div>
      </div>
    </div>
  </div>

</template>
