
<script>

import Api from '../Api'
import debounce from 'lodash.debounce'

export default {

  name: 'SearchForm',

  props: {
    placeholder: {
      type: String,
      required: false,
      default: null
    },
    value: {
      type: String,
      required: false,
      default: null
    },
    type: {
      type: String,
      required: false,
      default: null
    },
    clearOnHit: {
      type: Boolean,
      required: false,
      default: false
    }
  },

  data() {
    return {

      entity: null,
      query: null,
      result: [],
      selectedIndex: -1
    }
  },

  mounted() {
    this.entity = this.type

    if (this.value) {
      this.query = this.value
    } else {
      this.$refs.input.focus()
    }
  },

  methods: {

    reset() {
      this.result = []
      this.query = null
    },

    up() {
      if (this.selectedIndex > 0) {
        this.selectedIndex--
      } else {
        this.selectedIndex = -1
      }
    },

    down() {
      if (!this.selectedIndex || this.selectedIndex < this.result.length) {
        this.selectedIndex++
      } else {
        this.selectedIndex = -1
      }
    },

    focus() {
      this.selectedIndex = null
      this.$refs.input.select()
    },

    enter() {
      this.hit(this.result[this.selectedIndex])
    },

    hit(item) {
      this.query = item.name

      this.result = []

      if (this.clearOnHit) {
        this.query = null
      }

      this.$emit('hit', item)
    },

    input: debounce(function() {
      if (!this.query || this.query.length < 2) {
        this.result = []
        return
      }

      const data = {

        q: this.query,
        entity: this.entity ? this.entity.toUpperCase() : null,
        size: 200
      }

      Api.search.suggest(data).then(response => {
        this.result = response.data
      })
    }, 150),

    formatItem(item) {
      let text = this.$t('search.labels.' + item.entity)

      if (item.entity !== 'product') {
        text += ' | ' + item.code
      }

      text += ' | ' + item.name

      return this.strong(text, this.query)
    },

    strong(haystack, needle) {
      needle.split(' ').forEach(needle => {
        const i = haystack.toLowerCase().search(needle.toLowerCase())
        const len = needle.length

        if (i > -1 && len > 1) {
          haystack = haystack.substr(0, i) + '<b>' + haystack.substr(i, len) + '</b>' + haystack.substr(i + len)
        }
      })

      return haystack
    }
  }
}

</script>

<template>

  <div class="search-form">
    <div class="input-row">
      <i class="fa fa-search"></i>
      <input type="text" class="form-control form-control-lg" ref="input" v-model="query" @focus="focus" @input="input" @keydown.down="down" @keydown.up="up" @keydown.enter="enter" @keydown.esc="reset" :placeholder="placeholder">
      <div v-if="!type" class="custom-select-wrapper">
        <select class="custom-select custom-select-lg" v-model="entity" @change="input">
          <option :value="null">{{ $t('search.all') }}</option>
          <option value="ICD">{{ $t('search.labels.ICD') }}</option>
          <option value="PZN">{{ $t('search.labels.PZN') }}</option>
          <option value="ATC">{{ $t('search.labels.ATC') }}</option>
          <option value="product">{{ $t('search.labels.product') }}</option>
        </select>
      </div>
    </div>
    <div class="result pointer" v-show="result.length">
      <div v-for="(item,i) in result" class="item" :class="{active:(i===selectedIndex)}" @click="hit(item)" @mouseover="selectedIndex=i" v-html="formatItem(item)" :key="i"></div>
    </div>
  </div>

</template>
