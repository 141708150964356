<template>
  <div class="notification" v-if="notification.show">
    <i v-if="notification.icon" :class="`fa fa-${notification.icon}`" aria-hidden="true"></i>
    <span>{{ notification.msg }}</span>
<!--    <span>X Diese Statistik wurde aus Ihren Favoriten gelöscht.</span>-->
  </div>
</template>

<script>
export default {
  name: 'Notification',

  computed: {
    notification() {
      return this.$store.state.notification
    }
  }
}
</script>

<style scoped lang="scss">
@import 'src/assets/sass/variables';

.notification {
  background-color: $darkblue;
  border: 1px solid #ddd;
  border-radius: 10px;
  bottom: 50px;
  //box-shadow: 2px 2px 5px 0 grey;
  color: $white;
  left: 30%;
  padding: 15px;
  position: fixed;
  text-align: center;
  width: fit-content;
  z-index: 100;

  i {
    color: $white;
    margin-right: 10px;
  }
}
</style>
