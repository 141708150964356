<script>
import Page from './Page'

export default {
  name: 'Privacy',
  extends: Page,

  data() {
    return {}
  },

  created() {},

  computed: {
    analytics: {
      get() {
        return this.$store.getters.analyticsConsent
      },

      set(value) {
        this.$store.dispatch('analyticsConsent', value)
      }
    },

    tracking: {
      get() {
        return this.$store.getters['user/id']
      },

      set(tracking) {
        this.$store.dispatch('user/update', { tracking })
      }
    }
  },

  methods: {}
}
</script>

<template>
  <LayoutOverview>
    <template v-slot:top>
      <h1 class="page-title mb-3">ixsight now</h1>
      <h2>ein Produkt der intermedix Deutschland GmbH, Koblenz</h2>

      <h3>Datenschutzerklärung</h3>
      <p>April 2021</p>
    </template>
    <template v-slot:main>
      <p>
        Wir, die intermedix Deutschland GmbH, Maria Trost 21, 56070 Koblenz
        (nachfolgend intermedix genannt), ein Unternehmen der CompuGroup Medical
        SE & Co. KGaA (nachfolgend CGM genannt), freuen uns über Ihren Besuch
        auf unserer Webseite ixsight.de und Ihre Nutzung des Produkts ixsight
        now.
      </p>
      <p>
        Der Schutz Ihrer personenbezogenen Daten ist für uns ein wichtiges
        Anliegen, und wir möchten, dass Sie sich beim Besuch der Webseite
        ixsight.de sicher fühlen. Nachfolgend stellen wir Ihnen vor, welche
        Informationen wir während Ihres Besuches erfassen und wie diese genutzt
        werden.
      </p>
      <p>
        Wir stellen Ihnen das Produkt ixsight now gemeinsam mit der docmetric
        GmbH (nachfolgend docmetric genannt), ein Schwesterunternehmen der
        intermedix und ebenfalls eine 100%-ige Tochtergesellschaft der CGM, zur
        Verfügung. docmetric ist für uns als Auftragsverarbeiter tätig und
        unterstützt uns in der technischen Produktbereitstellung.
      </p>

      <h3>Gültigkeitsbereich</h3>

      <p>
        Diese Erklärung richtet sich an alle Besucher, Kunden und Partner des
        Produkts ixsight now in Form der Webseite ixsight.de. Das Produkt bietet
        Marktteilnehmern vornehmlich der deutschen Gesundheitsindustrie einen
        einfachen Zugang zu medizinischen Statistiken zum deutschen ambulanten
        Gesundheitsmarkt. Sie gilt nicht für eventuell vorhandene weiterführende
        Links zu fremden Unternehmen.
      </p>
      <p>
        In der Datenschutzerklärung unterscheiden wir zwischen
        Datenschutz-Maßgaben zu den auf der Webseite einsehbaren medizinischen
        Statistiken (1.) und Maßnahmen zur Wahrung der Privatsphäre der Besucher
        der Webseite sowie der Nutzer des Produkts (2.)
      </p>

      <h3>1. Medizinische Statistiken von ixsight now</h3>

      <p>
        Bei den medizinischen Statistiken von ixsight now handelt es sich um
        anonymisierte Daten aus Arzt- und Apotheken-Informationssystemen der
        CGM, die von Arztpraxen und Apotheken zur Verfügung gestellt und auf
        Deutschland bzw. KV-Regionen hochgerechnet werden.
      </p>
      <p>
        Die angezeigten Daten beinhalten Diagnosen, Leistungsziffern,
        Verordnungen, Informations-verhalten der Praxen und Abverkäufe in
        Apotheken. Dargestellt werden wochen-, monats- und quartalsweise
        Entwicklungen sowie Korrelationen der Daten untereinander. Alle Daten,
        die in die medizinischen Statistiken einfließen, werden bereits in
        anonymisierter Form aus den Systemen der Ärzte und Apotheken
        übermittelt. Um jedes Risiko einer nachträglichen Re-Identifikation von
        Patienten, Ärzten oder Apotheken auszuschließen, werden in den ixsight
        now-Statistiken zudem keine seltenen Erkrankungen, Verordnungen oder
        Abverkäufe dargestellt. Eine Zuordnung der Daten zur sendenden
        Arztpraxis oder Apotheke wird ebenfalls unmöglich gemacht.
      </p>

      <h3>2. Daten von Besucher*innen und Kund*innen von ixsight now</h3>

      <p>
        intermedix speichert bei Ihrem Besuch auf ixsight.de Daten. Weitere
        Informationen finden Sie hierzu im folgenden Abschnitt 2.1. Zusätzlich
        werden im Falle eines vorliegenden Vertragsverhältnisses bzw. in der
        Vertragsanbahnung Kundendaten gespeichert und verarbeitet. Weitere
        Informationen finden Sie hierzu im Abschnitt 2.2.
      </p>

      <h3>2.2 Daten von Besucher*innen</h3>

      <p>
        Wenn Sie unsere Internetseite ixsight.de besuchen, speichern unsere
        Webserver Daten zum Zweck des technischen Betriebs und zur
        Systemsicherheit. Hierbei handelt es sich um technische Informationen,
        die es uns ermöglichen zu analysieren, wie Besucher mit unserer Webseite
        interagieren. Hierunter fallen die folgenden Informationen:
      </p>

      <ul>
        <li>Browsertyp und Browserversion</li>
        <li>verwendetes Betriebssystem</li>
        <li>die URL der Webseite von der Sie kommen</li>
        <li>die URLs, die Sie im Produkt ixsight now besuchen</li>
        <li>
          den Namen Ihres Computers, die Uhrzeit und die Dauer Ihrer Besuche
        </li>
        <li>
          Gekürzte IP-Adresse, die uns von Ihrem Computer beim Besuch unserer
          Webseite übermittelt wird.
        </li>
      </ul>

      <p>
        Abhängig von der Art der Nutzung und von den Einstellungen Ihres
        Computers speichern wir außerdem bei Ihrem Besuch unserer Webseite
        kleine Textdateien auf Ihrem Gerät (sogenannte „Cookies“). Cookies
        helfen uns dabei, die Nutzungshäufigkeit und die Anzahl der Nutzer
        unserer Internetseiten zu ermitteln, sowie unsere Angebote für Sie
        möglichst komfortabel und effizient zu gestalten. Der Zweck des
        Einsatzes dieser Cookies besteht darin, Ihnen eine optimale
        Benutzerführung anbieten zu können sowie Sie "wiederzuerkennen" und
        Ihnen bei wiederholter Nutzung eine möglichst abwechslungsreiche
        Internetseite und neue Inhalte präsentieren zu können. Der Inhalt eines
        Cookies beschränkt sich auf eine Identifikationsnummer. Name oder
        IP-Adresse sind nicht Bestandteile von Cookies.
      </p>
      <p id="analytics">
        Der Einsatz von Cookies erfolgt erst nach Ihrer expliziten Zustimmung,
        einige von ihnen sind technisch temporär notwendig, während andere uns
        helfen unser Onlineangebot zu verbessern und wirtschaftlich zu
        betreiben. Bei Ihrem ersten Besuch auf unserer Webseite, können Sie die
        nicht notwendigen Cookies ablehnen. Diese Einstellungen können Sie auch
        jederzeit nachträglich in der folgenden Schaltfläche abwählen. Der
        Widerruf der Einwilligung kann ebenfalls durch Löschen aller Cookies von
        unserer Webseite in Ihrem Browser erfolgen. Da dieses abhängig vom
        verwendeten Browser ist, schlagen Sie hierzu bitte die Hilfe-Funktion
        Ihres Browsers auf. Im Falle der Ablehnung dieser Datenerhebung wird in
        Ihrem Browser ein sog. Opt-Out-Cookie abgelegt, was zur Folge hat, dass
        wir keine Sitzungsdaten erheben, die technisch nicht notwendig sind.
        Wenn Sie Ihre Cookies im Browser zu einem späteren Zeitpunkt löschen, so
        hat dies zur Folge, dass auch das Opt-Out-Cookie gelöscht wird und ggf.
        von Ihnen erneut aktiviert werden muss.
      </p>

      <p>
        Nicht technische notwendige Cookies ausschalten?
        <label class="switch ml-2" for="switch__analytics">
          <input type="checkbox" id="switch__analytics" v-model="analytics" />
          <span class="toggle"></span>
        </label>
      </p>

      <p>
        Diese Webseite verwendet ausschließlich Matomo zur Auswertung der
        Cookies. Dabei handelt es sich um einen sogenannten Webanalysedienst. Zu
        diesem Zweck werden die durch die Cookies erzeugten
        Nutzungsinformationen (einschließlich Ihrer anonymisierter IP-Adresse)
        an unsere CGM-Server übertragen und zu Nutzungsanalysezwecken
        gespeichert. Ihre IP-Adresse wird bei diesem Vorgang umgehend
        anonymisiert, so dass Sie als Besucher für uns anonym bleiben.
      </p>
      <p>
        Diese Webseite verwendet den Matomo Tag Manager. Dieser bietet eine
        Oberfläche für die zentrale Implementierung und Verwaltung von
        Webseiten-Tags. Der Tag Manager selbst erhebt keine personenbezogenen
        Daten, sondern löst nur Tags (z.B. Tracking- oder Marketing-Snippets)
        aus, die wiederum gegebenenfalls Daten erheben können.
      </p>
      <p>
        Es erfolgt keine Datenübermittlung an Drittanbieter. Auch die für Matomo
        erforderliche Verarbeitung erfolgt vollständig auf CGM-eigenen Servern.
      </p>
      <p>
        Wir behalten uns vor, die beschriebenen Besucherdaten nachträglich in
        Einzelfällen auszuwerten, wenn uns konkrete Anhaltspunkte für eine
        rechtswidrige Nutzung vorliegen.
      </p>
      <p>
        Darüberhinausgehende personenbezogene Angaben wie Ihr Name, Ihre
        Anschrift, Telefonnummer oder E-Mail-Adresse werden nicht erfasst, es
        sei denn, diese Angaben werden von Ihnen freiwillig gemacht, z.B. im
        Rahmen einer Umfrage, einer Informationsanfrage oder zur Durchführung
        eines Vertrages. In Bezug auf den letzten Punkt lesen Sie bitte
        folgenden Abschnitt.
      </p>

      <h3>2.2 Kundendaten</h3>

      <p>
        Als Kunden im Sinne dieser Datenschutzerklärung bezeichnen wir Personen,
        die personenbezogene Daten an uns weitergeben, um das Produkt ixsight
        now im Rahmen einer Vertrags-anbahnung oder eines abgeschlossenen
        Vertragsverhältnisses zu nutzen. Die von Ihnen zur Verfügung gestellten
        personenbezogenen Daten verwenden wir zum Zweck der Vertragserfüllung,
        des technischen Betriebs und der Personalisierung unserer Services.
      </p>
      <p>
        Wenn Sie als Kunde einen Vertrag für die Nutzung der ixsight now mit uns
        eingehen, legen wir in unserem System ein Kundenkonto für Sie an. Darin
        sind die von Ihnen angegebenen Stamm-und Kontaktdaten sowie Ihre
        Bestell- und Abrechnungsdaten enthalten.
      </p>

      <p>
        Um Ihnen technisch den Zugriff auf das Produkt zu gewähren, legen wir
        außerdem für Sie einen personenbezogenen Account an. Der Account
        umfasst:
      </p>
      <ul>
        <li>eine zufallsgenerierte Nutzer-ID</li>
        <li>die Zuordnung des Account-Typs (z.B. Basis, Pro)</li>
        <li>Ihre E-Mail-Adresse</li>
        <li>Ihren Vor- und Nachnamen.</li>
      </ul>

      <p>
        Nach Ihrer Anmeldung auf ixsight.de können Sie Ihre E-Mail-Adresse,
        Ihren Namen und Ihr Passwort jederzeit aktualisieren.
      </p>

      <p>
        Mit Ihrer expliziten Zustimmung speichern wir bei Ihrer Produktnutzung
        folgende Daten zu Ihrer Nutzer-ID:
      </p>

      <ul id="tracking">
        <li>Besuchte URLs und Statistiken von ixsight.de</li>
        <li>Account-Typ</li>
        <li>Tagesgenauer Zeitstempel</li>
        <li>Statistik-Downloads.</li>
      </ul>

      <p>
        Diese Verarbeitung ermöglicht es uns die Angebote der intermedix auf
        Ihre individuellen Bedürfnisse anzupassen und die missbräuchliche
        Nutzung Ihres Accounts zu detektieren. Wenn Sie mit der Speicherung und
        Auswertung dieser Daten nicht einverstanden sind, können Sie die
        Speicherung auch nachträglich jederzeit hier abwählen:
      </p>

      <div v-if="user.check">
        <p>
          Tracking von personenbezogenem Nutzungsverhalten ausschalten?
          <label class="switch ml-2" for="switch_tracking">
            <input type="checkbox" id="switch_tracking" v-model="tracking" />
            <span class="toggle"></span>
          </label>
        </p>
      </div>

      <div v-else class="text-muted">
        <p>
          Tracking von personenbezogenem Nutzungsverhalten ausschalten?
          <label class="switch disabled ml-2" for="switch_tracking">
            <input type="checkbox" id="switch_tracking" disabled />
            <span class="toggle"></span>
          </label>
          <br /><em
            >Sie können diese Einstellung nur als angemeldeter Nutzer ändern.
            Als nicht angemeldeter Nutzer erheben wir diese Daten nicht.</em
          >
        </p>
      </div>

      <h3>Verweise auf externe Internetseiten (Links)</h3>

      <p>
        intermedix als Betreiber der Webseite ixsight.de hat keinen Einfluss auf
        die inhaltliche Ausgestaltung der verlinkten, externen Webseiten. Diese
        wurden vor der Freischaltung der Links sorgfältig überprüft. Dennoch
        kann nicht ausgeschlossen werden, dass durch die Betreiber der
        jeweiligen Seiten inhaltliche Änderungen vorgenommen wurden, die gegen
        geltendes Recht oder unsere Philosophie verstoßen.
      </p>
      <p>
        Links zu externen Webseiten, die auf unserer Webseite angezeigt werden,
        können gegebenenfalls Nutzerdaten sammeln, wenn Sie auf diese klicken
        oder sonst ihren Anweisungen folgen. Wir empfehlen generell die
        Datenschutzrichtlinien von Webseiten einzusehen, wenn Sie Bedenken
        hinsichtlich der Erhebung und der Nutzung Ihrer Daten haben.
      </p>

      <h3>Ort der Speicherung</h3>

      <p>
        Ihre Besucher- und Nutzerdaten werden auf eigenen Servern im
        Rechenzentrum der CGM in Frankfurt gehostet, die nach ISO-27001
        zertifiziert sind. intermedix und docmetric treffen angemessene
        technische, administrative und physische Sicherheitsvorkehrungen, um die
        in ihrem Besitz befindlichen Daten vor Verlust, Missbrauch und
        unbefugtem Zugriff, Offenlegung, Änderung und Zerstörung zu schützen,
        wobei die mit der Verarbeitung verbundenen Risiken und die Art der
        verarbeiteten personenbezogenen Daten gebührend berücksichtigt werden.
      </p>
      <p>
        Der Zugriff auf personenbezogenen Daten ist dabei auf Mitarbeiter von
        intermedix und docmetric beschränkt. Diese Personen sind an die
        Einhaltung dieser Datenschutzerklärung und an
        Vertraulichkeitsverpflichtungen gebunden. Die Verletzung dieser
        Vertraulichkeitsverpflichtungen kann mit Kündigung und Strafverfolgung
        geahndet werden.
      </p>
      <p>
        Eine Weitergabe, Verkauf oder sonstige Übermittlung Ihrer
        personenbezogenen Daten an Dritte erfolgt nicht, es sei denn, dass dies
        zum Zwecke der Vertragsabwicklung erforderlich ist oder Sie ausdrücklich
        eingewilligt haben. So kann es beispielsweise erforderlich sein, dass
        wir bei Bestellungen von Produkten Ihre Anschrift und Bestelldaten an
        unsere Vertriebspartner weitergeben. Die Empfänger dieser Daten werden
        Ihnen in der Bestellbestätigung genannt. Eine Übermittlung in
        Drittländer findet nicht statt.
      </p>

      <h3>Löschfristen</h3>

      <p>
        Wir verpflichten uns gemäß DSGVO sämtliche Kunden- und Vertragsdaten
        nach Kündigung Ihres Vertrages zu löschen. Hierbei sind wir jedoch
        gesetzlich verpflichtet, handels- und steuerrechtliche
        Aufbewahrungsfristen vor allem aus § 257 HGB zu beachten, die über die
        Dauer des Vertragsverhältnisses hinausgehen können. Die Daten zum
        technischen Betrieb werden nach 14 Tagen gelöscht. Session-Cookies
        werden nach Beendigung jeder Sitzung gelöscht. Daten zum
        personenbezogenen Nutzungsverhalten anonymisieren oder löschen wir,
        sobald Sie widersprochen haben oder Ihr Konto gelöscht wird.
      </p>

      <h3>Ihre Rechte</h3>

      <p>
        Sie haben jederzeit das gesetzliche Recht, Auskunft über die von uns
        gespeicherten Daten zu verlangen. Sollten trotz unserer Bemühungen um
        Datenrichtigkeit und Aktualität falsche Informationen über Sie
        gespeichert sein, werden wir diese auf Ihre Aufforderung hin so schnell
        wie möglich berichtigen. Beruht eine Verarbeitung auf Ihrer
        Einwilligung, können Sie diese jederzeit für die Zukunft widerrufen. Um
        eine Auskunftsanfrage zu platzieren, können Sie unser Kontaktformular
        auf ixsight.de verwenden.
      </p>
      <p>
        Darüber hinaus haben Sie das Recht auf Mitnahme dieser Daten sowie das
        Recht auf Berichtigung, Einschränkung der Verarbeitung und Löschung
        dieser Daten.
      </p>
      <p>
        Sie haben gemäß Art. 77 DSGVO das Recht, sich bei der Aufsichtsbehörde
        zu beschweren, sofern Sie der Meinung sind, dass wir unseren
        Datenschutz- und Informationspflichten Ihnen gegenüber nicht nachkommen.
      </p>

      <h3>Änderungen an dieser Datenschutzerklärung</h3>
      <p>
        Beachten Sie, dass diese Datenschutzerklärung von Zeit zu Zeit ergänzt
        und geändert werden kann. Wir werden sie dann jeweils eigens darauf
        aufmerksam machen. Jede Version dieser Datenschutzbestimmungen ist
        anhand ihres Datums- und Versionsstandes am Ende dieser
        Datenschutzerklärung zu identifizieren. Außerdem archivieren wir alle
        früheren Versionen dieser Datenschutzbestimmungen zu Ihrer Einsicht auf
        Nachfrage.
      </p>

      <h3>Verantwortlich für intermedix</h3>
      <p>
        intermedix Deutschland GmbH<br />
        Maria Trost 21<br />
        56070 Koblenz<br />
        Geschäftsführer: Markus Huppertz, Dr. Ulrich Thomé<br />
        Sitz und Amtsgericht der Gesellschaft Koblenz
      </p>

      <h3>Datenschutzbeauftragter</h3>

      <p>
        Bei Fragen hinsichtlich der Verarbeitung Ihrer personenbezogenen Daten
        können Sie sich an den Datenschutzbeauftragten der CGM wenden, der im
        Falle von Auskunftsersuchen oder Beschwerden auch den Kunden der
        intermedix zur Verfügung steht:
      </p>

      <p>
        Hans Josef Gerlitz<br />
        CompuGroup Medical SE & Co. KGaA<br />
        Maria Trost 21<br />
        D-56070 Koblenz<br />
        HansJosef.Gerlitz@CGM.com
      </p>

      <h3>Zuständige Aufsichtsbehörde</h3>

      <p>Für die intermedix ist</p>
      <p>
        Der Landesbeauftragte für den Datenschutz und die Informationsfreiheit
        Rheinland-Pfalz<br />Hintere Bleiche 34<br />55116 Mainz
      </p>
      <p>als Aufsichtsbehörde zuständig.</p>

      <p>Version: 1.0 Stand: 01.04.2021</p>
    </template>
  </LayoutOverview>
</template>
