<script>
import { mapGetters } from 'vuex'
import mixins from './mixins'
import router from './router/router'
import i18n from './i18n'

import Auth from './components/Auth'
import Header from './components/Header'
import Footer from './components/Footer'
import Consent from './components/Consent'
import Tracking from './components/Tracking'
import Notification from './components/Notification'

export default {
  name: 'Platform',
  router,
  i18n,
  mixins: [mixins],
  components: { Consent, Tracking, Auth, Header, Notification, Footer },

  created() {
    this.$store.dispatch('init')
  }, 

  mounted() {},

  computed: {
    banner() {
      return this.$store.state.banner
    },

    user() {
      return this.$store.state.user.instance
    },
    ...mapGetters(['isReady'])
  },

  methods: {}
}

</script>

<template>
  <div id="vue-platform" v-if="isReady">
    <Header />

    <div
      id="main"
      :style="[banner.show ? { marginTop: banner.headerHeight } : {}]"
    >
      <transition name="fade" mode="out-in" appear>
        <router-view></router-view>
      </transition>
    </div>

    <Notification />

    <Footer></Footer>

    <Auth></Auth>

    <Consent></Consent>

    <Tracking></Tracking>
  </div>
</template>

<style lang="scss">
@font-face {
  font-family: 'NunitoIX';
  font-style: normal;
  font-weight: normal;
  src: url('assets/fonts/Nunito_Sans/NunitoSans-Regular.ttf');
}

@font-face {
  font-family: 'NunitoHeaderIX';
  font-style: normal;
  font-weight: normal;
  src: url('assets/fonts/Nunito_Sans/NunitoSans-Regular.ttf');
}

$font-family-sans-serif: 'NunitoIX', sans-serif;

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: 'NunitoHeaderIX', sans-serif;
}

@import 'src/assets/sass/app';
</style>