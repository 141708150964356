
<script>

export default {

  name: 'Alert',
  props: ['alert'],

  computed: {

    type() {
      return this.alert.type || 'info'
    },

    message() {
      return this.alert.message || null
    }
  }
}

</script>

<template>

  <div v-if="alert" class="alert" :class="'alert alert-'+type">
    {{ $t(message) }}
  </div>

</template>
