
<script>

import Page from './Page'
import icon1 from '../assets/images/bm-landingpage_icon1.png'
import icon2 from '../assets/images/bm-landingpage_icon2.png'
import icon3 from '../assets/images/bm-landingpage_icon3.png'

export default {

  name: 'BenchmarksOverview',
  extends: Page,

  data() {
    return {
      entities: [
        { name: 'diagnosis', iconPath: icon1, bg: 'rgb(48, 94, 163)' },
        { name: 'prescription', iconPath: icon2, bg: 'rgb(91, 136, 198)' },
        { name: 'sales', iconPath: icon3, bg: 'rgb(29, 53, 95)' }
      ]
    }
  },

  computed: {
    user() {
      return this.$store.state.user.instance
    }
  },

  methods: {

  }
}

</script>

<template>
  <div v-if="user.check" class="bm-home">
    <div class="bm-home__title">
      <h1 class="page-title">ixsight Benchmarks</h1>
    </div>

    <div class="bm-home__main">

      <div class="bm-home__main__hint">
        <div>
          <h2>Was wollen Sie vergleichen?</h2>
          <span>Wählen Sie einfach das passende Benchmarking Tool und stellen sich die gewünschte Analyse selber zusammen.</span>
        </div>
      </div>

      <div class="bm-home__main__tiles tiles">
        <router-link
            class="tiles__item"
            :to="{ name:'benchmarks.show', params:{ entity: entity.name } }"
            v-for="(entity) in entities" :key="entity.name"
            :style="{ backgroundColor: entity.bg }"
        >
          <div class="tiles__item__content">
            <img :src="entity.iconPath" alt="">
            <div>{{ $t('benchmarks.entities.'+entity.name) }}</div>
          </div>
          <div class="tiles__item__action">
            <i class="fa fa-angle-down"></i>
          </div>
        </router-link>
      </div>
    </div>

  </div>
</template>

<style lang="scss" scoped>
@import 'src/assets/sass/variables';
@import '~bootstrap/scss/mixins';

// Cancel global styling:
a:hover {
  text-decoration: none;
  color: white;
}

$bg-tile-1: rgb(48, 94, 163);
$bg-tile-2: rgb(91, 136, 198);
$bg-tile-3: rgb(29, 53, 95);

.bm-home {
  width: 100%;
  margin: 0 auto;
  padding: 40px 30px;

  &__title {
    margin-bottom: 4rem;

    .page-title {
      margin-bottom: 0;
    }
  }

  &__main {
    display: flex;
    justify-content: space-between;

    &__hint {
      align-items: flex-end;
      display: flex;
      font-size: larger;
      width: 30%;
    }
  }
}

.tiles {
  display: flex;
  justify-content: space-around;

  &__item {
    align-items: center;
    display: inline-flex;
    flex-direction: column;
    justify-content: space-between;

    border-radius: 5px;
    color: white;
    font-weight: 600;
    font-size: larger;
    height: 230px;
    margin-right: 35px;
    text-align: center;
    width: 285px;

    &__content {
      margin-top: 5px;

      img {
        width: 45%;
      }
    }

    &__action {
      background-color: $pink;
      font-size: 30px;
      height: 40px;
      line-height: 1.2;
      width: 100%;
      border-radius: 0 0 5px 5px;
    }
  }
}

@media only screen and (max-width: 1230px) {
  .bm-home__main {
    flex-direction: column;

    &__hint {
      width: 100%;
      margin-bottom: 30px;
    }

    &__tiles {
      justify-content: space-between;
    }
  }
}

@media only screen and (min-width: 1650px) {
  .tiles {
    width: 70%;

    &__item {
      width: 25%;

      img {
        width: 40%;
      }
    }
  }
}

</style>
