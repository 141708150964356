export default {
  // colors: ['#ff0000','#00ff00','#0000ff','#ffff00'],
  colors: [
    '#19355c',
    '#0e3540',
    '#1d4b85',
    '#185959',
    '#3d67a4',
    '#348785',
    '#6289c6',
    '#74ada9',
    '#a3c9ec',
    '#abdae0',
    '#e7e7e8'
  ],

  responsive: {
    rules: []
  },

  navigation: {
    buttonOptions: {
      enabled: false
    }
  },

  lang: {
    thousandsSep: '.',
    decimalPoint: ','
  },

  chart: {
    styledMode: false,
    spacing: [30, 1, 60, 1]
  },

  title: {
    text: '',
    align: 'left',
    style: {
      fontSize: '28px',
      color: 'rgb(19,51,97)'
    }
  },

  subtitle: {
    enabled: false,
    align: 'left'
  },

  credits: {
    enabled: true,
    href: '/files/ixsight-now_Anleitung-und-Methodik_public.pdf',
    style: {
      fontSize: '10px',
      color: '#aaaaaa'
    },
    position: {
      x: 0,
      y: -5
    }
  },

  legend: {
    align: 'left'
  },

  xAxis: {
    useHTML: true,
    labels: {
      style: {
        fontSize: '13px'
      }
    }
  },

  yAxis: {
    labels: {
      style: {
        fontSize: '13px'
      }
    },
    title: {
      enabled: false
    },
    stackLabels: {
      enabled: true,
      style: {
        fontWeight: 'bold'
      }
    }
  },

  plotOptions: {
    series: {
      states: {
        inactive: {
          opacity: 0.75,
          duration: 0.33
        }
      }
    }
  },

  tooltip: {
    backgroundColor: 'rgb(224,224,224)'
  }
}
