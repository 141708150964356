
<script>
import Alert from '../components/Alert'
import Api from '../Api'

export default {

  name: 'Beta',
  components: { Alert },

  data() {
    return {

      alert: null,
      open: false,
      formType: '',
      feedbackForm: {
        firstname: null,
        lastname: null,
        email: null,
        body: null
      },
      newsletterForm: {
        firstName: '',
        lastName: '',
        email: '',
        phonePrefix: '',
        phoneNumber: '',
        comment: '',
        contactMethod: '',
        testAccount: false,
        subscribed: false,
        termsAccepted: false
      }
    }
  },

  watch: {

  },

  computed: {
    user() {
      return this.$store.state.user.instance
    }
  },

  methods: {

    submit() {
      this.alert = null

      Api.util.contact(this.feedbackForm).then(response => {
        this.alert = { type: 'success', message: 'contact.success' }
      }).catch(this.alertHttpError)
    },

    openForm(type) {
      this.formType = type
      if (this.user.check && type === 'feedback') {
        this.feedbackForm.firstname = this.user.firstname
        this.feedbackForm.lastname = this.user.lastname
        this.feedbackForm.email = this.user.email
      }

      this.open = true
    },

    close() {
      this.open = false
    }
  }

}

</script>

<template>

  <div>

    <div class="mt-4 text-center">
      <div
        v-if="user.check"
        class="floating-btn pointer"
        :style="{ bottom: '4.5rem' }"
        @click="openForm('feedback')"
      >
        <div class="content"><i class="fa fa-comment-o mr-1"></i> Feedback</div>
      </div>
      <div class="floating-btn pointer" @click="openForm('newsletter')">
        <div class="content"><i class="fa fa-newspaper-o mr-1"></i> Newsletter</div>
      </div>
    </div>

    <transition name="fade" appear>
      <div class="modal" v-if="open" @click.self="close">
        <div class="box box-auth bg-gradient form-light">
          <div class="close" @click="close"><i class="fa fa-times"></i></div>

          <Alert :alert="alert"></Alert>

          <div class="beta-form">

            <template v-if="formType === 'feedback'">
              <h2>Wir freuen uns auf Ihr Feedback</h2>
              <form @submit.prevent="submit">
                <div class="form-group">
                  <label for="firstname">{{ $t('contact.firstname') }}:</label>
                  <input type="text" class="form-control form-control-lg" id="firstname" v-model="feedbackForm.firstname">
                </div>
                <div class="form-group">
                  <label for="lastname">{{ $t('contact.lastname') }}:</label>
                  <input type="text" class="form-control form-control-lg" id="lastname" v-model="feedbackForm.lastname">
                </div>
                <div class="form-group">
                  <label for="email">{{ $t('contact.email') }}: <abbr>*</abbr></label>
                  <input type="text" class="form-control form-control-lg" id="email" v-model="feedbackForm.email" required>
                </div>
                <div class="form-group">
                  <label for="body">{{ $t('contact.body') }}:</label>
                  <textarea type="text" class="form-control form-control-lg" id="body" v-model="feedbackForm.body"></textarea>
                </div>
                <div class="d-flex flex-row align-items-center mt-4">
                  <button type="submit" class="btn btn-secondary btn-lg px-4">{{ $t('labels.submit') }}</button>
                </div>
              </form>
            </template>

            <template v-if="formType === 'newsletter'">
              <h2>ixsight now - Newsletter</h2>
              <form action="https://mailing.cgm.com/art_resource.php?sid=3hktj.1plk2qt" method="post">

                <div class="form-group">
                  <label for="form_FIRSTNAME">{{ $t('contact.firstname') }}:</label>
                  <input
                    v-model="newsletterForm.firstName"
                    type="text"
                    class="form-control form-control-lg"
                    id="form_FIRSTNAME"
                    name="form_FIRSTNAME"
                  >
                </div>

                <div class="form-group">
                  <label for="form_NAME">{{ $t('contact.lastname') }}:</label>
                  <input
                    v-model="newsletterForm.lastName"
                    type="text"
                    class="form-control form-control-lg"
                    id="form_NAME"
                    name="form_NAME"
                  >
                </div>

                <div class="form-group">
                  <label for="form_EMAIL">{{ $t('contact.email') }}<abbr>*</abbr>:</label>
                  <input
                    v-model="newsletterForm.email"
                    type="email"
                    class="form-control form-control-lg"
                    id="form_EMAIL"
                    name="form_EMAIL"
                    required
                  >
                </div>

                <div class="row mb-4">
                  <div class="col-3">
                    <label for="form_PHONEPREFIX">{{ $t('contact.phonePrefix') }}:</label>
                    <input
                      v-model="newsletterForm.phonePrefix"
                      type="number"
                      class="form-control form-control-lg"
                      id="form_PHONEPREFIX"
                      name="form_PHONEPREFIX"
                    >
                  </div>

                  <div class="col-9">
                    <label for="form_PHONENUMBER">{{ $t('contact.phoneNumber') }}:</label>
                    <input
                      v-model="newsletterForm.phoneNumber"
                      type="number"
                      class="form-control form-control-lg"
                      id="form_PHONENUMBER"
                      name="form_PHONENUMBER"
                    >
                  </div>
                </div>

                <div class="form-group">
                  <label for="form_IXSIGHTANFRAGEKOMMENTARFELD">{{ $t('contact.comment') }}:</label>
                  <textarea
                    type="text"
                    class="form-control form-control-lg"
                    id="form_IXSIGHTANFRAGEKOMMENTARFELD"
                    name="form_IXSIGHTANFRAGEKOMMENTARFELD"
                    rows="5"
                    v-model="newsletterForm.comment"
                  ></textarea>
                </div>

                <div class="form-group">
                  <label for="form_IXSIGHTANFRAGEKANALWUNSCH">{{ $t('contact.contactMethod') }}:</label>
                  <div class="custom-select-wrapper">
                    <select
                      class="custom-select"
                      name="form_IXSIGHTANFRAGEKANALWUNSCH"
                      id="form_IXSIGHTANFRAGEKANALWUNSCH"
                    >
                      <option value="0">-</option>
                      <option value="175895">E-Mail</option>
                      <option value="175896">Telefon</option>
                    </select>
                  </div>
                </div>

                <div class="form-group">
                  <div class="custom-control custom-checkbox custom-control-inline">
                    <input
                      type="checkbox"
                      id="form_ANFRAGETESTACCOUNT"
                      class="custom-control-input"
                      name="form_ANFRAGETESTACCOUNT"
                      v-model="newsletterForm.testAccount"
                      value="0"
                      required
                    >
                    <label class="custom-control-label" for="form_ANFRAGETESTACCOUNT"
                    >Anfrage Testaccount</label>
                  </div>
                </div>

                <div class="form-group">
                  <div class="custom-control custom-checkbox custom-control-inline">
                    <input
                      type="checkbox"
                      id="form_ABONNEMENTS:213613"
                      class="custom-control-input"
                      name="form_ABONNEMENTS:213613"
                      v-model="newsletterForm.subscribed"
                      value="1"
                      required
                    >
                    <label class="custom-control-label" for="form_ABONNEMENTS:213613"
                    >ixsight now News</label>
                  </div>
                </div>

                <div class="form-group">
                  <div class="custom-control custom-checkbox custom-control-inline">
                    <input
                      type="checkbox"
                      id="form_SINGLEOPTIN"
                      class="custom-control-input"
                      name="form_SINGLEOPTIN"
                      v-model="newsletterForm.termsAccepted"
                      value="0"
                      required
                    >
                    <label class="custom-control-label" for="form_SINGLEOPTIN"><strong>
                      Einwilligungserklärung zur Beantwortung und Verarbeitung meiner Anmeldung (Details siehe unten)</strong><abbr>*</abbr>
                    </label>
                  </div>
                </div>

                <p>Mit dem Absenden des Formulars erkläre ich mich damit einverstanden, dass diese Daten von der intermedix Deutschland GmbH im Rahmen der<a
                    href="https://www.intermedix.de/de/intermedix_datenschutz/datenschutz.de.jsp"
                    rel="id_567592697"
                    target="_blank"
                  >Datenschutzerklärung</a>verwendet werden.
                </p>

                <button class="btn btn-secondary" type="submit">Abschicken</button>
              </form>
            </template>

          </div>

        </div>
      </div>
    </transition>

  </div>

</template>

<style scoped lang="scss">
@import 'src/assets/sass/variables';

a {
  color: #FF8C0CFF !important;
}
.custom-control-input:checked ~ .custom-control-label::before {
  border-color: white;
}
</style>
