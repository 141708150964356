
<script>

export default {

  name: 'Tabs',

  data() {
    return {

      tabs: []
    }
  },

  created() {
    this.tabs = this.$children
  },

  methods: {

    openTab(index) {
      this.tabs.forEach((tab, i) => {
        tab.isOpen = (index === i)
      })
    }
  }
}

</script>

<template>

  <div class="tabs">
    <div class="tabs-head">
      <span v-for="(tab,index) in tabs" :class="{active:tab.isOpen}" @click="openTab(index)" :key="index">{{ tab.title }}</span>
    </div>
    <div class="tabs-body">
      <slot></slot>
    </div>
  </div>

</template>
