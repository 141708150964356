<template>
  <div class="cmp">
    <Tooltip
      :text="tooltipText"
      :styles="{
        right: '30px',
        width: '150px'
      }"
      :show="!modalOpen && !isFavorite"
    >
      <button
        @click="toggleFavorite"
        class="btn btn-outline-light btn-sm"
        :class="{ active: isFavorite }"
      >
        <i class="fa fa-star-o" aria-hidden="true"></i>
      </button>
    </Tooltip>
    <FavoriteModal
      action-name="Hinzufügen"
      @on-action="addFavorite"
      @on-close="closeModal"
      v-if="modalOpen"
      :name="favorite.title"
    />
  </div>
</template>

<script>
import Api from '../Api'
import Tooltip from './Tooltip'
import FavoriteModal from './FavoriteModal'

export default {
  name: 'FavoriteBtn',
  components: { Tooltip, FavoriteModal },
  props: ['title', 'filtersStr'],

  mounted() {
    this.setFavIndexAndIsFavorite()
  },

  data() {
    return {
      isFavorite: false,
      favIndex: -1,
      favorite: {
        id: '',
        title: '',
        url: ''
      },
      modalOpen: false
    }
  },

  computed: {
    favorites() {
      return this.$store.state.user.instance.favorites
    },

    tooltipText() {
      return 'Fügen Sie diese Statistik Ihren persönlichen Favoriten hinzu. Diese lassen sich über das Favoritenmenü in Ihrem Account aufrufen.'
    }
  },

  methods: {
    async addFavorite(title) {
      const { search, pathname } = window.location
      this.favorite.title = title
      this.favorite.filters = this.filtersStr
      this.favorite.url = pathname + search
      this.$store.commit(
        'SHOW_NOTIFICATION',
        {
          msg: 'Diese Statistik mit ausgewählten Filtern wurde zu Ihren Favoriten hinzugefügt.',
          icon: 'check'
        }
      )

      this.modalOpen = false
      try {
        const res = await Api.user.favorite.add(this.favorite)
        this.favorite.id = res.data.favId
        this.$store.commit('user/ADD_FAVORITE', this.favorite)
        this.isFavorite = true
      } catch (err) {
        console.error(err)
      }
    },

    closeModal() {
      this.modalOpen = false
    },

    async deleteFavorite() {
      const favId = this.favorites[this.favIndex].id

      this.$store.commit('user/DELETE_FAVORITE', favId)
      try {
        await Api.user.favorite.delete(favId)
        this.isFavorite = false
        this.$store.commit(
          'SHOW_NOTIFICATION',
          {
            msg: 'Diese Statistik wurde aus Ihren Favoriten gelöscht.',
            icon: 'times'
          }
        )
      } catch (err) {
        console.error(err)
      }
    },

    setFavIndexAndIsFavorite() {
      if (this.favorites) {
        const { pathname } = window.location
        this.favIndex = this.favorites.findIndex(fav => fav.url.startsWith(pathname) && fav.filters === this.filtersStr)
        this.isFavorite = this.favIndex > -1
      }
    },

    toggleFavorite() {
      if (this.isFavorite) {
        this.deleteFavorite()
      } else {
        this.favorite.title = this.title
        this.modalOpen = true
      }
    }
  },

  watch: {
    filtersStr() {
      this.setFavIndexAndIsFavorite()
    }
  }
}
</script>

<style scoped lang="scss">
@import 'src/assets/sass/variables';

.cmp {
  margin-top: 10px;
  text-align: right;
}

.btn-outline-light {
  &:hover {
    background-color: inherit;
    color: $lightblue;
  }

  &:focus {
    box-shadow: unset;
  }
}
</style>
