
<script>

import Page from './Page'

export default {

  name: 'Method',
  extends: Page,

  data() {
    return {

    }
  }
}

</script>

<template>

  <LayoutOverview>
    <template v-slot:top>
      <h1>Methodik</h1>
    </template>
    <template v-slot:main>

    </template>
  </LayoutOverview>

</template>
