
<script>

import Page from './Page'
import Highcharts from 'highcharts'
import theme from '../theme'
import Api from '../Api'
import moment from 'moment'
import XLSX from 'xlsx'

export default {

  name: 'PznInfo',
  extends: Page,

  data() {
    return {

      stuff: null,
      showDiscount: null,
      loading: false,
      showTop: false
    }
  },

  mounted() {
    const pzn = this.$route.params.pzn

    if (pzn) {
      this.load(pzn)
    }

    window.onscroll = () => {
      this.showTop = (document.documentElement.scrollTop || document.body.scrollTop) > 60
    }
  },

  watch: {
    $route(to, from) {
      const pzn = to.params.pzn

      if (pzn) {
        this.load(pzn)
      }
    }
  },

  computed: {

    pznTree() {
      return this.stuff ? this.groupBy(this.stuff.overview.tree, 'ifap_name') : []
    }
  },

  methods: {
    exportFile(format) {
      const krankenkassen = this.stuff.rabattvertraege.kk
      const sheetHeads = [`Rabattverträge von ${this.stuff.name} | ${this.stuff.pzn}`]
      const tableHeads = ['Krankenkasse', 'IK-Nummer']
      const fileName = `rabattvertraege_pzn-${this.stuff.pzn}.${format}`
      let firstColumnWidth = 0

      const data = [sheetHeads, [], tableHeads]
      for (const key in krankenkassen) {
        krankenkassen[key].forEach(item => {
          data.push([key, item.ik])
        })

        if (key.length > firstColumnWidth) {
          firstColumnWidth = key.length
        }
      }

      if (format === 'xlsx') {
        const worksheet = XLSX.utils.aoa_to_sheet(data)
        worksheet['!cols'] = [{ wch: firstColumnWidth }] // Make 1st Column wider.
        const workbook = XLSX.utils.book_new()
        XLSX.utils.book_append_sheet(workbook, worksheet)
        XLSX.writeFile(workbook, fileName)
      }

      if (format === 'csv') {
        let csv = ''

        data.forEach(row => {
          row.forEach(cell => {
            csv += (cell || '') + ';'
          })

          csv += '\n'
        })

        const link = this.$refs.exportLink

        link.href = 'data:text/csv;charset=utf-8,' + encodeURI(csv)
        link.download = fileName
        link.click()
      }
    },

    load(pzn) {
      this.loading = true

      Api.stats.pznInfo(pzn).then(response => {
        this.stuff = response.data
        this.loading = false
        this.$nextTick(this.initChart)
      })
    },

    initChart() {
      if (!this.stuff.price_history.length) return

      Highcharts.setOptions(theme)

      const formatNum = (val) => new Intl.NumberFormat('de-DE', { style: 'decimal', minimumFractionDigits: 2, maximumFractionDigits: 2 }).format(val)

      const data = this.stuff.price_history.map(item => [Date.parse(item.validdate), item.avp])

      const options = {
        chart: {
          spacing: [30, 1, 0, 1]
        },
        xAxis: {
          type: 'datetime'
        },
        yAxis: {
          min: 0,
          title: {
            enabled: true,
            text: 'Preis AVK - in €'
          }
        },
        tooltip: {
          formatter: function() {
            return moment(this.x).format('DD.MM.YYYY') + ': ' + formatNum(this.y) + ' EUR'
          }
        },
        legend: {
          enabled: false
        },
        credits: {
          enabled: false
        },
        series: [{
          type: 'line',
          step: true,
          name: 'EUR',
          data
        }]
      }

      Highcharts.chart(this.$refs.placeholder, options)
    },

    scrollTop() {
      window.scrollTo({ top: 0, behavior: 'smooth' })
    },

    searchHit(item) {
      // eslint-disable-next-line handle-callback-err
      this.$router.push({ name: 'pzn-info', params: { pzn: item.code } }).catch(err => {})
    },

    mwstValue(val) {
      switch (parseInt(val)) {
        case 0: return 'Voll'
        case 1: return 'Ermäßigt'
        case 2: return 'Ohne'
      }

      return null
    }

  }
}

</script>

<template>

  <LayoutDetail class="pzn-info">
    <template v-slot:side v-if="!loading">

      <div class="text-right mb-4" v-if="stuff">
        <router-link :to="{name:'statistics.index',params:{entity:'pzn', code:stuff.pzn}}" class="btn btn-outline-secondary btn-sm">
          <i class="fa fa-angle-left mr-1"></i>{{ $t('statistics.back_to_overview') }}
        </router-link>
      </div>

      <SearchForm v-on:hit="searchHit" type="pzn" :value="(stuff ? stuff.pzn : '')"></SearchForm>

      <ul class="pzn-tree mt-4" v-if="stuff">
        <li v-for="(list, index) in pznTree" :key="index">
          <span class="title">{{ index }}</span>
          <ul>
            <li v-for="item in sortBy(list, 'pzn')" :key="item.pzn">
              <router-link :to="{name:'pzn-info',params:{pzn:item.pzn}}">
                {{ item.pzn }} {{ item.menge }} {{ item.mengeneinheit }}
              </router-link>
            </li>
          </ul>
        </li>
      </ul>

      <div class="top" @click="scrollTop" v-show="showTop">
        <i class="fa fa-chevron-up"></i>
      </div>

    </template>
    <template v-slot:main v-if="!loading">

      <h1>PZN-Info</h1>

      <div v-if="!stuff">
        <p class="lead">Hier finden Sie PZN-spezifische warenwirtschaftliche Informationen zu Präparaten. Die angezeigten Daten werden jeweils zum 01. und 15. eines Monats aktualisiert.</p>
        <p class="lead">Starten Sie einfach über die Suche links oben.</p>
      </div>

      <div v-else>

        <h2>{{ stuff.name }}</h2>

        <div class="card p-0 mb-4">
          <div class="card-head card-title bg-gradient">
            Allgemein
          </div>
          <div class="card-body">
            <div class="table-responsive">
              <table class="table table-sm table-striped table-stats">
                <tbody>
                  <tr>
                    <td class="key">PZN:</td>
                    <td class="val">{{ stuff.pzn }}</td>
                  </tr>
                  <tr>
                    <td class="key">IFA Kurzname:</td>
                    <td class="val">{{ stuff.name }}</td>
                  </tr>
                  <tr>
                    <td class="key">ATC:</td>
                    <td class="val">{{ stuff.atc_who_code }} {{ stuff.atc_who_name }}</td>
                  </tr>
                  <tr>
                    <td class="key">Darreichungsform:</td>
                    <td class="val">{{ stuff.darreichungsform_long }}</td>
                  </tr>
                  <tr>
                    <td class="key">Zulassungsnummer:</td>
                    <td class="val">{{ stuff.zulassungsnummer }}</td>
                  </tr>
                  <tr>
                    <td class="key">Hilfsmittelpositions Nummer:</td>
                    <td class="val">{{ stuff.hpn }}</td>
                  </tr>
                  <tr>
                    <td class="key">PZN Nachfolger:</td>
                    <td class="val">{{ stuff.nachfolge_pzn }}</td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>

        <div class="card p-0 mb-4" v-if="stuff.price_history.length">
          <div class="card-head card-title bg-gradient">
            Preishistorie
          </div>
          <div class="card-body">
            <div class="highchart-container">
              <div ref="placeholder"></div>
            </div>
          </div>
        </div>

        <div class="card p-0 mb-4">
          <div class="card-head card-title bg-gradient">
            Preisinfo
          </div>
          <div class="card-body">
            <div class="table-responsive">
              <table class="table table-sm table-striped table-stats">
                <tbody>
                  <tr>
                    <td class="key">Krankenhaus-EK:</td>
                    <td class="val"><span v-if="stuff.kaep">{{ stuff.kaep | currency }} EUR</span></td>
                  </tr>
                  <tr>
                    <td class="key">Großhandels-EK:</td>
                    <td class="val"><span v-if="stuff.gep">{{ stuff.gep | currency }} EUR</span></td>
                  </tr>
                  <tr>
                    <td class="key">Apotheken-EK:</td>
                    <td class="val"><span v-if="stuff.aep">{{ stuff.aep | currency }} EUR</span></td>
                  </tr>
                  <tr>
                    <td class="key">Apotheken-VK:</td>
                    <td class="val"><span v-if="stuff.avp">{{ stuff.avp | currency }} EUR</span></td>
                  </tr>
                  <tr>
                    <td class="key">PPU:</td>
                    <td class="val"><span v-if="stuff.ppu">{{ stuff.ppu | currency }} EUR</span></td>
                  </tr>
                  <tr>
                    <td class="key">AMPreisV SGB:</td>
                    <td class="val">{{ parseInt(stuff.ampv_sgb) ? 'Ja' : 'Nein' }}</td>
                  </tr>
                  <tr>
                    <td class="key">AMPreisV AMG:</td>
                    <td class="val">{{ parseInt(stuff.ampv_amg) ? 'Ja' : 'Nein' }}</td>
                  </tr>
                  <tr>
                    <td class="key">Festbetrag:</td>
                    <td class="val"><span v-if="parseFloat(stuff.festbetrag)">{{ stuff.festbetrag | currency }} EUR</span></td>
                  </tr>
                  <tr>
                    <td class="key">Festbetragsstufe:</td>
                    <td class="val">{{ stuff.festbetragsstufe }}</td>
                  </tr>
                  <tr>
                    <td class="key">MwSt:</td>
                    <td class="val">{{ mwstValue(stuff.mwst) }}</td>
                  </tr>
                  <tr>
                    <td class="key">Zuzahlungsfrei:</td>
                    <td class="val">{{ stuff.c }}</td>
                  </tr>
                  <tr>
                    <td class="key">Gültigkeitsdatum Preisinfos:</td>
                    <td class="val">{{ stuff.gdat_preise | nice_date }}</td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>

        <div class="card p-0 mb-4">
          <div class="card-head card-title bg-gradient">
            Vertriebsinfo
          </div>
          <div class="card-body">
            <div class="table-responsive">
              <table class="table table-sm table-striped table-stats">
                <tbody>
                  <tr>
                    <td class="key">Vertriebsstatus:</td>
                    <td class="val">{{ stuff.vertriebsfaehig }}</td>
                  </tr>
                  <tr>
                    <td class="key">Verkehrsfähigkeit:</td>
                    <td class="val">{{ stuff.verkehrsfaehig }}</td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>

        <div class="card p-0 mb-4">
          <div class="card-head card-title bg-gradient">
            Packungsinfo
          </div>
          <div class="card-body">
            <div class="table-responsive">
              <table class="table table-sm table-striped table-stats">
                <tbody>
                  <tr>
                    <td class="key">Packungsgrößen:</td>
                    <td class="val">{{ stuff.normpackung }}</td>
                  </tr>
                  <tr>
                    <td class="key">Einzelpackungsgröße:</td>
                    <td class="val">{{ stuff.menge }} {{ stuff.mengeneinheit }}</td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>

        <div class="card p-0 mb-4">
          <div class="card-head card-title bg-gradient">
            Lagerungsinfo
          </div>
          <div class="card-body">
            <div class="table-responsive">
              <table class="table table-sm table-striped table-stats">
                <tbody>
                  <tr>
                    <td class="key">Kennzeichen Verfallsdatum:</td>
                    <td class="val">{{ stuff.kennzeichen_verfalldatum }}</td>
                  </tr>
                  <tr>
                    <td class="key">Laufzeit:</td>
                    <td class="val"><span v-if="stuff.laufzeit_verfalldatum">{{ stuff.laufzeit_verfalldatum }} Monate</span></td>
                  </tr>
                  <tr>
                    <td class="key">Kennzeichen Kühlkette:</td>
                    <td class="val">{{ stuff.kennzeichen_kühlkette }}</td>
                  </tr>
                  <tr>
                    <td class="key">Lagertemperatur Min.:</td>
                    <td class="val"><span v-if="stuff.lagertemp_min">{{ stuff.lagertemp_min }} °C</span></td>
                  </tr>
                  <tr>
                    <td class="key">Lagertemperatur Max.:</td>
                    <td class="val"><span v-if="stuff.lagertemp_max">{{ stuff.lagertemp_max }} °C</span></td>
                  </tr>
                  <tr>
                    <td class="key">Lichtempfindlich:</td>
                    <td class="val">{{ stuff.lichtempfindlich }}</td>
                  </tr>
                  <tr>
                    <td class="key">Feuchteempfindlich:</td>
                    <td class="val">{{ stuff.feuchteempfindlich }}</td>
                  </tr>
                  <tr>
                    <td class="key">Lageempfindlich:</td>
                    <td class="val">{{ stuff.lageempfindlich }}</td>
                  </tr>
                  <tr>
                    <td class="key">Zerbrechlich:</td>
                    <td class="val">{{ stuff.zerbrechlich }}</td>
                  </tr>
                  <tr>
                    <td class="key">Kennzeichen Eichung:</td>
                    <td class="val">{{ stuff.kennzeichnung_eichung }}</td>
                  </tr>
                  <tr>
                    <td class="key">Laufzeit Eichung:</td>
                    <td class="val">{{ stuff.laufzeit_eichung }}</td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>

        <div class="card p-0 mb-4">
          <div class="card-head card-title bg-gradient">
            Rechtsinfo
          </div>
          <div class="card-body">
            <div class="table-responsive">
              <table class="table table-sm table-striped table-stats">
                <tbody>
                  <tr>
                    <td class="key">Arzneimittel:</td>
                    <td class="val">{{ stuff.arzneimittel == '1' ? 'ja' : 'nein' }}</td>
                  </tr>
                  <tr>
                    <td class="key">Medizinprodukt:</td>
                    <td class="val">{{ stuff.medizinprodukt == '1' ? 'ja' : 'nein' }}</td>
                  </tr>
                  <tr>
                    <td class="key">Apothekenpflichtig:</td>
                    <td class="val">{{ stuff.apothekenpflichtig == '1' ? 'ja' : 'nein' }}</td>
                  </tr>
                  <tr>
                    <td class="key">Verschreibungspflichtig:</td>
                    <td class="val">{{ stuff.verschreibungspflichtig == '1' ? 'ja' : 'nein' }}</td>
                  </tr>
                  <tr>
                    <td class="key">Betäubungsmittel:</td>
                    <td class="val">{{ stuff.betaeubungsmittel == '1' ? 'ja' : 'nein' }}</td>
                  </tr>
                  <tr>
                    <td class="key">Blutprodukt:</td>
                    <td class="val">{{ stuff.blutprodukt == '1' ? 'ja' : 'nein' }}</td>
                  </tr>
                  <tr>
                    <td class="key">Droge / Chemikalie:</td>
                    <td class="val">{{ stuff.droge_chemikalie == '1' ? 'ja' : 'nein' }}</td>
                  </tr>
                  <tr>
                    <td class="key">Lifestyle:</td>
                    <td class="val">{{ stuff.lifestyle == '1' ? 'ja' : 'nein' }}</td>
                  </tr>
                  <tr>
                    <td class="key">Ausnahmeregelung:</td>
                    <td class="val">{{ stuff.ausnahmeregelung == '1' ? 'ja' : 'nein' }}</td>
                  </tr>
                  <tr>
                    <td class="key">Zulassung Generikum:</td>
                    <td class="val">{{ stuff.zul_generikum == '1' ? 'ja' : 'nein' }}</td>
                  </tr>
                  <tr>
                    <td class="key">Negativliste:</td>
                    <td class="val">{{ stuff.negativliste == '1' ? 'ja' : 'nein' }}</td>
                  </tr>
                  <tr>
                    <td class="key">Hilfsmittel zum Verbrauch:</td>
                    <td class="val">{{ stuff.hilfsmittel_zv == '1' ? 'ja' : 'nein' }}</td>
                  </tr>
                  <tr>
                    <td class="key">Lebensmittel:</td>
                    <td class="val">{{ stuff.lebensmittel == '1' ? 'ja' : 'nein' }}</td>
                  </tr>
                  <tr>
                    <td class="key">S-Datenblatt Gefahrstoffe:</td>
                    <td class="val">{{ stuff.sdb_gefahrstoffe == '1' ? 'ja' : 'nein' }}</td>
                  </tr>
                  <tr>
                    <td class="key">Tierarzneimittel:</td>
                    <td class="val">{{ stuff.tierarzneimittel == '1' ? 'ja' : 'nein' }}</td>
                  </tr>
                  <tr>
                    <td class="key">Nahrungsergänzungsmittel:</td>
                    <td class="val">{{ stuff.nahrungsergaenzungsmittel == '1' ? 'ja' : 'nein' }}</td>
                  </tr>
                  <tr>
                    <td class="key">Diätetikum:</td>
                    <td class="val">{{ stuff.diaetetikum == '1' ? 'ja' : 'nein' }}</td>
                  </tr>
                  <tr>
                    <td class="key">Gültigkeitsdatum Rechtsinfo:</td>
                    <td class="val">{{ stuff.gdat_rechtsinfo | nice_date }}</td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>

        <div class="card p-0 mb-4" v-if="stuff.rabattvertraege && stuff.rabattvertraege.kk">
          <div class="card-head card-title bg-gradient">
            Rabattverträge
          </div>
          <div class="card-body">
            <div class="row">
              <div class="col">
                <div class="table-responsive table-scroll">
                  <table class="table table-sm table-striped table-hover table-stats">
                    <thead>
                      <tr>
                        <th>Krankenkasse</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr v-for="(v, k) in stuff.rabattvertraege.kk" @click="showDiscount = k" :key="k">
                        <td class="val" :class="{active:showDiscount===k}">{{ k }}</td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
              <div class="col-6"  v-if="showDiscount">
                <div class="table-responsive table-scroll">
                  <table class="table table-sm table-striped table-stats">
                    <thead>
                      <tr>
                        <th>IK-Nummer</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr v-for="(item, i) in stuff.rabattvertraege.kk[showDiscount]" :key="i">
                        <td class="val">{{ item.ik }}</td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>

            <br>
            <span class="btn btn-sm btn-outline-light" @click="exportFile('xlsx')">
              <i class="fa fa-file-excel-o mr-1"></i> XLSX
            </span>
            <span class="btn btn-sm btn-outline-light" @click="exportFile('csv')">
              <i class="fa fa-file-code-o mr-1"></i> CSV
            </span>
            <a ref="exportLink"></a>
          </div>
        </div>

      </div>

    </template>
  </LayoutDetail>

</template>
