
<script>

import Page from './Page'
import Api from '../Api'

export default {

  name: 'StatisticOverview',
  extends: Page,

  data() {
    return {

      item: null,
      statistics: null,
      chapters: null,
      tree: null
    }
  },

  mounted() {
    const entity = this.$route.params.entity
    const code = this.$route.params.code

    Api.stats.landingpage(entity, code).then(response => {
      this.item = response.data.item
      this.statistics = response.data.statistics
      this.chapters = response.data.chapterorder
      this.tree = response.data.details.tree

      // eslint-disable-next-line handle-callback-err
      this.$router.replace({ name: 'statistics.index', params: { entity: this.item.entity.toLowerCase(), code: this.item.code } }).catch(err => {})
    })
  },

  computed: {

    atcTree() {
      const tree = []
      let level = 0

      for (const code in this.tree) {
        tree.push({ code, label: this.tree[code], level: level++ })
      }

      return tree
    }
  },

  methods: {

    statisticsByChapter(chapter) {
      return this.statistics.filter(item => item.chapter === chapter).sort((item1, item2) => {
        return item1.sortorder < item2.sortorder ? -1 : 1
      })
    }
  }
}

</script>

<template>

  <LayoutOverview v-if="item">
    <template v-slot:top>
      <h1 class="page-title"><span v-if="item.entity !== 'product'">{{ item.code }} | </span>{{ item.desc }}</h1>
    </template>
    <template v-slot:main>

      <h2>{{ $t('statistics.title') }}</h2>

      <template v-for="chapter in chapters">
        <div class="card card-title bg-gradient" :key="chapter">
          {{ chapter }}
        </div>
        <div v-for="statistic in statisticsByChapter(chapter)" :key="statistic.id">
          <router-link
            v-if="statistic.enabled"
            :to="{
              name:'statistics.show',
              params:{ entity:item.entity.toLowerCase(),
              code:item.code,
              id: statistic.id
            }}"
            class="card card-link enabled"
          >
            <span class="title mr-lg-4">{{ replaceItem(item, statistic.title) }}</span>
            <span class="secondary nowrap ml-lg-auto">{{ $t('statistics.show_details') }} <i class="fa fa-angle-right ml-1"></i></span>
          </router-link>
          <span v-else class="card card-link disabled">
            <span class="title">{{ replaceItem(item, statistic.title) }}</span>
          </span>
        </div>
      </template>

    </template>
    <template v-slot:side>

      <h2>{{ $t('statistics.base_information') }}</h2>
      <div class="card flex-column">
        <p class="lg mb-0">Hier finden Sie umfangreiche Statistiken zu {{ item.desc }}.<br><br>Innerhalb der Statistiken können Sie weitere Filterungen wie z.B. ausgewählte Zeiträume oder Patientenaltersgruppen auswählen.</p>
      </div>

      <template v-if="tree && (item.entity === 'pzn' || item.entity === 'product')">
        <h2 class="mt-4">
          {{ $t('statistics.pzn_tree') }}
          <span class="help ml-1" :title="$t('statistics.pzn_tree_info')">
            <i class="fa fa-question-circle"></i>
          </span>
        </h2>
        <div class="card flex-column mt-4">
          <ul class="pzn-tree mb-0">
            <li v-for="(list, index) in groupBy(tree, 'ifap_name')" :key="index">
              <span class="title">{{ index }}</span>
              <ul>
                <li v-for="item in sortBy(list, 'pzn')" :key="item.pzn">
                  <router-link :to="{ name:'pzn-info', params: { pzn: item.pzn } }">
                    {{ item.pzn }} {{ item.menge }} {{ item.mengeneinheit }}
                  </router-link>
                </li>
              </ul>
            </li>
          </ul>
        </div>
      </template>

      <template v-if="tree && item.entity === 'atc'">
        <h2 class="mt-4">{{ $t('statistics.atc_tree') }}</h2>
        <div class="card flex-column mt-4">
          <ul class="atc-tree mb-0">
            <li v-for="item in atcTree" :style="'margin-left:'+(item.level)+'rem;'" :key="item.code">
              <span>{{ item.code }}</span>:&nbsp;{{ item.label }}
            </li>
          </ul>
        </div>
      </template>

    </template>
  </LayoutOverview>

</template>
