export default class User {
  constructor(data) {
    if (!data) return

    this.id = data.id
    this.email = data.email
    this.firstname = data.firstname
    this.lastname = data.lastname
    this.role = data.role
    this.activated = data.activated
    this.created = data.created
    this.tracking = typeof data.tracking !== 'undefined' ? data.tracking : null
    this.favorites = []
    if (data.favorites) {
      this.favorites = data.favorites.reverse()
    }

    this.check = this.id !== null
    this.isAdmin = this.role === 'admin'
    this.isIxAdmin = this.role === 'ixadmin'
    this.isDocmetric = this.role === 'docmetric'
  }
}
