
<script>

export default {

  name: 'Collapse',

  props: ['title', 'look'],

  data() {
    return {

      isOpen: false
    }
  },

  methods: {

    toggle() {
      this.isOpen = !this.isOpen
    },

    beforeEnter(element) {
      requestAnimationFrame(() => {
        if (!element.style.height) {
          element.style.height = '0px'
        }
        element.style.display = null
      })
    },

    enter(element) {
      requestAnimationFrame(() => {
        requestAnimationFrame(() => {
          element.style.height = `${element.scrollHeight}px`
        })
      })
    },

    afterEnter(element) {
      element.style.height = null
    },

    beforeLeave(element) {
      requestAnimationFrame(() => {
        if (!element.style.height) {
          element.style.height = `${element.offsetHeight}px`
        }
      })
    },

    leave(element) {
      requestAnimationFrame(() => {
        requestAnimationFrame(() => {
          element.style.height = '0px'
        })
      })
    },

    afterLeave(element) {
      element.style.height = null
    }

  }
}

</script>

<template>

  <div class="collapse" :class="look">
    <div class="collapse-head" @click="toggle">
      <span class="title">{{ title }}</span>
      <i class="ml-auto fa" :class="isOpen ? 'fa-angle-up' : 'fa-angle-down'"></i>
    </div>
    <transition name="dropdown" appear
                @before-enter="beforeEnter"
                @enter="enter"
                @after-enter="afterEnter"
                @before-leave="beforeLeave"
                @leave="leave"
                @after-leave="afterLeave">
      <div class="collapse-body" v-show="isOpen">
        <slot></slot>
      </div>
    </transition>
  </div>

</template>
