
<script>
export default {
  name: 'BenchmarkFilter',

  props: {
    placeholder: {
      type: String,
      required: false,
      default: null
    },
    result: {
      type: Array,
      required: false,
      default: () => []
    },
    setfocus: {
      type: Boolean,
      required: false,
      default: false
    }
  },

  data() {
    return {

      query: null,
      hasFocus: false,
      selectedIndex: -1
    }
  },

  mounted() {
    this.$bus.$on('reset-benchmark-filter', this.reset)

    if (this.setfocus) {
      this.$refs.input.focus()
    }
  },

  methods: {

    reset() {
      this.query = null
    },

    up() {
      if (this.selectedIndex > 0) {
        this.selectedIndex--
      } else {
        this.selectedIndex = -1
      }
    },

    down() {
      if (!this.selectedIndex || this.selectedIndex < this.result.length) {
        this.selectedIndex++
      } else {
        this.selectedIndex = -1
      }
    },

    focus() {
      this.hasFocus = true
      this.selectedIndex = -1
      this.$refs.input.select()
    },

    blur() {
      setTimeout(() => {
        this.hasFocus = false
      }, 250)
    },

    enter() {
      this.hit(this.result[this.selectedIndex])
    },

    hit(item) {
      this.query = item
      this.hasFocus = false
      this.input(null, true)
    },

    input(_, selected) {
      this.$emit('input', { query: this.query, selected: (selected || false) })
    }
  }
}

</script>

<template>

  <div class="search-form search-form-sm">
    <div class="input-row">
      <i class="fa fa-search"></i>
      <input type="text" class="form-control" ref="input" v-model="query" @focus="focus" @blur="blur" @input="input" @keydown.down="down" @keydown.up="up" @keydown.enter="enter" @keydown.esc="reset" :placeholder="placeholder">
    </div>
    <div class="result pointer" v-show="hasFocus && result.length">
      <div v-for="(item,i) in result" class="item" :class="{active:(i===selectedIndex)}" @click="hit(item)" @mouseover="selectedIndex=i" v-html="item" :key="i"></div>
    </div>
  </div>

</template>
