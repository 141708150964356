<!-- Spec = Specialization (Fachgruppe) -->
<template>
  <div class="card mt-4 mt-xl-0">
    <h5>{{ $t('statistics.dataAvailability') }}</h5>
    <table>
      <tr
        v-for="spec in specs"
        :key="spec.value"
        :class="{ 'selected': selectedSpecIds.includes(spec.value) }"
      >
        <td class="label">{{spec.desc}}:</td><td>{{spec.since}}</td>
      </tr>
    </table>
  </div>
</template>

<script>

export default {
  name: 'SpecInfo',

  props: ['isCube'],

  computed: {
    selectedSpecIds() {
      return this.$store.state.filter
        .selectedSpecializations.map(spec => spec.value)
    },

    specs() {
      let specs = []
      if (this.isCube) {
        specs = this.$store.state.filter.cubeSpecs
      } else {
        specs = this.$store.state.filter.specializations
      }
      // specs.map(spec => ({
      //   ...spec,
      //   desc: // remove (beta)...
      // }))
      return specs
    }
  }
}
</script>

<style scoped lang="scss">
@import 'src/assets/sass/variables';

.card {
  background-color: $darkblue;
  color: #ddd;
  margin-top: 20px !important;

  h5 {
    border-bottom-color: white;
    color: white;
  }

  td {
    font-size: 11px;

    &.label {
      padding-right: 5px;
    }
  }

  .selected {
    color: white;
    font-weight: bold;
  }
}
</style>
