
<script>

import Api from '../Api'
import Alert from './Alert'

export default {

  name: 'ContactForm',

  components: { Alert },

  props: {
    showLoginBtn: {
      type: Boolean,
      required: false,
      default: false
    },
    showDemoOption: {
      type: Boolean,
      required: false,
      default: true
    }
  },

  data() {
    return {

      alert: null,
      form: {},
      accept_terms: false
    }
  },

  created() {
    this.reset()
  },

  methods: {

    reset() {
      this.form = {
        firstname: null,
        lastname: null,
        email: null,
        phone: null,
        body: null,
        type: 'E-Mail',
        wants_demo: false
      }

      this.$nextTick(() => {
        if (this.$refs.inputFirstname) {
          this.$refs.inputFirstname.focus()
        }
      })
    },

    submit() {
      this.alert = null

      Api.util.contact(this.form).then(response => {
        this.alert = { type: 'success', message: 'contact.success' }
        this.reset()
      }).catch(this.alertHttpError)
    },

    showAuth() {
      this.$emit('changeMode', 'login')
    }
  }
}

</script>

<template>

  <div class="contact-form">
    <h2 v-if="showLoginBtn">Jetzt kostenlosen Demo Account anfordern</h2>
    <Alert :alert="alert"></Alert>
    <form method="post" action="https://scnem2.com/art_resource.php?sid=3hktj.1plk2qt">
      <div class="form-group">
        <label for="form_FIRSTNAME">{{ $t('contact.firstname') }}:</label>
        <input type="text" class="form-control form-control-lg" id="form_FIRSTNAME" name="form_FIRSTNAME" ref="inputFirstname" v-model="form.firstname">
      </div>
      <div class="form-group">
        <label for="form_NAME">{{ $t('contact.lastname') }}:</label>
        <input type="text" class="form-control form-control-lg" id="form_NAME" name="form_NAME" v-model="form.lastname">
      </div>
      <div class="form-group">
        <label for="form_EMAIL">{{ $t('contact.email') }}<abbr>*</abbr>:</label>
        <input type="text" class="form-control form-control-lg" id="form_EMAIL" name="form_EMAIL" v-model="form.email" required>
      </div>
      <div class="form-group">
        <label for="form_PHONENUMBER">{{ $t('contact.phone') }}:</label>
        <input type="text" class="form-control form-control-lg" id="form_PHONENUMBER" name="form_PHONENUMBER" v-model="form.phone">
      </div>
      <div class="form-group">
        <label for="form_IXSIGHTANFRAGEKOMMENTARFELD">{{ $t('contact.body') }}:</label>
        <textarea type="text" class="form-control form-control-lg" rows="4" id="form_IXSIGHTANFRAGEKOMMENTARFELD" name="form_IXSIGHTANFRAGEKOMMENTARFELD" v-model="form.body"></textarea>
      </div>
      <div class="form-group">
        <p>Auf welchen Weg sollen wir Sie kontaktieren?</p>
        <div class="custom-control custom-radio custom-control-inline">
          <input type="radio" id="form_IXSIGHTANFRAGEKANALWUNSCH_EMAIL" class="custom-control-input" value="E-Mail" name="form_IXSIGHTANFRAGEKANALWUNSCH" v-model="form.type">
          <label class="custom-control-label" for="form_IXSIGHTANFRAGEKANALWUNSCH_EMAIL">E-Mail</label>
        </div>
        <div class="custom-control custom-radio custom-control-inline">
          <input type="radio" id="form_IXSIGHTANFRAGEKANALWUNSCH_PHONE" class="custom-control-input" value="Telefon" name="form_IXSIGHTANFRAGEKANALWUNSCH" v-model="form.type">
          <label class="custom-control-label" for="form_IXSIGHTANFRAGEKANALWUNSCH_PHONE">Telefon</label>
        </div>
      </div>
      <div class="form-group" v-if="showDemoOption">
        <div class="custom-control custom-checkbox custom-control-inline">
          <input type="checkbox" id="form_ANFRAGETESTACCOUNT" class="custom-control-input" value="1" name="form_ANFRAGETESTACCOUNT" v-model="form.wants_demo">
          <label class="custom-control-label" for="form_ANFRAGETESTACCOUNT">Produktdemo und Testaccount gewünscht</label>
        </div>
      </div>
      <div class="form-group">
        <div class="custom-control custom-checkbox custom-control-inline">
          <input type="checkbox" id="form_SINGLEOPTIN" class="custom-control-input" value="1" name="form_SINGLEOPTIN" v-model="accept_terms" required>
          <label class="custom-control-label" for="form_SINGLEOPTIN">Mit dem Absenden des Formulars erkläre ich mich damit einverstanden, dass diese Daten von der intermedix Deutschland GmbH im Rahmen der Datenschutzerklärung verwendet werden.<abbr>*</abbr></label>
        </div>
      </div>
      <div class="form-group">
        <small><strong>*</strong> Pflichtfelder</small>
      </div>
      <div class="d-flex flex-row align-items-center mt-4">
        <button type="submit" class="btn btn-secondary btn-lg px-4">{{ $t('labels.submit') }}</button>
        <span v-if="showLoginBtn" class="btn btn-lg btn-outline-white ml-auto" @click="showAuth">{{ $t('auth.login') }}</span>
      </div>
    </form>
  </div>

</template>

<style scoped lang="scss">
.custom-control-input:checked ~ .custom-control-label::before {
  border-color: white;
}
</style>
