
<script>

export default {

  name: 'Slider',
  props: {

    arrows: {
      type: Boolean,
      required: false,
      default: false
    }
  },

  data() {
    return {

      slides: [],
      activeIndex: 0
    }
  },

  mounted() {
    this.slides = this.$children
    this.slides[0].isOpen = true
  },

  methods: {

    prev() {
      const index = (this.activeIndex === 0) ? this.slides.length - 1 : this.activeIndex - 1
      this.show(index, 'slide-right')
    },

    next() {
      const index = (this.activeIndex >= this.slides.length - 1) ? 0 : this.activeIndex + 1
      this.show(index, 'slide-left')
    },

    show(index, transition) {
      if (!transition) {
        transition = index < this.activeIndex ? 'slide-right' : 'slide-left'
      }

      this.slides.forEach((slide, i) => {
        slide.transition = transition
        slide.isOpen = (index === i)
      })

      this.activeIndex = index
    }
  }
}

</script>

<template>

  <div class="slider">
    <span v-if="arrows" class="arrow prev pointer" @click="prev">
      <i class="fa fa-chevron-left"></i>
    </span>
    <span v-if="arrows" class="arrow next pointer" @click="next">
      <i class="fa fa-chevron-right"></i>
    </span>
    <div class="slider-body">
      <slot></slot>
    </div>
    <div class="slider-indicators">
      <span class="dot pointer" :class="{active:(activeIndex === i)}" v-for="(slide, i) in slides" @click="show(i)" :key="i">
        <i class="fa fa-circle"></i>
      </span>
    </div>
  </div>

</template>
