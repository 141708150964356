<template>
  <div class="tooltip-container">
    <div
      v-show="show"
      class="tooltip"
      :style="styles"
      v-html="text"
    >
    </div>
    <slot></slot>
  </div>
</template>

<script>
export default {
  name: 'Tooltip',

  props: {
    styles: {
      type: Object
    },

    show: {
      type: Boolean,
      default: true
    },

    text: {
      type: String,
      default: 'Keine Information verfügbar.'
    }
  }
}
</script>

<style scoped lang="scss">
@import 'src/assets/sass/variables';

.tooltip-container {
  position: relative;
  display: inline-block;
  cursor: pointer;

  &:hover .tooltip {
    visibility: visible;
    opacity: 1;
  }
}

.tooltip {
  background-color: rgba($darkblue, 0.8);
  border-radius: 7px;
  color: white;
  cursor: default;
  font-size: 12px;
  line-height: 1.5;
  opacity: 0;
  padding: 10px;
  text-align: left;
  transition: 300ms all;
  visibility: hidden;

  position: absolute;
  z-index: 100;

  ::v-deep ul {
    margin-top: 0;
    margin-bottom: 0;
    padding-left: 15px;

    &, li {
      list-style-type: disc;
    }
  }
}

</style>
