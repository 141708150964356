
<script>

export default {

  name: 'NotFound'
}

</script>

<template>

  <div>
    <div class="text-center mt-5">
      <h2>Seite nicht gefunden</h2>
    </div>
  </div>

</template>
