
<script>

export default {
  name: 'Dropdown',

  props: ['title', 'icon', 'look', 'right', 'preventClose', 'linkLook', 'dropdownId'],

  data() {
    return {

      isOpen: false
    }
  },

  created() {
    document.body.addEventListener('click', this.closeFromOutside)
    if (!this.dropdownId) {
      throw new Error('dropdownId is required. Otherwise the outsideClick-handler would not work!')
    }
  },

  destroyed() {
    document.body.removeEventListener('click', this.closeFromOutside)
  },

  watch: {

    $route() {
      if (this.preventClose) return
      this.isOpen = false
    }
  },

  methods: {

    toggle() {
      this.isOpen = !this.isOpen

      if (this.isOpen) {
        this.$bus.$emit('dropdown-open')
      }
    },

    close() {
      if (this.preventClose) return

      this.isOpen = false
    },

    closeFromOutside(event) {
      const shouldIgnore = typeof event.path === 'undefined' || event.path.some(el => el?.id === this.dropdownId)
      if (shouldIgnore) return
      this.isOpen = false
    }
  }
}
</script>

<template>

  <div
    class="dropdown"
    :class="look"
    :id="dropdownId"
  >
    <span class="dropdown-toggle nav-link" :class="linkLook" @click="toggle">
      <i v-if="icon" :class="icon"></i>
      {{ title }}
    </span>
    <transition name="dropdown" appear>
      <div
        class="dropdown-menu show"
        :class="{ 'dropdown-menu-right': right }"
        @click="close"
        v-show="isOpen"
      >
        <slot></slot>
      </div>
    </transition>
  </div>

</template>

<style lang="scss" scoped>
@import 'src/assets/sass/variables';

.dropdown-menu {
  border: 1px solid $darkblue;
}
</style>
