
<script>

import Api from '../Api'
import debounce from 'lodash.debounce'
import BenchmarkFilter from './BenchmarkFilter'

export default {
  name: 'BenchmarkSearch',
  components: { BenchmarkFilter },
  props: {
    entity: {
      type: String,
      required: true
    },
    items: {
      type: Array,
      required: false,
      default: () => []
    }
  },

  data() {
    return {

      query: null,
      queries: {
        provider: '',
        product_group: '',
        product: '',
        pzn: '',
        atc3: '',
        atc5: '',
        atc7: ''
      },
      selected: {
        provider: false,
        product_group: false,
        product: false,
        pzn: false,
        atc3: false,
        atc5: false,
        atc7: false
      },
      result: [],
      tree: [],

      suggestions: [],
      selectedItems: [],
      searchBatches: [],
      hasSelected: false
    }
  },

  watch: {

    selectedItems(items) {
      this.hasSelected = true

      this.refreshTree()
    }
  },

  mounted() {
    if (this.items.length) {
      this.selectedItems = this.items
    }

    const prevSelectedItems = this.$store.getters.getPreviousBenchmarksItems
    if (this.entity !== 'diagnosis' && prevSelectedItems.length > 0) {
      this.selectedItems = prevSelectedItems
    }

    if (this.entity === 'diagnosis') {
      this.$refs.input.select()
    }
  },

  computed: {

    mergedSelectedItems() {
      return this.searchBatches.flat().concat(this.selectedItems).filter((value, index, self) => self.indexOf(value) === index)
    }
  },

  methods: {

    reset() {
      this.result = []
      this.suggestions = []
      this.query = null

      for (const key in this.queries) {
        this.queries[key] = ''
        this.selected[key] = false
      }

      this.$bus.$emit('reset-benchmark-filter')
    },

    resetSelection() {
      this.$store.dispatch('setPreviousBenchmarksItems', [])
      this.searchBatches = []
      this.selectedItems = []
    },

    submit() {
      this.$store.dispatch('setPreviousBenchmarksItems', this.mergedSelectedItems)
      this.$emit('submit', { items: this.mergedSelectedItems })
    },

    refreshTree() {
      const params = {}

      if (this.entity === 'diagnosis') {
        params.icd = this.mergedSelectedItems.join(',')
      } else {
        params.pzn = this.mergedSelectedItems.join(',')
      }

      Api.benchmarks.tree(this.entity, params).then(response => {
        this.tree = response.data
      })
    },

    deleteItem(id) {
      const i = this.selectedItems.findIndex(item => item === id)

      if (i > -1) {
        this.$delete(this.selectedItems, i)
      } else {
        this.searchBatches.forEach((batch, j) => {
          const i = this.searchBatches[j].findIndex(item => item === id)

          if (i > -1) {
            this.$delete(this.searchBatches[j], i)
          }
        })
      }
    },

    removeItem(id) {
      this.deleteItem(id)
      this.refreshTree()
    },

    removeChild(child) {
      child.pzns.forEach(pzn => {
        this.deleteItem(pzn)
      })

      this.refreshTree()
    },

    removeRoot(root) {
      root.children.forEach(child => {
        child.pzns.forEach(pzn => {
          this.deleteItem(pzn)
        })
      })

      this.refreshTree()
    },

    toggleItem(id) {
      const i = this.selectedItems.findIndex(item => item === id)

      if (i > -1) {
        this.$delete(this.selectedItems, i)
      } else {
        this.selectedItems.push(id)
      }
    },

    selectAll() {
      if (this.entity === 'diagnosis') {
        this.selectedItems = this.result.map(item => item.icd3_code || item.icd5_code)
      } else {
        this.selectedItems = this.result.map(item => item.pzn)
      }
    },

    selectNone() {
      this.selectedItems = []
    },

    input: debounce(function() {
      let data = {}
      let length = 0

      if (this.entity === 'diagnosis') {
        data = { q: this.query }
        length = this.query.length
      } else {
        for (const key in this.queries) {
          data['q_' + key] = this.queries[key].length ? this.queries[key] : ''
          data['q_' + key + '_selected'] = this.selected[key]
          length += this.queries[key].length
        }
      }

      if (length < 3) {
        this.result = []
        this.suggestions = []
        return
      }

      Api.benchmarks.search(this.entity, data).then(response => {
        if (this.hasSelected) {
          this.searchBatches.push(this.selectedItems)
          this.selectedItems = []
          this.hasSelected = false

          let ids = []

          if (this.entity === 'diagnosis') {
            ids = response.data.map(item => item.icd3_code || item.icd5_code)
          } else {
            ids = response.data.table_data.map(item => item.pzn)
          }

          this.selectedItems = this.mergedSelectedItems.filter(item => ids.includes(item))
        }

        if (this.entity === 'diagnosis') {
          this.result = response.data
          this.suggestions = []
        } else {
          this.result = response.data.table_data
          this.suggestions = response.data.search_data
        }
      })
    }, 150),

    filterInput(attr, data) {
      this.selected[attr] = data.selected
      this.queries[attr] = data.query
      this.input()
    }
  }
}

</script>

<template>

  <div class="benchmark-search">

    <div class="row-fix">
      <div class="col-8 col-fix">

        <!-- Title und subtitle of modal -->
        <h2 class="mb-0">{{ $t('benchmarks.search_titles.'+entity) }}</h2>
        <p class="lead">{{ $t('benchmarks.search_subtitles.'+entity) }}</p>

        <!-- Modal input-Field for "Diagnoseeintragungen" -->
        <div class="search-form" v-if="entity === 'diagnosis'">
          <div class="input-row">
            <i class="fa fa-search"></i>
            <input type="text" class="form-control form-control-lg" ref="input" v-model="query" @input="input" placeholder="Suche...">
          </div>
        </div>

        <!-- Modal input-field for "Verordnungen" and "Umsätze und Abverkäufe" -->
        <div class="search-form search-form-sm mb-3" v-else>
          <div class="row">
            <div class="col">
              <div class="mb-3">
                <BenchmarkFilter :setfocus="true" v-on:input="data => filterInput('provider', data)" :result="suggestions ? suggestions['anbieter'] : []" :placeholder="$t('benchmarks.search.provider')"></BenchmarkFilter>
              </div>
              <div>
                <BenchmarkFilter v-on:input="data => filterInput('product_group', data)" :result="suggestions ? suggestions['produktgruppe'] : []" :placeholder="$t('benchmarks.search.product_group')"></BenchmarkFilter>
              </div>
            </div>
            <div class="col">
              <div class="mb-3">
                <BenchmarkFilter v-on:input="data => filterInput('product', data)" :result="suggestions ? suggestions['produkt'] : []" :placeholder="$t('benchmarks.search.product')"></BenchmarkFilter>
              </div>
              <div>
                <BenchmarkFilter v-on:input="data => filterInput('pzn', data)" :result="suggestions ? suggestions['pzn'] : []" :placeholder="$t('benchmarks.search.pzn')"></BenchmarkFilter>
              </div>
            </div>
            <div class="col">
              <div class="mb-3">
                <BenchmarkFilter v-on:input="data => filterInput('atc3', data)" :result="suggestions ? suggestions['atc3'] : []" :placeholder="$t('benchmarks.search.atc3')"></BenchmarkFilter>
              </div>
              <div>
                <BenchmarkFilter v-on:input="data => filterInput('atc5', data)" :result="suggestions ? suggestions['atc5'] : []" :placeholder="$t('benchmarks.search.atc5')"></BenchmarkFilter>
              </div>
            </div>
            <div class="col">
              <div class="mb-3">
                <BenchmarkFilter v-on:input="data => filterInput('atc4', data)" :result="suggestions ? suggestions['atc4'] : []" :placeholder="$t('benchmarks.search.atc4')"></BenchmarkFilter>
              </div>
              <div>
                <BenchmarkFilter v-on:input="data => filterInput('atc7', data)" :result="suggestions ? suggestions['atc7'] : []" :placeholder="$t('benchmarks.search.atc7')"></BenchmarkFilter>
              </div>
            </div>
          </div>
        </div>

        <!-- Control buttons over the search result table -->
        <div class="d-flex flex-row my-3">
          <div style="height:31px;"></div>
          <span v-if="result.length" class="btn btn-sm btn-light mr-auto" @click="reset">{{ $t('benchmarks.reset_filter') }}</span>
          <span v-if="result.length" class="btn btn-sm btn-light mr-2" @click="selectAll">{{ $t('benchmarks.select_all') }} ({{ result.length }})</span>
          <span v-if="result.length" class="btn btn-sm btn-light" @click="selectNone">{{ $t('benchmarks.select_none') }}</span>
        </div>

        <!-- Search result table -->
        <transition name="fade" appear>
          <div class="box-result">

              <table class="table table-sm table-hover" v-if="entity==='diagnosis'">
                <thead>
                <tr>
                  <th>&nbsp;</th>
                  <th>ICD-Kategorie</th>
                  <th>Subkategorien</th>
                  <th>Bezeichnung</th>
                </tr>
                </thead>
                <tbody v-if="result.length">
                <tr v-for="(item, i) in result" :key="i">
                  <td>
                    <div class="custom-control custom-checkbox">
                      <input type="checkbox" class="custom-control-input" :id="'check_'+ (item.icd3_code || item.icd5_code)" :value="item.icd3_code || item.icd5_code" v-model="selectedItems">
                      <label class="custom-control-label" :for="'check_'+ ( item.icd3_code || item.icd5_code)"></label>
                    </div>
                  </td>
                  <td @click="toggleItem(item.icd3_code || item.icd5_code)">{{ item.icd3_code }}</td>
                  <td @click="toggleItem(item.icd3_code || item.icd5_code)">{{ item.icd5_code }}</td>
                  <td @click="toggleItem(item.icd3_code || item.icd5_code)">{{ item.icd3_desc || item.icd5_desc }}</td>
                </tr>
                </tbody>
              </table>

              <table class="table table-sm table-hover" v-else>
                <thead>
                  <tr>
                    <th>&nbsp;</th>
                    <th>{{ $t('benchmarks.labels.anbieter') }}</th>
                    <th>{{ $t('benchmarks.labels.produktgruppe') }}</th>
                    <th>{{ $t('benchmarks.labels.produkt') }}</th>
                    <th>{{ $t('benchmarks.labels.pzn') }}</th>
                    <th>{{ $t('benchmarks.labels.atc') }}</th>
                    <th>{{ $t('benchmarks.labels.atc_name') }}</th>
                  </tr>
                </thead>
                <tbody v-if="result.length">
                  <tr v-for="item in result" :key="item.pzn">
                    <td>
                      <div class="custom-control custom-checkbox">
                        <input type="checkbox" class="custom-control-input" :id="'check_'+item.pzn" :value="item.pzn" v-model="selectedItems">
                        <label class="custom-control-label" :for="'check_'+item.pzn"></label>
                      </div>
                    </td>
                    <td @click="toggleItem(item.pzn)">{{ item.anbieter }}</td>
                    <td @click="toggleItem(item.pzn)">{{ item.produktgruppe }}</td>
                    <td @click="toggleItem(item.pzn)">{{ item.produkt }}</td>
                    <td @click="toggleItem(item.pzn)">{{ item.pzn }}</td>
                    <td @click="toggleItem(item.pzn)">{{ item.atc }}</td>
                    <td @click="toggleItem(item.pzn)">{{ item.atc_name }}</td>
                  </tr>
                </tbody>
              </table>

          </div>
        </transition>

      </div>

      <!-- Tree of selected items -->
      <transition name="fade" appear>
        <div class="col-4 col-fix" v-if="mergedSelectedItems.length">

          <h2 class="mb-0">Auswahl</h2>

          <div class="d-flex flex-column my-3">
            <div class="lead mb-1">
              {{ $tc('benchmarks.entries', mergedSelectedItems.length) }}
            </div>
            <div class="d-flex">
              <span class="btn btn-sm btn-light" @click="resetSelection">{{ $t('benchmarks.remove_all') }}</span>
              <span class="btn btn-sm btn-secondary ml-2" @click="submit">{{ $t('benchmarks.submit_selection') }} <i class="fa fa-angle-right"></i></span>
            </div>
          </div>

          <div class="box-result">
            <div class="tree" v-if="entity==='diagnosis'">
              <div class="d-flex flex-row align-items-start mb-2" v-for="item in tree" :key="item.id">
                <span class="icd">{{ item.id }}</span>
                <span class="name">{{ item.desc }} <span class="icon-link" @click="removeItem(item.id)"><i class="fa fa-times"></i></span></span>
              </div>
            </div>
            <div class="tree" v-else>
              <div class="root" v-for="(root, i) in tree" :key="i">
                <div class="label">
                  {{ root.root }} <span class="icon-link" @click="removeRoot(root)"><i class="fa fa-times"></i></span>
                </div>
                <template v-if="typeof root.children !== 'undefined'">
                  <div class="child" v-for="(child, i) in root.children" :key="i">
                  <div class="label">
                    {{ child.child }} <span class="icon-link" @click="removeChild(child)"><i class="fa fa-times"></i></span>
                  </div>
                  <template v-if="typeof child.pzns !== 'undefined'">
                    <div class="pzn" v-for="(pzn, i) in child.pzns" :key="i">
                      {{ pzn }} <span class="icon-link" @click="removeItem(pzn)"><i class="fa fa-times"></i></span>
                    </div>
                  </template>
                </div>
                </template>
              </div>
            </div>
          </div>

        </div>
      </transition>
    </div>

  </div>

</template>
