
<script>
import mixins from '../mixins'

export default {

  name: 'Tracking',
  mixins: [mixins],

  data() {
    return {

      closed: false
    }
  },

  watch: {

    $route(to, from) {
      this.closed = false
    }
  },

  computed: {
    user() {
      return this.$store.state.user.instance
    },

    show() {
      return !this.closed && this.user.check && this.user.tracking === null
    }
  },

  methods: {

    setTracking(tracking) {
      this.$store.dispatch('user/update', { tracking })
    }
  }

}

</script>

<template>

  <div>

    <transition name="fade">
      <div class="consent" v-if="show">

        <div class="close" @click="closed=true"><div class="fa fa-times"></div></div>

        <div class="container py-4">

          <div class="d-flex flex-row align-items-start">
            <div class="mr-auto">
              <h2 class="mb-1">Tracking-/Cookie-Einstellungen</h2>
              <p>Die Analyse von Ihrem persönlichen Nutzungsverhalten nachdem Sie sich angemeldet haben, ermöglicht es uns Ihnen künftig in dem Produkt personalisierte Services auf Basis Ihrer Verhaltenshistorie anzubieten und Ihnen maßgeschneiderte Werbekampagnen von intermedix anbieten zu können. Sie können dieses Tracking entsprechend akzeptieren oder ablehnen. Sie können diese Einstellung auch jederzeit nachträglich in der <router-link :to="{name:'privacy', hash:'#tracking'}">Datenschutzerklärung</router-link> abwählen.</p>
            </div>
            <span class="btn btn-primary" @click="setTracking(true)">Akzeptieren</span>
          </div>

          <div class="d-flex flex-row">
            <div class="mr-auto">
              <router-link :to="{name:'imprint'}" class="mr-2">Impressum</router-link>
              <router-link :to="{name:'privacy'}">Datenschutzerklärung</router-link>
            </div>
            <div>
              <span class="btn btn-outline-primary btn-sm mr-2" @click="setTracking(false)">Erhebung des personenbezogenen Nutzungsverhalten ablehnen</span>
            </div>
          </div>

        </div>

      </div>
    </transition>
  </div>

</template>
