
<script>

export default {

  name: 'LayoutFull'
}

</script>

<template>

  <div class="layout-full">
    <div class="main-col">
      <slot name="main"></slot>
    </div>
  </div>

</template>
