
<script>
import moment from 'moment'
import Page from './Page'
import Api from '../Api'
import XLSX from 'xlsx'


export default {
  name: 'Users',
  extends: Page,

  data() {
    return {
      list: [],
      form: null,
      sort_by: 'id',
      sort_asc: true,
      search: null,
      tableAttributes: [
        'id',
        'email',
        'firstname',
        'lastname',
        'company',
        'role',
        'created',
        'validFrom',
        'expiration',
        'activated'
      ]
    }
  },

  created() {
    Api.user.index().then(response => {
      this.list = response.data
    })
  },

  computed: {
    canDelete() {
      return this.form.id !== this.user.id
    },

    roles() {
      if (this.user.isIxAdmin) {
        return ['client_basic', 'client_company', 'client_pro', 'docmetric', 'test', 'ixadmin']
      }

      return ['client_basic', 'client_company', 'client_pro', 'docmetric', 'test', 'ixadmin', 'admin']
    },

    minDate() {
      return new Date().toISOString().split('T')[0]
    },

    processedList() {
      const needle = this.search && this.search.length > 2 ? this.search.toLowerCase() : null
      const sortDate = ['created', 'expiration'].includes(this.sort_by)

      return this.list.filter(item => {
        if (!needle) return true

        let found = false;

        ['email', 'firstname', 'lastname', 'company'].forEach(attr => {
          if (item[attr]) {
            const search = item[attr].toLowerCase().search(needle)

            if (search > -1) {
              found = true
            }
          }
        })

        return found
      }).sort((item1, item2) => {
        let sortMode = 0

        if (!item1[this.sort_by]) {
          sortMode = 1
        } else if (!item2[this.sort_by]) {
          sortMode = -1
        } else if (sortDate) {
          sortMode = moment(item1[this.sort_by]).isBefore(moment(item2[this.sort_by])) ? -1 : 1
        } else {
          sortMode = item1[this.sort_by].toLowerCase() < item2[this.sort_by].toLowerCase() ? -1 : 1
        }

        return sortMode * (this.sort_asc ? 1 : -1)
      })
    }
  },

  methods: {

    changeRole() {
      if (this.form.role === 'docmetric') {
        this.form.expiration = moment().add(1, 'year').format('YYYY-MM-DD')
      }

      if (this.form.role === 'test') {
        this.form.expiration = moment().add(1, 'days').format('YYYY-MM-DD')
      }

      if (this.form.role === 'ixadmin' || this.form.role === 'admin') {
        this.form.expiration = null
      }
    },

    index() {
      this.form = null
      this.alert = null
    },

    create() {
      this.form = {
        email: null,
        firstname: null,
        lastname: null,
        company: null,
        validfrom: this.minDate,
        expiration: moment().add(5, 'days').format('YYYY-MM-DD'),
        role: 'demo',
        activated: false,
        newsletter: false
      }

      this.alert = null
    },

    store() {
      Api.user.store(this.form).then(response => {
        this.list.unshift({ ...this.form, id: response.data.userid })
        this.index()

        this.alert = { type: 'success', message: 'users.created' }
      }).catch(this.alertHttpError)
    },

    edit(user) {
      if (user.expiration) {
        user.expiration = moment(user.expiration).format('YYYY-MM-DD')
      }

      if (user.validfrom) {
        user.validfrom = user.validfrom.split('T')[0]
      }

      this.form = user
      this.password = null
      this.alert = null
    },

    update() {
      Api.user.update(this.form.id, this.form).then(response => {
        this.index()
        this.alert = { type: 'success', message: 'users.updated' }
      }).catch(this.alertHttpError)
    },

    destroy() {
      if (!this.canDelete) {
        return
      }

      if (!confirm(this.$t('labels.are_you_sure'))) {
        return
      }

      Api.user.destroy(this.form.id).then(response => {
        this.$delete(this.list, this.list.findIndex(user => user.id === this.form.id))
        this.index()

        this.alert = { type: 'success', message: 'users.deleted' }
      }).catch(this.alertHttpError)
    },

    sort(attr) {
      if (this.sort_by === attr) {
        this.sort_asc = !this.sort_asc
      } else {
        this.sort_by = attr
      }
    },

    exportXls() {
      let data = this.list.map(row => {
        return [row.id, row.email, row.firstname, row.lastname, row.company, row.role, moment(row.created).format('DD.MM.YYYY'), moment(row.expiration).format('DD.MM.YYYY'), (row.activated ? 1 : 0)]
      })

      data = [this.tableAttributes, ...data]

      const xls = XLSX.utils.book_new()
      const sheet = XLSX.utils.json_to_sheet(data, { skipHeader: true })

      XLSX.utils.book_append_sheet(xls, sheet, 'Benutzer')
      XLSX.writeFile(xls, 'ixsight_users.xlsx')
    }
  },

  watch: {
    form: {
      handler(val) {
        if (!val) return

        if (val.role === 'ixadmin' || val.role === 'admin') {
          val.expiration = null
        }
      },
      deep: true
    }
  }
}

</script>

<template>

  <LayoutFull class="bg-white" v-if="user.isAdmin || user.isIxAdmin">
    <template v-slot:main>

      <Alert :alert="alert"></Alert>

      <div v-if="form && form.id">
        <div class="row justify-content-center">
          <div class="col-12 col-lg-7 col-xl-5">

            <form autocomplete="off" @submit.prevent="update">
              <h2>{{ $t('users.edit') }}</h2>
              <hr>
              <div class="form-group">
                <label for="id">{{ $t('user.id') }}:</label>
                <input type="text" class="form-control" id="id" :value="form.id" disabled>
              </div>
              <div class="form-group">
                <label for="created">{{ $t('user.created') }}:</label>
                <input type="text" class="form-control" id="created" :value="form.created | nice_date" disabled>
              </div>
              <div class="form-group">
                <label for="email">{{ $t('user.email') }}: <abbr>*</abbr></label>
                <input type="email" class="form-control" id="email" v-model="form.email" required>
              </div>
              <div class="form-group">
                <label for="firstname">{{ $t('user.firstname') }}: <abbr>*</abbr></label>
                <input type="text" class="form-control" id="firstname" v-model="form.firstname" required>
              </div>
              <div class="form-group">
                <label for="lastname">{{ $t('user.lastname') }}: <abbr>*</abbr></label>
                <input type="text" class="form-control" id="lastname" v-model="form.lastname" required>
              </div>
              <div class="form-group">
                <label for="company">{{ $t('user.company') }}:</label>
                <input type="text" class="form-control" id="company" v-model="form.company">
              </div>
              <div class="form-group">
                <label for="role">{{ $t('user.role') }}: <abbr>*</abbr></label>
                <select class="form-control" id="role" v-model="form.role" required>
                  <option v-for="role in roles" :value="role" :key="role">{{ $t('roles.'+role) }}</option>
                </select>
              </div>
              <div class="form-group" v-if="form.validfrom">
                <label for="validFrom2">{{ $t('user.validFrom') }}:</label>
                <input
                  type="date"
                  class="form-control"
                  id="validFrom2"
                  disabled
                  :max="form.expiration"
                  v-model="form.validfrom"
                >
              </div>
              <div class="form-group" v-if="form.role !== 'ixadmin' && form.role !== 'admin'">
                <label for="expiration2">{{ $t('user.expiration') }}:</label>
                <input
                  type="date"
                  class="form-control"
                  id="expiration2"
                  :min="form.validfrom"
                  v-model="form.expiration"
                >
              </div>
              <div class="form-group">
                <label for="password_new">{{ $t('auth.password_new') }}:</label>
                <input type="password" class="form-control" id="password_new" v-model="form.password" autocomplete="new-password">
              </div>
              <div class="form-group">
                <div class="custom-control custom-checkbox">
                  <input type="checkbox" class="custom-control-input" id="activated" v-model="form.activated">
                  <label class="custom-control-label" for="activated">{{ $t('user.activated') }}</label>
                </div>
              </div>
              <div class="form-group">
                <div class="custom-control custom-checkbox">
                  <input type="checkbox" class="custom-control-input" id="newsletter" v-model="form.newsletter">
                  <label class="custom-control-label" for="newsletter">{{ $t('user.newsletter') }}</label>
                </div>
              </div>
              <hr>
              <div class="flex-row">
                <button type="submit" class="btn btn-primary"><i class="fa fa-check"></i> {{ $t('labels.save') }}</button>
                <span class="btn btn-outline-primary ml-2" @click="index">{{ $t('labels.back') }}</span>
                <span v-if="canDelete" class="btn btn-outline-secondary ml-auto" @click="destroy"><i class="fa fa-times"></i> {{ $t('users.delete') }}</span>
              </div>
            </form>

          </div>
        </div>
      </div>

      <div v-else-if="form">
        <div class="row justify-content-center">
          <div class="col-12 col-lg-7 col-xl-5">

            <form autocomplete="off" @submit.prevent="store">
              <h2>{{ $t('users.create') }}</h2>
              <hr>
              <div class="form-group">
                <label for="email">{{ $t('user.email') }}: <abbr>*</abbr></label>
                <input type="email" class="form-control" id="email" v-model="form.email" required>
              </div>
              <div class="form-group">
                <label for="firstname">{{ $t('user.firstname') }}: <abbr>*</abbr></label>
                <input type="text" class="form-control" id="firstname" v-model="form.firstname" required>
              </div>
              <div class="form-group">
                <label for="lastname">{{ $t('user.lastname') }}: <abbr>*</abbr></label>
                <input type="text" class="form-control" id="lastname" v-model="form.lastname" required>
              </div>
              <div class="form-group">
                <label for="company">{{ $t('user.company') }}:</label>
                <input type="text" class="form-control" id="company" v-model="form.company">
              </div>
              <div class="form-group">
                <label for="role">{{ $t('user.role') }}: <abbr>*</abbr></label>
                <select class="form-control" id="role" v-model="form.role" required @change="changeRole">
                  <option v-for="role in roles" :value="role" :key="role">{{ $t('roles.'+role) }}</option>
                </select>
              </div>
              <div class="form-group">
                <label for="validFrom">{{ $t('user.validFrom') }}:</label>
                <input
                  type="date"
                  class="form-control"
                  id="validFrom"
                  :min="minDate"
                  :max="form.expiration"
                  v-model="form.validfrom"
                >
              </div>
              <div class="form-group" v-if="form.role !== 'ixadmin' && form.role !== 'admin'">
                <label for="expiration">{{ $t('user.expiration') }}:</label>
                <input
                  type="date"
                  class="form-control"
                  id="expiration"
                  :min="form.validfrom"
                  v-model="form.expiration"
                >
              </div>
              <div class="form-group">
                <label for="password_new">{{ $t('auth.password_new') }}: <abbr>*</abbr></label>
                <input type="password" class="form-control" id="password_new" v-model="form.password" required autocomplete="new-password">
              </div>
              <div class="form-group">
                <div class="custom-control custom-checkbox">
                  <input type="checkbox" class="custom-control-input" id="activated" v-model="form.activated">
                  <label class="custom-control-label" for="activated">{{ $t('user.activated') }}</label>
                </div>
              </div>
              <div class="form-group">
                <div class="custom-control custom-checkbox">
                  <input type="checkbox" class="custom-control-input" id="newsletter" v-model="form.newsletter">
                  <label class="custom-control-label" for="newsletter">{{ $t('user.newsletter') }}</label>
                </div>
              </div>
              <hr>
              <div class="flex-row">
                <button type="submit" class="btn btn-primary"><i class="fa fa-check"></i> {{ $t('labels.save') }}</button>
                <span class="btn btn-outline-primary ml-2" @click="index">{{ $t('labels.back') }}</span>
              </div>
            </form>

          </div>
        </div>
      </div>

      <div v-else-if="list">
        <div class="d-flex flex-row justify-content-center mb-3">
          <h2 class="mb-0">{{ $tc('users.index', list.length) }}</h2>
          <div class="ml-auto">
            <input type="text" class="form-control" style="width:320px" v-model="search" placeholder="Suche...">
          </div>
          <div>
            <span class="btn btn-primary ml-2" @click="create()"><i class="fa fa-plus"></i> {{ $t('users.create') }}</span>
            <span class="btn btn-outline-primary ml-2" @click="exportXls" title="Donwload XLSX"><i class="fa fa-download"></i></span>
          </div>
        </div>
        <table class="table table-striped table-hover table-sort">
          <thead>
            <tr>
              <th v-for="attr in tableAttributes" @click="sort(attr)" :key="attr">
                {{ $t('user.'+attr) }}
                <span class="sort">
                  <span v-if="sort_by === attr">
                    <span v-if="sort_asc"><i class="fa fa-sort-up"></i></span>
                    <span v-else><i class="fa fa-sort-down"></i></span>
                  </span>
                </span>
              </th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="user in processedList" @click="edit(user)" :key="user.id">
              <td v-html="user.id"></td>
              <td v-html="user.email"></td>
              <td v-html="user.firstname"></td>
              <td v-html="user.lastname"></td>
              <td v-html="user.company"></td>
              <td v-html="$t('roles.'+user.role)"></td>
              <td>{{ user.created | nice_date }}</td>
              <td>{{ user.validfrom | nice_date }}</td>
              <td>{{ user.expiration | nice_date }}</td>
              <td v-html="user.activated ? '<i class=\'fa fa-check\'></i>' : ''"></td>
            </tr>
          </tbody>
        </table>
      </div>

    </template>
  </LayoutFull>
</template>