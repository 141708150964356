
<script>

export default {

  name: 'Tab',

  props: ['title', 'open'],

  data() {
    return {

      isOpen: false
    }
  },

  created() {
    this.isOpen = this.open
  }

}

</script>

<template>

  <div class="tab" v-show="isOpen">
    <slot></slot>
  </div>

</template>
