
<script>

import Page from './Page'
import { mapGetters } from 'vuex'
import '@lottiefiles/lottie-player'
import hero from '../assets/hero.json'
import overflow1 from '../assets/images/intermedix_flow_01-blue.png'

export default {
  name: 'Home',
  extends: Page,

  computed: {
    docsCount() {
      // Todo: Update number according to latest Methodikpaper
      return '2.900'
    },

    drugstoresCount() {
      // Todo: Update number according to latest Methodikpaper
      return '2.600'
    },

    ...mapGetters(['searchTags']),

    hero() {
      return JSON.stringify(hero)
    },

    intermedixOverflow1() {
      return overflow1
    }
  }
}

</script>

<template>

  <div class="home">

    <section class="welcome screen">
      <div class="container-fluid h-100 py-4 py-md-0">
        <div class="row h-100">
          <div class="col-12 col-lg-6 order-1 order-md-0">
            <div class="flex-col justify-content-center h-100">
              <h1 class="main-title">Wochenaktuelle Healthcare-Daten<br> für bessere Entscheidungen</h1>
              <p class="lead lead-one">ixsight now ist die Plattform für Datenanalysen zur ambulanten Gesundheitsversorgung in Deutschland. Per einfacher Suchabfrage erhalten Sie wochenaktuelle Statistiken zu Diagnosen, Verordnungen, erbrachten ärztlichen Leistungen, abgegebenen Medikamenten, Marktanteilen, Preisen, Rabattverträgen und vielem mehr.</p>
              <div class="mt-4">
                <router-link :to="{name:'search'}" class="btn btn-secondary btn-lg">Zur Suche <i class="fa fa-angle-right ml-1"></i></router-link>
              </div>
            </div>
          </div>
          <div class="col-12 col-lg-6 order-0 order-md-1">
            <lottie-player
              autoplay
              loop
              speed=".25"
              :src="hero"
            >
            </lottie-player>
          </div>
        </div>
      </div>
    </section>

    <div class="section-transition">
      <img :src="intermedixOverflow1" alt="intermedix overflow">
    </div>

    <section class="screen bg-gradient flex-center">
      <div class="container my-5">
        <div class="row">
          <div class="col-12">
            <h1>Komplexe Medizindaten.<br>Auf den Punkt.</h1>
          </div>
          <div class="col-12 col-lg-6 text-center">
            <div class="px-4">
            <Slider :arrows="true">
              <Slide key="a">
                <div class="img-frame">
                  <img src="../assets/images/screen_stats_1.png">
                </div>
              </Slide>
              <Slide key="b">
                <div class="img-frame">
                  <img src="../assets/images/screen_stats_2.png">
                </div>
              </Slide>
              <Slide key="c">
                <div class="img-frame">
                  <img src="../assets/images/screen_stats_3.png">
                </div>
              </Slide>
            </Slider>
            </div>
          </div>
          <div class="col-12 col-lg-6">
            <div class="flex-col justify-content-center lead text-left">
              <p>Wir bereiten relevante Daten von Ärzten, Apotheken und Drittquellen für Sie auf – aktuell und repräsentativ.</p>
              <p><strong>Bei uns finden Sie Mengenverhältnisse, Trends und Korrelationen zu:</strong></p>
              <ul>
                <li>Diagnosestellungen (ICD)</li>
                <li>Arzneimittelprodukten (Produkte und PZN)</li>
                <li>Wirkstoffen (ATC)</li>
              </ul>
              <p>... und vieles mehr.</p>
              <div>
                <router-link :to="{name:'search'}" class="btn btn-secondary btn-lg mt-2">Jetzt testen <i class="fa fa-angle-right ml-1"></i></router-link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>

    <section class="screen">
      <div class="container-fluid py-4 py-md-5">
        <div class="row flex-fill">
          <div class="col-12 py-md-5 text-center">
            <h1>An unseren Daten lassen wir uns messen</h1>
          </div>
          <div class="col-12 col-lg-4 d-flex">
            <div class="px-md-5 mt-5 mt-md-0 d-flex flex-column flex-fill">
              <div class="text-center">
                <div class="icon-wrapper mb-4">
                  <img src="../assets/images/icon-atom.png">
                </div>
                <h2>Unsere Quellen</h2>
              </div>
              <p class="lead">Unsere Daten erhalten wir von repräsentativen Panels aus >{{docsCount}} niedergelassene Ärzten und >{{drugstoresCount}} Apotheken in Deutschland. Pro Tag verarbeiten wir aus diesen Panels</p>
              <ul class="lead">
                <li>über 90.000 tagesaktuelle Datensätze zu Verordnungen, Diagnosen und ärztlichen Leistungen</li>
                <li>über 100.000 Seitenaufrufe von Ärzten in ihrem Informationssystem</li>
                <li>über 650.000 Transaktionsdaten aus Apotheken.</li>
              </ul>
              <p class="lead">Alle Daten erhalten wir ausschließlich in anonymisierter Form.</p>
              <p class="lead">Zusätzlich integrieren wir externen Datenbanken, die Sie in den Quellenangaben der jeweiligen Statistiken finden. </p>
            </div>
          </div>
          <div class="col-12 col-lg-4 d-flex">
            <div class="px-md-5 mt-5 mt-md-0 d-flex flex-column flex-fill">
              <div class="text-center">
                <div class="icon-wrapper mb-4">
                  <img src="../assets/images/icon-analyse.png">
                </div>
                <h2>Unsere Analysen</h2>
              </div>
              <p class="lead">Die <strong>ixsight now</strong> Algorithmen berechnen aus einem Bestand von über 300 Millionen Daten innerhalb von 300 Millisekunden Ihre gewünschte Statistik. Mit einfach zu bedienenden Filtern können Sie diese Statistik weiter an Ihre Fragestellung anpassen, zum Beispiel durch Auswahl des Zeitraums, der Region oder patientendemographischer Merkmale.<br>In unserem Whitepaper beschreiben wir, welche Filter verfügbar sind und erläutern unsere Definitionen sowie die Methodik der Hochrechnung.</p>
              <div class="mt-auto">
                <a href="/files/ixsight-now_Anleitung-und-Methodik_public.pdf" target="_blank" class="btn btn-secondary btn-lg mt-auto">Whitepaper lesen<i class="fa fa-angle-right ml-1"></i></a>
              </div>
            </div>
          </div>
          <div class="col-12 col-lg-4 d-flex">
            <div class="px-md-5 mt-5 mt-md-0 d-flex flex-column flex-fill">
              <div class="text-center">
                <div class="icon-wrapper mb-4">
                  <img src="../assets/images/icon-diamond.png">
                </div>
                <h2>Ihr Mehrwert</h2>
              </div>
              <p class="lead"><strong>ixsight now</strong> bietet die aktuellsten Zahlen und Analysen zum deutschen ambulanten Gesundheitsmarkt und macht Ihnen diese auf einfachste Weise anschaulich zugänglich. Alle Statistiken enthalten transparente Information zu Datenquellen und Methodik und können per Mausklick in verschiedenen Formaten heruntergeladen werden.<br>Kontaktieren Sie uns für einen Testaccount oder wenn Sie Spezialanfragen haben. Unsere Analysten helfen Ihnen gerne weiter.</p>
              <div class="mt-auto">
                <router-link :to="{name:'contact'}" class="btn btn-secondary btn-lg">Kontakt aufnehmen<i class="fa fa-angle-right ml-1"></i></router-link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>

  </div>

</template>

<style lang="scss" scoped>
@import 'src/assets/sass/variables';

.main-title {
  margin-bottom: 30px;
}

.section-transition {
  img {
    max-width: 100%;
    // crop image:
    margin-bottom: -390px;
    margin-top: -14%;
  }
}

.icon-wrapper {
  display: inline-block;

  img {
    height: 100px;
  }
}
</style>
