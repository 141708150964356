import moment from 'moment'

export default {
  methods: {
    encodeHash(filter) {
      return encodeURIComponent(btoa(JSON.stringify(filter)))
    },

    decodeHash(hash) {
      return JSON.parse(atob(decodeURIComponent(hash)))
    },

    groupBy(arr, key2) {
      return arr.reduce((val, key) => {
        ;(val[key[key2]] = val[key[key2]] || []).push(key)
        return val
      }, {})
    },

    sortBy(arr, key) {
      return arr.sort((item1, item2) => {
        return item1[key] < item2[key] ? -1 : 1
      })
    }
  },

  filters: {
    nice_date(date) {
      return date ? moment(date).format('DD.MM.YYYY') : null
    },

    currency(val) {
      return new Intl.NumberFormat('de-DE', {
        style: 'decimal',
        minimumFractionDigits: 2,
        maximumFractionDigits: 2
      }).format(val)
    }
  }
}
