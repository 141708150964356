
<script>

import Page from './Page'
import Api from '../Api'

export default {

  name: 'ResetPassword',
  extends: Page,

  data() {
    return {

      form: {
        token: null,
        new: null,
        new_confirm: null
      }
    }
  },

  mounted() {
    this.form.token = this.$route.params.token
  },

  methods: {

    submit() {
      this.alert = null

      Api.auth.changeForgottenPassword(this.form).then(response => {
        this.alert = { type: 'success', message: 'auth.reset_password_success' }
      }).catch(this.alertHttpError)
    }
  }
}

</script>

<template>

  <div class="screen bg-gradient flex-center form-light">
    <div class="container">
      <div class="row justify-content-center">
        <div class="col-12 col-lg-7 col-xl-5">

          <h2>{{ $t('auth.reset_password') }}</h2>

          <Alert :alert="alert"></Alert>

          <form @submit.prevent="submit">
            <div class="form-group">
              <label for="password_new">{{ $t('auth.password_new') }}:</label>
              <input type="password" class="form-control form-control-lg" id="password_new" placeholder="" v-model="form.new" required>
            </div>
            <div class="form-group">
              <label for="password_confirm">{{ $t('auth.password_new_confirm') }}:</label>
              <input type="password" class="form-control form-control-lg" id="password_confirm" placeholder="" v-model="form.new_confirm" required>
            </div>
            <button type="submit" class="btn btn-secondary btn-lg btn-block mt-4">{{ $t('labels.reset') }}</button>
          </form>

        </div>
      </div>
    </div>
  </div>

</template>
