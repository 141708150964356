import Vue from 'vue'
import Vuex from 'vuex'
import Api from '../Api'
import filter from './filter'
import user from './user'
import { shouldShowBanner } from '../utils'

Vue.use(Vuex)

export default new Vuex.Store({
  modules: {
    filter: {
      namespaced: true,
      ...filter
    },
    user: {
      namespaced: true,
      ...user
    }
  },

  state: {
    isReady: false,
    showAuth: false,
    nextRoute: false,
    analyticsConsent: false,
    notification: {
      show: false,
      msg: '',
      icon: ''
    },

    searchTags: [],
    regions: [],
    agegroups: ['0-29', '30-59', '60-199'],
    genders: ['f', 'm'], // 'u','x','v','d'
    timeintervals: ['week', 'month', 'quarter'],
    formats: ['absolute', 'percent'],
    insuranceTypes: ['GKV', 'PKV'],
    salesRegulations: ['rx', 'otc'],
    reimport: ['no', 'yes'],
    previousBenchmarksItems: [],

    banner: {
      headerHeight: '185px',
      msg: 'Banner Placeholder...',
      show: false
    }
  },

  getters: {
    isReady: (state) => state.isReady,
    showAuth: (state) => state.showAuth,
    nextRoute: (state) => state.nextRoute,
    analyticsConsent: (state) => state.analyticsConsent,

    searchTags: (state) => state.searchTags,
    regions: (state) => state.regions,
    agegroups: (state) => state.agegroups,
    genders: (state) => state.genders,
    timeintervals: (state) => state.timeintervals,
    formats: (state) => state.formats,
    salesRegulations: (state) => state.salesRegulations,
    reimport: (state) => state.reimport,

    getPreviousBenchmarksItems: (state) => state.previousBenchmarksItems,
    getInsuranceTypes: (state) => state.insuranceTypes
  },

  mutations: {
    isReady: (state, value) => {
      state.isReady = value
    },

    showAuth: (state, value) => {
      state.showAuth = value
    },

    nextRoute: (state, value) => {
      state.nextRoute = value
    },

    analyticsConsent: (state, value) => {
      state.analyticsConsent = value
    },

    regions: (state, value) => {
      state.regions = value
    },

    searchTags: (state, value) => {
      state.searchTags = value
    },

    SET_PREVIOUS_BENCHMARKS_ITEMS: (state, value) => {
      state.previousBenchmarksItems = value
    },

    // snackbar
    SHOW_NOTIFICATION: (state, { msg, icon }) => {
      state.notification.show = true
      state.notification.msg = msg
      state.notification.icon = icon
      setTimeout(() => {
        state.notification.show = false
      }, 4000)
    },

    TOGGLE_BANNER: (state, { show, msg, headerHeight }) => {
      if (show !== undefined) state.banner.show = show
      if (headerHeight) state.banner.headerHeight = headerHeight
      if (msg) state.banner.msg = msg
    }
  },

  actions: {
    getReady({ commit, dispatch }) {
      const consent = window.localStorage.getItem('analyticsConsent')

      if (+consent === 1) {
        dispatch('analyticsConsent', true)
      }

      if (+consent === 0) {
        dispatch('analyticsConsent', false)
      }

      return new Promise((resolve, reject) => {
        const token = window.localStorage.getItem('accessToken')

        if (token) {
          dispatch('user/init', token)
            .then(() => {
              commit('isReady', true)
              commit('TOGGLE_BANNER', { show: shouldShowBanner() })
              resolve()
            })
            .catch(reject)
        } else {
          commit('isReady', true)
          resolve()
        }
      })
    },

    // async init({ commit }) {
    //   await Api.list.all().then((response) => {
    //     commit('regions', response.data.kvregions)
    //     commit('searchTags', response.data.searchtags)
    //   })
    // },

    async init({ commit }) {
      try {
        const response = await Api.list.all()
        commit('regions', response.data.kvregions)
        commit('searchTags', response.data.searchtags)
      } catch (error) {
        if (error.response) {
          // Server responded with a status other than 200 range
          console.error('Error response:', error.response.data)
          console.error('Error status:', error.response.status)
          console.error('Error headers:', error.response.headers)
        } else if (error.request) {
          // Request was made but no response received
          console.error('Error request:', error.request)
        } else {
          // Something else happened while setting up the request
          console.error('Error message:', error.message)
        }
        console.error('Error config:', error.config)
      }
    },

    analyticsConsent({ commit }, isAccepted) {
      window.localStorage.setItem('analyticsConsent', isAccepted ? 1 : 0)

      commit('analyticsConsent', isAccepted)

      if (isAccepted) {
        /* DO NOT REFACTOR THIS CODE BLOCK! */
        /* eslint-disable */
        var _paq = (window._paq = window._paq || [])
        /* Tracker methods like "setCustomDimension" should be called before "trackPageView". */
        _paq.push(['trackPageView'])
        _paq.push(['enableLinkTracking'])
        ;(function () {
          var u = 'https://matomo.prd.cgm.punkt.hosting/'
          _paq.push(['setTrackerUrl', u + 'matomo.php'])
          _paq.push(['setSiteId', '97'])
          var d = document
          var g = d.createElement('script')
          var s = d.getElementsByTagName('script')[0]
          g.type = 'text/javascript'
          g.async = true
          g.src = u + 'matomo.js'
          s.parentNode.insertBefore(g, s)
        })()
        /* eslint-enable */
        /* ------------------------------ */
      } else {
        window._paq = null
      }
    },

    setPreviousBenchmarksItems({ commit }, value) {
      commit('SET_PREVIOUS_BENCHMARKS_ITEMS', value)
    }
  }
})
