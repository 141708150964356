<script>
import { mapGetters, mapState } from 'vuex'
import XLSX from 'xlsx'
import moment from 'moment'
import html2canvas from 'html2canvas'
import JsPDF from 'jspdf'
import Highcharts from 'highcharts'
import HighchartsMore from 'highcharts/highcharts-more'

import Page from '../Page'
import Api from '../../Api'
import theme from '../../theme'
import { addFilterToExport, fillGapOnXAxis } from './utils'
import { StatIndex as SI, SpecializationGroup as SG } from '../../data'
import MultiChoiceFilter from '../../components/filters/MultiChoiceFilter'
import SpecInfo from '../../components/SpecInfo'
import FavoriteBtn from '../../components/FavoriteBtn'

require('highcharts/modules/exporting')(Highcharts)
HighchartsMore(Highcharts)
moment.locale('de')

// const CUBE_MAX_DATE = moment().isoWeekday(6).format('YYYY-MM-DD')

export default {
  name: 'StatisticDetail',
  components: { FavoriteBtn, MultiChoiceFilter, SpecInfo },
  extends: Page,

  data() {
    return {
      SG,
      SI,

      statId: null,
      // specialization: null,
      agegroup: null,
      gender: null,
      insurancetype: null,
      salesRegulation: null,
      reimport: null,
      timeinterval: 'month',
      format: 'absolute',
      startDate: null,
      endDate: null,
      minDate: null,
      who: true,
      extrapolate: true,
      selectedPzns: [], // if isSinglePzn = true : "selectedPzns" is just a single Number and no array!

      icds: [],
      selectedIcds: [],

      instance: null,
      showTable: false,
      isEmpty: false,
      isLoading: false,
      showExport: false,
      isSinglePzn: false,
      icdSearch: null,
      pznSearch: null,
      shouldDataReverse: false,

      item: null,
      meta: null,
      chart: null,
      months: []
    }
  },

  mounted() {
    Highcharts.setOptions(theme)
    this.loadMeta()
    if (this.isCubeStat) {
      this.endDate = moment().isoWeekday(-1).format('YYYY-MM-DD')
    }
  },

  watch: {
    filter: function () {
      if (!this.item) return
      this.updateChartData(this.renderChart)
    },

    format: function () {
      this.updateFormat()
    }
  },

  computed: {
    chartOptions() {
      switch (this.meta.presentation) {
        case 'bars':
          return {
            colors: ['#19355c'],
            chart: {
              type: 'bar',
              height: 600
            },
            plotOptions: {
              series: {
                dataLabels: {
                  enabled: true
                }
              }
            }
          }

        case 'columns':
          return {
            chart: {
              type: 'column',
              height: 600
            },
            plotOptions: {
              series: {
                dataLabels: {
                  enabled: true
                }
              }
            }
          }

        case 'stacked_bars':
          // https://www.highcharts.com/demo/bar-stacked
          return {
            chart: {
              type: 'bar',
              height: 600
            },
            plotOptions: {
              series: {
                stacking: 'normal'
              }
            }
          }

        case 'stacked_columns':
          // https://www.highcharts.com/demo/column-stacked
          return {
            chart: {
              type: 'column',
              height: 600
            },
            plotOptions: {
              series: {
                stacking: 'normal'
              }
            }
          }

        case 'doughnut':
          // https://www.highcharts.com/demo/pie-donut
          return {
            chart: {
              type: 'pie'
            }
          }

        case 'boxplot':
          // https://www.highcharts.com/demo/box-plot
          return {
            chart: {
              type: 'boxplot'
            }
          }

        case 'line':
          // https://www.highcharts.com/demo/line
          return {
            chart: {
              type: 'line'
            }
          }

        default:
          return {}
      }
    },

    endMonth: {
      get() {
        return this.endDate ? moment(this.endDate).format('YYYY-MM-DD') : null
      },

      set(val) {
        if (val) {
          const date = moment(val)

          if (date.isBefore(moment(this.startDate), 'month')) {
            this.startDate = date.startOf('month').format('YYYY-MM-DD')
          }

          this.endDate = date.endOf('month').format('YYYY-MM-DD')
        }
      }
    },

    filename() {
      return 'ixsight_' + this.item.code + ''
    },

    filter: {
      get() {
        return {
          specialization: this.selectedSpecializations
            .map((item) => item.value)
            .join(','),
          region: this.selectedRegions.map((item) => item.value).join(','),
          agegroup: this.agegroup,
          gender: this.gender,
          date1: this.startDate
            ? moment(this.startDate).format('YYYY-MM-DD')
            : null,
          date2: this.endDate
            ? moment(this.endDate).format('YYYY-MM-DD')
            : null,
          timeinterval: this.timeinterval,
          who: this.who,
          extrapolate: this.extrapolate,
          pzns: this.selectedPzns,
          insurancetype: this.insurancetype,
          salesregulation: this.salesRegulation,
          reimport: this.reimport,
          icd5s: this.selectedIcds.join(',')
        }
      },

      set(val) {
        ['specialization', 'region'].forEach((filterName) => {
          if (!val[filterName]) return
          const initialFilterVals = val[filterName].split(',')
          if (initialFilterVals.length > 0) {
            this.$store.commit('filter/init', {
              name: filterName,
              values: val[filterName].split(',')
            })
          }
        })

        this.agegroup = val.agegroup || null
        this.gender = val.gender || null

        this.startDate =
          val.date1 ||
          (this.meta ? this.meta.startdate : null) ||
          moment().subtract(6, 'month').startOf('month').format('YYYY-MM-DD')
        this.endDate = val.date2 || moment().isoWeekday(0).format('YYYY-MM-DD')
        this.timeinterval = val.timeinterval || 'month'
        this.who = val.who || true
        this.extrapolate = val.extrapolate || true

        this.selectedPzns = val.pzns || (this.item ? this.item.pzn : [])
        if (this.isSinglePzn && !val.pzns) this.selectedPzns = this.item.pzn[0]

        this.insurancetype = val.insurancetype || null
        this.salesRegulation = val.salesregulation || null
        this.reimport = val.reimport || null
        this.selectedIcds = val.icd5s?.split(',') || this.item?.icds || []
      }
    },

    formattedEndDate() {
      return this.endDate ? moment(this.endDate).format('DD.MM.YYYY') : null
    },

    formattedStartDate() {
      return this.selectedStartDate
        ? moment(this.selectedStartDate).format('DD.MM.YYYY')
        : null
    },

    infotext() {
      if (
        typeof this.meta.infotext.text !== 'undefined' &&
        this.meta.infotext.text.length > 0
      ) {
        return this.meta.infotext.text
          .map((obj) => {
            return '<strong>' + obj.title + '</strong><br>' + obj.text + '<br>'
          })
          .join('')
      }

      if (typeof this.meta.infotext.info !== 'undefined') {
        return this.meta.infotext.info
      }

      return this.meta.infotext
    },

    isCubeStat() {
      const cubeStats = [
        SI.DOC_GEN_PROD,
        SI.CALL_CUBE_1,
        SI.CALL_CUBE_2,
        SI.CALL_CUBE_3,
        SI.CALL_CUBE_4,
        SI.DOC_GEN_ATC,
        SI.CALL_CUBE_5,
        SI.CALL_CUBE_6,
        SI.CALL_CUBE_7,
        SI.CALL_CUBE_8
      ]
      return cubeStats.includes(this.statId)
    },

    isIcd5enabled() {
      const enabledIcd5Stats = [
        SI.BILLING_BY_ICD,
        SI.DIAG_BY_ICD_OT,
        SI.DIAG_BY_AGE_AND_SEX,
        SI.CO_DIAG_BY_ICD,
        SI.PRES_PROD_BY_ICD_OT,
        SI.PRES_PROD_BY_ICD,
        SI.PRES_SUB_BY_ICD,
        SI.PRES_SUB_BY_ICD_OT,
        SI.PRES_PROV_BY_ICD,
        SI.PRES_PROV_BY_ICD_OT
      ]
      return enabledIcd5Stats.includes(this.statId)
    },

    isOverTimeStat() {
      const overTimeStats = [
        SI.DIAG_BY_ICD_OT,
        SI.PRES_PROD_BY_ICD_OT,
        SI.PRES_SUB_BY_ICD_OT,
        SI.PRES_PROV_BY_ICD_OT,
        SI.DS_PROD_SALES_OT,
        SI.DS_PROD_REVENUE_OT,
        SI.PRES_PROD_OT,
        SI.DIAG_BY_PRES_PROD_OT,
        SI.PROD_PRICE_OT,
        SI.PRES_ATC_OT,
        SI.PRES_ATC_BY_PROV_OT,
        SI.DIAG_BY_PRES_ATC_OT,
        SI.DS_ATC_REVENUE_OT,
        SI.DS_ATC_SALES_BY_PROV_OT,
        SI.PRES_ATC_BY_PZN_OT
        // Deactivated:
        // SI.PRES_PROD_BY_SPEC_OT,
        // SI.PRES_ATC_BY_SPEC_OT,
        // SI.PRES_ATC_BY_PROD_OT
      ]

      return overTimeStats.includes(this.statId)
    },

    ...mapGetters({
      agegroups: 'agegroups',
      genders: 'genders',
      getInsuranceTypes: 'getInsuranceTypes',
      timeintervals: 'timeintervals',
      formats: 'formats'
    }),

    ...mapState({
      selectedRegions: (state) => state.filter.selectedRegions,

      selectedSpecializations(state) {
        let res = []
        if (this.isCubeStat) {
          res = state.filter.selectedCubeSpecs
        } else {
          res = state.filter.selectedSpecializations
        }
        return res
      }
    }),

    maxDate() {
      let res = null
      const today = moment().format('YYYY-MM-DD')
      if (this.isCubeStat) {
        res = moment().isoWeekday(-1).format('YYYY-MM-DD')
      } else {
        res = today
      }
      return res
    },

    queryIcds() {
      if (!this.icdSearch) {
        return this.icds
      }

      const input = this.icdSearch.toLowerCase()
      const queryIcds = this.icds.filter((item) =>
        item.name.toLowerCase().includes(input)
      )
      return queryIcds
    },

    queryPzns() {
      const needle =
        this.pznSearch && this.pznSearch.length
          ? this.pznSearch.toLowerCase()
          : null

      return this.item.pzn_desc.filter((pzn) => {
        return !needle || pzn[1].toLowerCase().search(needle) > -1
      })
    },

    selectedEndDate: {
      get() {
        return this.endDate ? moment(this.endDate).format('YYYY-MM-DD') : null
      },

      set(val) {
        if (val) {
          const date = moment(val)

          if (date.isBetween(moment(this.startDate), moment(), 'day', '[]')) {
            this.endDate = date.format('YYYY-MM-DD')
          }
        }
      }
    },

    selectedStartDate: {
      get() {
        return this.startDate
          ? moment(this.startDate).format('YYYY-MM-DD')
          : null
      },

      set(val) {
        if (val) {
          const date = moment(val)

          if (
            date.isBetween(
              moment(this.meta.startdate),
              moment(this.endDate),
              'day',
              '[]'
            )
          ) {
            this.startDate = date.format('YYYY-MM-DD')
          }
        }
      }
    },

    showMonth() {
      return [1, 3, 4, 6, 7, 9, 71, 22, 26, 78, 28, 30, 34, 76, 79].includes(
        this.statId
      )
    },

    startMonth: {
      get() {
        return this.startDate
          ? moment(this.startDate).format('YYYY-MM-DD')
          : null
      },

      set(val) {
        if (val) {
          const data = moment(val)

          if (data.isAfter(moment(this.endDate), 'month')) {
            this.endDate = data.endOf('month').format('YYYY-MM-DD')
          }

          this.startDate = data.startOf('month').format('YYYY-MM-DD')
        }
      }
    },

    subtitle() {
      if (this.meta) {
        if (this.format === 'absolute') {
          return this.replaceItem(this.item, this.meta.subtitle_absolute)
        }
        if (this.format === 'percent') {
          return this.replaceItem(this.item, this.meta.subtitle_percent)
        }
      }

      return null
    },

    tableData() {
      return this.formatTableData(this.chart)
    },

    title() {
      return this.replaceItem(this.item, this.meta.title)
    }
  },

  methods: {
    exportCustom(format) {
      this.showExport = true

      const margin = 40
      const outerWidth = 595 * 2
      const outerHeight = format === 'pdf' ? 842 * 2 : 860
      const innerWidth = outerWidth - margin * 2
      const innerHeight = outerHeight - margin * 2

      const fWidth = Math.floor(innerWidth * 0.25)

      const chartWidth = Math.floor(innerWidth * 0.75) - margin * 2
      const chartHeight = 720

      const tableWidth = innerWidth - margin * 2
      const tableHeight = 480

      this.$nextTick(() => {
        this.$refs.exportTable.style.width = tableWidth + 'px'
        const fHeight = +this.$refs.exportFilter.offsetHeight

        const canvas = this.$refs.exportCanvas
        const link = this.$refs.exportLink
        const ctx = canvas.getContext('2d')
        const dom = window.URL || window.webkitURL || window

        canvas.width = innerWidth
        canvas.height = innerHeight

        ctx.fillStyle = 'white'
        ctx.fillRect(0, 0, innerWidth, innerHeight)

        const options = {
          chart: {
            width: chartWidth,
            height: chartHeight,
            spacing: 0
          },
          title: {
            text: this.title
          },
          subtitle: {
            enabled: true,
            text: this.subtitle
          }
        }

        let chart = this.instance.getSVG(options)
        chart = new Blob([chart], { type: 'image/svg+xml' })
        chart = dom.createObjectURL(chart)

        const chartImg = new Image()
        chartImg.src = chart

        chartImg.onload = () => {
          ctx.drawImage(
            chartImg,
            fWidth + margin,
            margin,
            chartWidth,
            chartHeight
          )
          dom.revokeObjectURL(chart)

          html2canvas(this.$refs.exportFilter, {
            width: fWidth,
            height: fHeight,
            scrollX: 0,
            scrollY: 0
          }).then((res) => {
            ctx.drawImage(res, margin, margin, fWidth, fHeight)

            if (format === 'pdf') {
              html2canvas(this.$refs.exportTable, {
                width: tableWidth,
                height: tableHeight,
                scrollX: 0,
                scrollY: 0
              }).then((res) => {
                ctx.drawImage(
                  res,
                  margin,
                  chartHeight + margin + margin,
                  tableWidth,
                  tableHeight
                )

                const doc = new JsPDF({
                  format: [outerWidth / 2, outerHeight / 2],
                  unit: 'px',
                  compression: 'NONE'
                })

                doc.addImage(
                  canvas.toDataURL('image/png'),
                  'PNG',
                  0,
                  0,
                  outerWidth / 2,
                  outerHeight / 2
                )
                doc.save(this.filename + '.pdf')
              })
            }

            if (format === 'png') {
              link.href = canvas.toDataURL('image/png')
              link.download = this.filename + '.png'
              link.click()
            }

            if (format === 'jpg') {
              link.href = canvas.toDataURL('image/jpeg')
              link.download = this.filename + '.jpg'
              link.click()
            }

            this.showExport = false
          })
        }
      })
    },

    exportCsv() {
      this.updateChartData(this.renderCsv, { full: true })
    },

    exportXls() {
      this.updateChartData(this.renderXls, { full: true })
    },

    format_number(val) {
      if (this.meta.presentation === 'boxplot') {
        return new Intl.NumberFormat('de-DE', {
          style: 'decimal',
          minimumFractionDigits: 2,
          maximumFractionDigits: 2
        }).format(val)
      }

      return new Intl.NumberFormat('de-DE', {
        style: 'decimal',
        minimumFractionDigits: 0,
        maximumFractionDigits: 0
      }).format(val)
    },

    formatTableData(data) {
      let head = []
      const body = []
      let foot = []
      const sums = []

      switch (this.meta.presentation) {
        case 'stacked_bars':
        case 'stacked_columns':
          head = ['', ...data.xAxis.categories]
          data.series.forEach((item) => {
            body.push([item.name, ...item.data])

            item.data.forEach((el, i) => {
              if (typeof sums[i] === 'undefined') sums[i] = 0
              sums[i] += el
            })
          })
          foot = ['Summe', ...sums]
          break

        case 'line':
          head = [data.xAxis.title.text, data.yAxis.title.text]
          data.series[0].data.forEach((item) => {
            body.push([item[0], item[1]])
          })
          break

        case 'bars':
        case 'columns':
        case 'doughnut': {
          let sum = 0
          data.series[0].data.forEach((item) => {
            body.push([item.name, item.y])
            sum += item.y
          })
          foot = ['Summe', sum]
          break
        }

        case 'boxplot':
          head = [
            'KV-Region',
            'Minimum',
            '1. Quartil',
            'Median',
            '3. Quartil',
            'Maximum'
          ]
          data.series[0].data.forEach((rawStats, i) => {
            if (!rawStats) {
              rawStats = [0, 0, 0, 0, 0]
            }
            const [min, firstQuartile, median, thirdQuartile, max] = rawStats
            body.push([
              data.xAxis.categories[i],
              min,
              firstQuartile,
              median,
              thirdQuartile,
              max
            ])
          })
          break

        default:
          break
      }

      return { head, body, foot }
    },

    getMultiChoiceDropdownLabel(type) {
      let selectedItems = []
      let allItems = []

      switch (type) {
        case 'icd':
          selectedItems = this.selectedIcds
          allItems = this.icds // should change too
          break

        case 'pzn':
          selectedItems = this.selectedPzns
          allItems = this.item.pzn
          break
      }

      if (!selectedItems.length) {
        return this.$t('filter.labels.none')
      }

      if (selectedItems.length === allItems.length) {
        return this.$t('filter.labels.all') + ' (' + allItems.length + ')'
      }

      if (selectedItems.length === 1) {
        return selectedItems[0]
      }

      return this.$t('filter.labels.some') + ' (' + selectedItems.length + ')'
    },

    hasFilter(handle) {
      return this.meta && this.meta.params.includes(handle)
    },

    loadMeta() {
      const entity = this.$route.params.entity.toLowerCase()
      const code = this.$route.params.code
      this.statId = parseInt(this.$route.params.id)
      this.shouldDataReverse = this.isOverTimeStat

      const filter = this.$route.query.filter
      this.filter = filter ? this.decodeHash(filter) : {}

      const params = {}
      params[entity] = code

      Api.stats
        .meta(this.statId, params)
        .then((response) => {
          this.item = response.data.item
          this.meta = response.data.meta

          this.setMonths(this.meta.startdate)

          if (!this.selectedStartDate) {
            this.selectedStartDate = response.data.meta.startdate
          }

          if (
            this.item.entity === 'product' &&
            typeof this.item.pzn !== 'undefined'
          ) {
            this.selectedPzns = this.item.pzn || []
          }

          if (this.item.icds && this.isIcd5enabled) {
            this.icds = this.item.icd_desc.map((item) => {
              return {
                code: item[0],
                name: item[1]
              }
            })

            if (this.filter.icd5s) {
              this.selectedIcds = this.filter.icd5s.split(',')
            } else {
              this.selectedIcds = this.icds.map((item) => item.code)
            }
          }

          if (this.showMonth) {
            const isCurrentMonth =
              new Date().getMonth() === new Date(this.endDate).getMonth()
            if (isCurrentMonth) {
              this.endDate = moment()
                .subtract(1, 'month')
                .endOf('month')
                .format('YYYY-MM-DD')
            } else {
              this.endDate = moment(this.endDate)
                .endOf('month')
                .format('YYYY-MM-DD')
            }
          }

          this.isSinglePzn = [74, 75].includes(this.statId)

          if (this.isSinglePzn) {
            if (this.item.pzn_desc && this.item.pzn_desc.length) {
              this.selectedPzns = this.item.pzn_desc[0][0]
            }
          }

          this.setMinDate()

          this.updateChartData(this.renderChart)
        })
        .catch((err) => {
          console.error(err)
        })
    },

    parseMetaLink(str) {
      if (!str) return

      const search = 'hier'
      const i = str.lastIndexOf(search)

      if (i > -1) {
        str =
          str.substr(0, i) +
          '<a href="/files/ixsight-now_Anleitung-und-Methodik_full.pdf" target="_blank">' +
          search +
          '</a>' +
          str.substr(i + search.length)
      }

      return str
    },

    renderChart(response) {
      this.$router
        .replace({ query: { filter: this.encodeHash(this.filter) } })
        .catch(() => {})

      this.isLoading = false

      if (!response.data) {
        this.isEmpty = true
        return
      }

      this.chart = response.data
      this.isEmpty = false

      const options = response.data

      if (this.meta.presentation === 'bars') {
        this.chart.series[0].data = this.chart.series[0].data.map((item) => {
          return {
            code: item.code,
            name: item.name,
            y: Math.round(item.y),
            abs: item.y_abs
          }
        })

        options.xAxis = {
          type: 'category',
          categories: null,
          labels: {
            align: 'left',
            reserveSpace: true
          }
        }
      }

      if (
        [SI.CO_DIAG_BY_ICD, SI.CO_PRES_PROD_OT, SI.CO_PRES_ATC].includes(
          this.statId
        )
      ) {
        options.plotOptions = {
          series: {
            dataLabels: {
              enabled: true,
              format: '{y}%'
            }
          }
        }
      }

      if ([SI.CO_PRES_PROD_OT, SI.CO_PRES_ATC].includes(this.statId)) {
        options.tooltip = {
          formatter: function () {
            const formatNum = (val) =>
              new Intl.NumberFormat('de-DE', {
                style: 'decimal',
                minimumFractionDigits: 0,
                maximumFractionDigits: 0
              }).format(val)

            return (
              formatNum(options.series[0].data[this.x].abs) +
              ' ' +
              options.series[0].name
            )
          }
        }
      }

      if (this.statId === SI.DIST_OF_PRES_PROD_ON_DOCS) {
        options.yAxis.max = 100
        options.yAxis.title.enabled = true
      }

      if (this.statId === SI.PRES_ATC_OT) {
        options.legend = { enabled: false }
      }

      if (this.meta.presentation === 'doughnut') {
        const sum = options.series[0].data
          .map((i) => i.y)
          .reduce((num1, num2) => num1 + num2, 0)

        options.series[0] = {
          data: options.series[0].data.map((item) => {
            const num = Math.round((item.y / sum) * 100)

            item.name = '(' + num + '%) ' + item.name
            return item
          })
        }
      }

      if (this.meta.presentation === 'boxplot') {
        const formatNum = (val) =>
          new Intl.NumberFormat('de-DE', {
            style: 'decimal',
            minimumFractionDigits: 2,
            maximumFractionDigits: 4
          }).format(val)

        options.tooltip = {
          formatter: function () {
            let toolTip = ''
            toolTip += 'Maximum: ' + formatNum(this.point.high) + '<br>'
            toolTip += '3. Quartil: ' + formatNum(this.point.q3) + '<br>'
            toolTip += 'Median: ' + formatNum(this.point.median) + '<br>'
            toolTip += '1. Quartil: ' + formatNum(this.point.q1) + '<br>'
            toolTip += 'Minimum: ' + formatNum(this.point.low) + '<br>'

            return toolTip
          }
        }

        options.plotOptions = { boxplot: { medianColor: 'rgb(247,115,102)' } }
        options.legend = { enabled: false }
        options.yAxis.labels = {
          formatter: function () {
            return formatNum(this.value)
          }
        }
      }

      if (
        ['stacked_columns', 'stacked_bars', 'columns', 'bar'].includes(
          this.meta.presentation
        )
      ) {
        options.tooltip = {
          formatter: function () {
            const y = new Intl.NumberFormat('de-DE', {
              style: 'decimal',
              minimumFractionDigits: 0,
              maximumFractionDigits: 0
            }).format(this.y)

            let toolTip = '<b>' + this.series.name + ':</b> ' + y

            if (typeof this.percentage !== 'undefined') {
              toolTip += ' (' + Math.round(this.percentage) + '%)'
            }

            return toolTip
          }
        }
      }

      // Round summation label of stacked-bars/stacked-columns
      if (
        this.meta.presentation === 'stacked_columns' ||
        this.meta.presentation === 'stacked_bars'
      ) {
        this.chart.series.forEach((series, i) => {
          options.series[i].data = series.data.map((value) => Math.round(value))
        })
      }

      if (this.meta.presentation === 'stacked_columns') {
        const colors = theme.colors.slice(0, options.series.length)

        options.series.reverse().forEach((item, i) => {
          item.color = colors[i >= colors.length ? i - colors.length : i]
        })

        options.series.reverse()

        // this.chart.xAxis.categories = this.chart.xAxis.categories.map(c => c + '<br><span class="panelsize">(1234)</span>')
      }

      options.credits = {
        text: 'Quelle: ' + this.meta.sourcename
      }

      options.exporting = {
        chartOptions: {
          chart: {
            width: 1024,
            spacing: [30, 30, 30, 30]
          },
          title: {
            text: this.title
          },
          subtitle: {
            enabled: true,
            text: this.subtitle
          }
        }
      }

      if (this.instance) {
        this.instance.destroy()
      }

      this.instance = Highcharts.chart(
        this.$refs.placeholder,
        Object.assign(this.chartOptions, options)
      )
    },

    renderCsv(response) {
      this.isLoading = false

      let data = this.formatTableData(response.data)

      if (this.shouldDataReverse) {
        data.body = data.body.reverse()
      }

      data = [data.head, ...data.body]

      let csv = ''

      const filter = addFilterToExport(
        this.filter,
        this.selectedSpecializations,
        this.selectedRegions
      )

      csv = filter.head[0] + ';\n'
      filter.body.forEach((keyValArr) => {
        const key = keyValArr[0]
        const val = keyValArr[1]

        csv += `${key}: ${val};`
      })
      csv += '\n\n'

      data.forEach((row) => {
        row.forEach((cell) => {
          csv += (cell || '') + ';'
        })

        csv += '\n'
      })

      const link = this.$refs.exportLink

      link.href = 'data:text/csv;charset=utf-8,' + encodeURI(csv)
      link.download = 'ixsight.csv'
      link.click()
    },

    renderXls(response) {
      this.isLoading = false

      const filters = addFilterToExport(
        this.filter,
        this.selectedSpecializations,
        this.selectedRegions
      )

      let data = this.formatTableData(response.data)

      if (this.shouldDataReverse) {
        data.body = data.body.reverse()
      }

      data = [
        filters.head,
        ...filters.body,
        [],
        [],
        [],
        [this.title],
        [],
        data.head,
        ...data.body
      ]

      const xls = XLSX.utils.book_new()

      const sheet = XLSX.utils.json_to_sheet(data, { skipHeader: true })
      sheet['!cols'] = [{ wch: 90 }]

      XLSX.utils.book_append_sheet(
        xls,
        sheet,
        this.title.replace(/[^\w\s]/gi, '').substring(0, 30)
      )
      XLSX.writeFile(xls, 'ixsight.xlsx')
    },

    resetFilters() {
      ['region', 'specialization'].forEach((filterName) => {
        if (this.hasFilter(filterName)) {
          this.$store.commit('filter/resetSelection', filterName)
        }
      })

      this.filter = {}
    },

    searchHit(item) {
      this.item = item
      this.updateEntity()

      location.search = null // DOCMTL-595
    },

    selectSinglePzn(selectedPzn) {
      this.selectedPzns = selectedPzn
      this.pznSearch = ''

      const dropdown = this.$refs.singlePznDropdown
      dropdown.forceClose()
    },

    setDisabledEndDate(date) {
      return date < new Date(this.selectedStartDate) || date > new Date()
    },

    setDisabledStartDate(date) {
      return (
        moment(date).isBefore(moment(this.minDate), 'day') ||
        moment(date).isAfter(moment(this.selectedEndDate), 'day')
      )
    },

    setMinDate() {
      this.minDate = this.meta.startdate
    },

    setMonths(minMonth) {
      const months = []

      const startDate = moment(minMonth).startOf('month')
      const lastMonth = moment().subtract(1, 'month').endOf('month')

      while (startDate.isSameOrBefore(lastMonth)) {
        months.push({
          start: startDate.startOf('month').format('YYYY-MM-DD'),
          end: startDate.endOf('month').format('YYYY-MM-DD'),
          label: startDate.startOf('month').format('MMMM YYYY')
        })
        startDate.add(1, 'month')
      }

      if (!this.startMonth) {
        this.startMonth = months[0].start
      }
      this.months = months
    },

    updateChartData(callback, options) {
      if (!this.item) return
      if (!this.meta) return
      if (this.isLoading) return

      let filter = this.filter

      delete filter.pzns
      delete filter.icds

      if (!this.hasFilter('who')) {
        delete filter.who
      }

      if (!this.hasFilter('extrapolate')) {
        delete filter.extrapolate
      }

      if (!this.hasFilter('specialization')) {
        delete filter.specialization
      } else {
        if (!filter.specialization) {
          this.isEmpty = true
          return
        }
      }

      if (this.hasFilter('region') && !filter.region) {
        this.isEmpty = true
        return
      }

      if (this.item.entity === 'product') {
        if (!this.selectedPzns || !this.selectedPzns.length) {
          this.isEmpty = true
          return
        }
        filter.pzn = Array.isArray(this.selectedPzns)
          ? this.selectedPzns.join(',')
          : this.selectedPzns
      } else if (this.item.entity === 'icd' && this.isIcd5enabled) {
        if (this.selectedIcds.length === 0) {
          this.isEmpty = true
          return
        }
        filter[this.item.entity.toLowerCase()] = this.selectedIcds.join(',')
      } else {
        filter[this.item.entity.toLowerCase()] = this.item.code
      }

      if (
        this.item.entity === 'atc' &&
        typeof this.item.atc_7_codes !== 'undefined'
      ) {
        filter.atc = this.item.atc_7_codes.join(',')
      }

      if (options) {
        filter = Object.assign(filter, options)
      }

      this.isLoading = true

      Api.stats
        .chart(this.statId, filter)
        .then((res) => {
          if (
            res?.data?.xAxis?.categories &&
            !res.config.params.specialization
          ) {
            const isCategoryDate = /2[0-9]{3}-[0-9]{1,2}/.test(
              res.data.xAxis.categories[0]
            )
            if (isCategoryDate) {
              res.data.xAxis.categories = fillGapOnXAxis(
                res,
                this.startDate,
                this.endDate,
                this.timeinterval
              )
            }
          }
          callback(res)
        })
        .catch((err) => {
          console.error(err)
        })
    },

    updateEntity() {
      this.$router
        .push({
          name: 'statistics.show',
          params: {
            entity: this.item.entity.toLowerCase(),
            code: this.item.code,
            id: this.statId
          },
          query: { filter: this.encodeHash(this.filter) }
          // eslint-disable-next-line
        })
        .catch(() => {})

      this.loadMeta()
    },

    updateFormat() {
      this.instance.update({
        plotOptions: {
          column: {
            stacking: this.format === 'percent' ? 'percent' : 'normal'
          }
        }
      })
    }
  }
}
</script>

<template>
  <LayoutDetail>
    <!-- eslint-disable-next-line -->
    <template v-slot:side v-if="meta" ref="filter">
      <div class="text-right mb-4">
        <router-link
          :to="{
            name: 'statistics.index',
            params: { entity: item.entity, code: item.code }
          }"
          class="btn btn-outline-secondary btn-sm"
        >
          <i class="fa fa-angle-left mr-1"></i>{{ $t('labels.back') }}
        </router-link>
      </div>

      <div class="flex-row mb-4">
        <h3 class="m-0">{{ $t('statistics.filter') }}</h3>
      </div>

      <div class="form-group" v-if="hasFilter('icd')">
        <label
          >{{
            isIcd5enabled ? $t('filter.labels.icds') : $t('filter.labels.icd')
          }}:</label
        >
        <SearchForm
          v-on:hit="searchHit"
          type="icd"
          :value="item.desc"
        ></SearchForm>
      </div>

      <div class="form-group" v-if="hasFilter('icd5')">
        <label>{{ $t('filter.labels.icd5') }}:</label>

        <!-- TODO: Adjust MultiChoiceFilter.vue for queryIcds and icdSearch, then use it for ICD5-Filter -->
        <Dropdown
          :dropdown-id="'multiChoiceIcd'"
          :title="getMultiChoiceDropdownLabel('icd')"
          :prevent-close="true"
          look="form-control select-dropdown multi-choice-dd"
        >
          <div class="dd-header">
            <div class="d-flex flex-row justify-content-around">
              <span
                class="lightblue text-small text-link"
                @click="selectedIcds = icds.map((icd) => icd.code)"
                >{{ $t('filter.labels.select_all') }}</span
              >
              <span
                class="lightblue text-small text-link"
                @click="selectedIcds = []"
                >{{ $t('filter.labels.select_none') }}</span
              >
            </div>
            <div class="form-white mt-2">
              <input
                type="text"
                class="form-control form-control-sm"
                v-model="icdSearch"
                placeholder="Suche..."
              />
            </div>
          </div>
          <div class="list">
            <div
              class="custom-control custom-checkbox"
              v-for="icd in queryIcds"
              :key="icd.code"
            >
              <input
                type="checkbox"
                class="custom-control-input"
                :id="'check_' + icd.code"
                :value="icd.code"
                v-model="selectedIcds"
              />
              <label class="custom-control-label" :for="'check_' + icd.code">{{
                icd.name
              }}</label>
            </div>
          </div>
        </Dropdown>
      </div>

      <div class="form-group" v-if="hasFilter('atc')">
        <label>{{ $t('filter.labels.atc') }}:</label>
        <SearchForm
          v-on:hit="searchHit"
          type="atc"
          :value="item.desc"
        ></SearchForm>
      </div>

      <div class="form-group" v-if="hasFilter('pzn')">
        <label>{{ $t('filter.labels.product') }}:</label>
        <SearchForm
          v-on:hit="searchHit"
          type="product"
          :value="item.desc ? item.desc : item.code"
        ></SearchForm>
      </div>

      <div
        class="form-group"
        v-if="hasFilter('pzn') && typeof item.pzn !== 'undefined'"
      >
        <label>{{ $t('filter.labels.pzn') }}:</label>

        <Dropdown
          v-if="isSinglePzn"
          :dropdown-id="'singlePzn'"
          look="form-control select-dropdown"
          :preventClose="true"
          ref="singlePznDropdown"
          :title="selectedPzns"
        >
          <div class="form-white mt-2 single-pzn">
            <input
              type="text"
              class="form-control form-control-sm"
              v-model="pznSearch"
              placeholder="Suche..."
            />
            <div
              v-for="pzn in queryPzns"
              :value="pzn[0]"
              @click="selectSinglePzn(pzn[0])"
              :key="pzn[0]"
              :class="{ selected: selectedPzns === pzn[0] }"
            >
              {{ pzn[1] }}
            </div>
          </div>
        </Dropdown>

        <!-- TODO: Adjust MultiChoiceFilter.vue and PZNs objects for each other, then use the CMP for this filter -->
        <Dropdown
          v-else
          :dropdown-id="'multiChoicePzn'"
          :title="getMultiChoiceDropdownLabel('pzn')"
          :prevent-close="true"
          look="form-control select-dropdown multi-choice-dd"
        >
          <div class="dd-header">
            <div class="d-flex flex-row justify-content-around">
              <span
                class="lightblue text-small text-link"
                @click="selectedPzns = item.pzn"
                >{{ $t('filter.labels.select_all') }}</span
              >
              <span
                class="lightblue text-small text-link"
                @click="selectedPzns = []"
                >{{ $t('filter.labels.select_none') }}</span
              >
            </div>
            <div class="form-white mt-2">
              <input
                type="text"
                class="form-control form-control-sm"
                v-model="pznSearch"
                placeholder="Suche..."
              />
            </div>
          </div>
          <div class="list">
            <div
              class="custom-control custom-checkbox"
              v-for="(pzn, i) in queryPzns"
              :key="i"
            >
              <input
                type="checkbox"
                class="custom-control-input"
                :id="'check_' + pzn[0]"
                :value="pzn[0]"
                v-model="selectedPzns"
              />
              <label class="custom-control-label" :for="'check_' + pzn[0]">{{
                pzn[1]
              }}</label>
            </div>
          </div>
        </Dropdown>
      </div>

      <div class="form-group" v-if="hasFilter('reimport')">
        <label for="selectReimport">{{ $t('filter.labels.reimport') }}:</label>
        <div class="custom-select-wrapper">
          <select class="custom-select" id="selectReimport" v-model="reimport">
            <option :value="null">{{ $t('filter.labels.all') }}</option>
            <option
              v-for="value in $store.getters.reimport"
              :value="value"
              :key="value"
            >
              {{ $t('filter.reimport.' + value) }}
            </option>
          </select>
        </div>
      </div>

      <div class="form-group mt-4" v-if="hasFilter('who')">
        <label class="switch" for="who">
          <input type="checkbox" id="who" v-model="who" />
          <span class="toggle"></span>
        </label>
        <div>
          <span class="text nowrap">{{ $t('filter.labels.who') }}</span>
          <span class="help ml-1" :title="$t('filter.infos.who')">
            <i class="fa fa-question-circle"></i>
          </span>
        </div>
      </div>

      <div class="form-group mt-4" v-if="hasFilter('extrapolate')">
        <label class="switch" for="extrapolate">
          <input type="checkbox" id="extrapolate" v-model="extrapolate" />
          <span class="toggle"></span>
        </label>
        <div>
          <span class="text nowrap">{{ $t('filter.labels.extrapolate') }}</span>
          <span class="help ml-1" :title="$t('filter.infos.extrapolate')">
            <i class="fa fa-question-circle"></i>
          </span>
        </div>
      </div>

      <br />

      <div v-if="showMonth" class="form-group">
        <label for="select_start_month"
          >{{ $t('filter.labels.startmonth') }}:</label
        >
        <div class="custom-select-wrapper">
          <select
            class="custom-select"
            id="select_start_month"
            v-model="startMonth"
          >
            <option
              v-for="item in months"
              :value="item.start"
              :key="item.label"
            >
              {{ item.label }}
            </option>
          </select>
        </div>
      </div>

      <div v-if="showMonth" class="form-group">
        <label for="select_end_month"
          >{{ $t('filter.labels.endmonth') }}:</label
        >
        <div class="custom-select-wrapper">
          <select
            class="custom-select"
            id="select_end_month"
            v-model="endMonth"
          >
            <option v-for="item in months" :value="item.end" :key="item.label">
              {{ item.label }}
            </option>
          </select>
        </div>
      </div>

      <div class="form-group" v-if="!showMonth && hasFilter('date1')">
        <label for="select_enddate">{{ $t('filter.labels.startdate') }}:</label>
        <date-picker
          v-if="isSafari"
          :append-to-body="false"
          :clearable="false"
          :disabled-date="setDisabledStartDate"
          format="YYYY-MM-DD"
          input-class="form-control"
          lang="de"
          v-model="selectedStartDate"
          required
          valueType="format"
        />
        <input
          v-else
          type="date"
          class="form-control"
          id="select_startdate"
          :min="minDate"
          :max="endDate"
          v-model="selectedStartDate"
          required
        />
      </div>

      <div class="form-group" v-if="!showMonth && hasFilter('date2')">
        <label for="select_enddate">{{ $t('filter.labels.enddate') }}:</label>
        <date-picker
          v-if="isSafari"
          :append-to-body="false"
          :clearable="false"
          :disabled-date="setDisabledEndDate"
          format="YYYY-MM-DD"
          input-class="form-control"
          lang="de"
          required
          v-model="selectedEndDate"
          valueType="format"
        />
        <input
          v-else
          type="date"
          class="form-control"
          id="select_enddate"
          :min="selectedStartDate"
          :max="maxDate"
          v-model="selectedEndDate"
          required
        />
      </div>

      <div class="form-group" v-if="hasFilter('timeinterval')">
        <label for="select_timeinterval"
          >{{ $t('filter.labels.timeinterval') }}:</label
        >
        <div class="custom-select-wrapper">
          <select
            class="custom-select"
            id="select_timeinterval"
            v-model="timeinterval"
          >
            <option v-for="value in timeintervals" :value="value" :key="value">
              {{ $t('filter.timeintervals.' + value) }}
            </option>
          </select>
        </div>
      </div>

      <br />

      <div class="form-group" v-if="hasFilter('specialization')">
        <MultiChoiceFilter v-if="isCubeStat" name="cubeSpec" />
        <MultiChoiceFilter v-else name="specialization" />
      </div>

      <div class="form-group" v-if="hasFilter('region')">
        <MultiChoiceFilter name="region" />
      </div>

      <div class="form-group" v-if="hasFilter('agegroup')">
        <label for="select_agegroup">{{ $t('filter.labels.agegroup') }}:</label>
        <div class="custom-select-wrapper">
          <select class="custom-select" id="select_agegroup" v-model="agegroup">
            <option :value="null">{{ $t('filter.labels.all') }}</option>
            <option v-for="value in agegroups" :value="value" :key="value">
              {{ $t('filter.agegroups.' + value) }}
            </option>
          </select>
        </div>
      </div>

      <div class="form-group" v-if="hasFilter('gender')">
        <label for="select_gender">{{ $t('filter.labels.gender') }}:</label>
        <div class="custom-select-wrapper">
          <select class="custom-select" id="select_gender" v-model="gender">
            <option :value="null">{{ $t('filter.labels.all') }}</option>
            <option v-for="value in genders" :value="value" :key="value">
              {{ $t('filter.genders.' + value) }}
            </option>
          </select>
        </div>
      </div>

      <div class="form-group" v-if="hasFilter('salesregulation')">
        <label for="selectSalesRegulation"
          >{{ $t('filter.labels.salesRegulation') }}:</label
        >
        <div class="custom-select-wrapper">
          <select
            class="custom-select"
            id="selectSalesRegulation"
            v-model="salesRegulation"
          >
            <option :value="null">{{ $t('filter.labels.all') }}</option>
            <option
              v-for="value in $store.getters.salesRegulations"
              :value="value"
              :key="value"
            >
              {{ $t('filter.salesRegulations.' + value) }}
            </option>
          </select>
        </div>
      </div>

      <div class="form-group" v-if="hasFilter('insurancetype')">
        <label for="selectInsuranceType"
          >{{ $t('filter.labels.insurancetype') }}:</label
        >
        <div class="custom-select-wrapper">
          <select
            class="custom-select"
            id="selectInsuranceType"
            v-model="insurancetype"
          >
            <option :value="null">{{ $t('filter.labels.all') }}</option>
            <option
              v-for="value in getInsuranceTypes"
              :value="value"
              :key="value"
            >
              {{ $t('filter.insurancetype.' + value) }}
            </option>
          </select>
        </div>
      </div>

      <div class="text-left">
        <span class="btn btn-outline-light btn-sm" @click="resetFilters">{{
          $t('filter.labels.reset')
        }}</span>
      </div>
    </template>

    <template v-slot:main v-if="item">
      <div class="row">
        <div class="col-12 col-xl-9">
          <a ref="exportLink"></a>
          <div class="export-container" v-if="showExport">
            <div ref="exportFilter" class="export-filter">
              <img
                src="../../assets/images/ixsightnow-logo.png"
                alt="ixsight"
                width="250"
              />
              <!--<div class="claim">ixsight now</div>-->

              <h3>Filter:</h3>

              <div
                class="form-group"
                v-if="hasFilter('pzn') && typeof item.pzn !== 'undefined'"
              >
                <div class="label">{{ $t('filter.labels.pzn') }}:</div>
                <div class="value">{{ pznSearchTitle }}</div>
              </div>

              <div class="form-group" v-if="hasFilter('reimport')">
                <div class="label">{{ $t('filter.labels.reimport') }}:</div>
                <div class="value">
                  {{
                    reimport
                      ? $t('filter.reimport.' + reimport)
                      : $t('filter.labels.all')
                  }}
                </div>
              </div>

              <div class="form-group" v-if="hasFilter('who') && who">
                <div class="value">{{ $t('filter.labels.who') }}: aktiv</div>
              </div>

              <div class="form-group" v-if="hasFilter('icd5')">
                <div class="label">{{ $t('filter.labels.icd5') }}:</div>
                <div class="value multi-val">{{ selectedIcds.join(' ') }}</div>
              </div>

              <div
                class="form-group"
                v-if="hasFilter('extrapolate') && extrapolate"
              >
                <div class="value">
                  {{ $t('filter.labels.extrapolate') }} aktiv
                </div>
              </div>

              <div class="form-group" v-if="hasFilter('date1')">
                <div class="label">{{ $t('filter.labels.startdate') }}:</div>
                <div class="value">{{ formattedStartDate }}</div>
              </div>

              <div class="form-group" v-if="hasFilter('date2')">
                <div class="label">{{ $t('filter.labels.enddate') }}:</div>
                <div class="value">{{ formattedEndDate }}</div>
              </div>

              <div class="form-group" v-if="hasFilter('timeinterval')">
                <div class="label">{{ $t('filter.labels.timeinterval') }}:</div>
                <div class="value">
                  {{ $t('filter.timeintervals.' + timeinterval) }}
                </div>
              </div>

              <div class="form-group" v-if="hasFilter('specialization')">
                <div class="label">
                  {{ $t('filter.labels.specialization') }}:
                </div>
                <div class="value multi-val">
                  {{
                    selectedSpecializations.map((item) => item.desc).join(', ')
                  }}
                </div>
              </div>

              <div class="form-group" v-if="hasFilter('region')">
                <div class="label">{{ $t('filter.labels.region') }}:</div>
                <div class="value multi-val">
                  {{ selectedRegions.map((item) => item.desc).join(', ') }}
                </div>
              </div>

              <div class="form-group" v-if="hasFilter('agegroup')">
                <div class="label">{{ $t('filter.labels.agegroup') }}:</div>
                <div class="value">
                  {{
                    agegroup
                      ? $t('filter.agegroups.' + agegroup)
                      : $t('filter.labels.all')
                  }}
                </div>
              </div>

              <div class="form-group" v-if="hasFilter('gender')">
                <div class="label">{{ $t('filter.labels.gender') }}:</div>
                <div class="value">
                  {{
                    gender
                      ? $t('filter.genders.' + gender)
                      : $t('filter.labels.all')
                  }}
                </div>
              </div>

              <div class="form-group" v-if="hasFilter('salesregulation')">
                <div class="label">
                  {{ $t('filter.labels.salesRegulation') }}:
                </div>
                <div class="value">
                  {{
                    salesRegulation
                      ? $t('filter.salesRegulations.' + salesRegulation)
                      : $t('filter.labels.all')
                  }}
                </div>
              </div>
            </div>
            <canvas ref="exportCanvas"></canvas>
            <div ref="exportTable">
              <div class="export-table">
                <div class="box">
                  <table class="table table-sm table-striped">
                    <thead>
                      <tr>
                        <th
                          v-for="(cell, i) in tableData.head"
                          :class="!i ? 'title' : 'value'"
                          :key="i"
                        >
                          {{ cell }}
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr v-for="(row, j) in tableData.body" :key="j">
                        <td
                          :class="!i ? 'title' : 'value'"
                          v-for="(cell, i) in row"
                          :key="i"
                        >
                          <span v-if="!i">{{ cell }}</span>
                          <span v-else>{{ format_number(cell) }}</span>
                        </td>
                      </tr>
                    </tbody>
                    <tfoot>
                      <tr>
                        <td
                          :class="!i ? 'title' : 'value'"
                          v-for="(cell, i) in tableData.foot"
                          :key="i"
                        >
                          <span v-if="!i">{{ cell }}</span>
                          <span v-else>{{ format_number(cell) }}</span>
                        </td>
                      </tr>
                    </tfoot>
                  </table>
                </div>
              </div>
            </div>
          </div>

          <div class="loading flex-center" v-if="isLoading">
            <i class="fa fa-spinner fa-pulse fa-4x fa-fw"></i>
          </div>

          <div v-show="isEmpty" class="card">
            <div class="text-center">
              <i class="fa fa-search fa-3x mb-3"></i>
              <p class="lead">
                {{ $t('statistics.no_data', { term: item.desc }) }}
              </p>
              <router-link
                :to="{ name: 'search' }"
                class="btn btn-outline-secondary"
                >{{ $t('statistics.back_to_search') }}</router-link
              >
            </div>
          </div>

          <div class="card" v-show="!isEmpty && !isLoading && chart">
            <div class="flex-row align-items-start">
              <div class="mr-5">
                <h2 class="mb-2">{{ title }}</h2>
                <p class="text-gray">{{ subtitle }}</p>
              </div>

              <div class="ml-auto">
                <div class="btn-group">
                  <span
                    class="btn btn-outline-light btn-sm"
                    :class="{ active: showTable }"
                    @click="showTable = true"
                    ><i class="fa fa-table"></i
                  ></span>
                  <span
                    class="btn btn-outline-light btn-sm"
                    :class="{ active: !showTable }"
                    @click="showTable = false"
                    ><i class="fa fa-bar-chart-o"></i
                  ></span>
                </div>

                <br />
                <FavoriteBtn
                  :filters-str="JSON.stringify(filter)"
                  :title="title"
                />
              </div>
            </div>

            <div v-if="showTable" class="data-table">
              <div class="table-responsive">
                <table class="table table-sm table-striped">
                  <thead>
                    <tr>
                      <th
                        v-for="(cell, i) in tableData.head"
                        :class="[
                          i === 0 || statId === SI.BILLING_BY_ICD
                            ? 'title'
                            : 'value',
                          statId === SI.BILLING_BY_ICD ? 'vert-top' : ''
                        ]"
                        :key="i"
                      >
                        {{ cell || '&nbsp;' }}
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr v-for="(row, j) in tableData.body" :key="j">
                      <td
                        :class="!i ? 'title' : 'value'"
                        v-for="(cell, i) in row"
                        :key="i"
                      >
                        <span v-if="!i">{{ cell }}</span>
                        <span v-else>{{ format_number(cell) }}</span>
                      </td>
                    </tr>
                  </tbody>
                  <tfoot>
                    <tr>
                      <td
                        :class="!i ? 'title' : 'value'"
                        v-for="(cell, i) in tableData.foot"
                        :key="i"
                      >
                        <span v-if="!i">{{ cell }}</span>
                        <span v-else>{{ format_number(cell) }}</span>
                      </td>
                    </tr>
                  </tfoot>
                </table>
              </div>
            </div>

            <div v-show="!showTable" class="highchart-container">
              <div ref="placeholder"></div>
              <div class="flex-row mt-2">
                <div class="btn-group ml-auto" v-if="hasFilter('format')">
                  <span
                    class="btn btn-outline-light btn-sm"
                    :class="{ active: format === 'absolute' }"
                    @click="format = 'absolute'"
                    >{{ $t('filter.formats.absolute') }}</span
                  >
                  <span
                    class="btn btn-outline-light btn-sm"
                    :class="{ active: format === 'percent' }"
                    @click="format = 'percent'"
                    >{{ $t('filter.formats.percent') }}</span
                  >
                </div>
              </div>
            </div>

            <div class="flex-row mt-2">
              <div class="btn-group">
                <template v-if="!showTable">
                  <span
                    class="btn btn-sm btn-outline-light"
                    @click="exportCustom('pdf')"
                    ><i class="fa fa-file-pdf-o mr-1"></i> PDF</span
                  >
                  <span
                    class="btn btn-sm btn-outline-light"
                    @click="exportCustom('png')"
                    ><i class="fa fa-file-image-o mr-1"></i> PNG</span
                  >
                  <span
                    class="btn btn-sm btn-outline-light"
                    @click="exportCustom('jpg')"
                    ><i class="fa fa-file-image-o mr-1"></i> JPG</span
                  >
                </template>
                <span class="btn btn-sm btn-outline-light" @click="exportXls"
                  ><i class="fa fa-file-excel-o mr-1"></i> XLSX</span
                >
                <span class="btn btn-sm btn-outline-light" @click="exportCsv"
                  ><i class="fa fa-file-code-o mr-1"></i> CSV</span
                >
              </div>
            </div>
          </div>
        </div>

        <!-- Right Side Section (Quelle, Kontext, ...) -->
        <div class="col-12 col-xl-3">
          <div class="card mt-4 mt-xl-0" v-if="meta">
            <template v-if="meta.source">
              <h5>{{ $t('statistics.source') }}</h5>
              <p
                class="text-dark"
                v-html="replaceItem(item, parseMetaLink(meta.source))"
              ></p>
            </template>
            <template v-if="infotext">
              <h5>{{ $t('statistics.infotext') }}</h5>
              <p class="text-dark" v-html="replaceItem(item, infotext)"></p>
            </template>
            <template v-if="meta.context">
              <h5>{{ $t('statistics.context') }}</h5>
              <p class="text-dark" v-html="replaceItem(item, meta.context)"></p>
            </template>
          </div>
          <SpecInfo v-if="hasFilter('specialization')" :is-cube="isCubeStat" />
        </div>
      </div>
    </template>
  </LayoutDetail>
</template>

<style scoped lang="scss">
@import './statisticDetail';
</style>
