
<script>

export default {

  name: 'LayoutOverview'
}

</script>

<template>

  <div class="layout-overview">
    <div class="container-fluid">
      <div class="row">
        <div class="col-12">
          <div class="top-col">
            <slot name="top"></slot>
          </div>
        </div>
        <div class="col-12 col-xl-8">
          <div class="main-col">
            <slot name="main"></slot>
          </div>
        </div>
        <div class="col-12 col-xl-4">
          <div class="side-col">
            <slot name="side"></slot>
          </div>
        </div>
      </div>
    </div>
  </div>

</template>
