
<script>

import Page from './Page'
import ContactForm from '../components/ContactForm'

export default {

  name: 'Contact',
  components: { ContactForm },
  extends: Page
}

</script>

<template>

  <div class="page">

    <div class="screen bg-gradient form-light flex-center text-left">
      <div class="container my-4">
        <div class="row">
          <div class="col-12 col-lg-5">
            <h2>Nehmen Sie Kontakt mit uns auf - wir freuen uns auf Ihre Nachricht!</h2>
            <p class="lead">intermedix Deutschland GmbH<br>Maria Trost 21<br>56070 Koblenz</p>
            <p class="lead">E-Mail-Adresse: <a href="mailto:ixsight@intermedix.de">ixsight@intermedix.de</a></p>
            <div class="mt-4">
              <a href="https://www.linkedin.com/company/intermedix-deutschland-gmbh" target="_blank"><i class="fa fa-linkedin fa-2x mr-3"></i></a>
              <a href="https://www.xing.com/pages/intermedixdeutschlandgmbh" target="_blank"><i class="fa fa-xing fa-2x"></i></a>
            </div>
          </div>
          <div class="col-12 col-lg-7">
            <ContactForm></ContactForm>
          </div>
        </div>
      </div>
    </div>

  </div>

</template>
