import axios from 'axios'
import router from './router/router'

let baseURL = null

switch (window.location.hostname) {
  case 'ixsight.de':
    baseURL = 'https://api.ixsight.de'
    break

  case 'stage.ixsight.de':
    baseURL = 'https://api-stage.ixsight.de'
    break

  case '127.0.0.1':
    baseURL = 'http://127.0.0.1:8000'
    break

  case 'localhost':
    baseURL = 'http://127.0.0.1:8000'
    break

  case 'test.ixsight.de':
    baseURL = 'https://api-dev.ixsight.de'
    break

  default:
  case 'dev.ixsight.de':
    baseURL = 'https://api-dev.ixsight.de'
    break
}

const http = axios.create({
  baseURL,
  headers: {
    Accept: 'application/json'
  }
})

http.interceptors.request.use((request) => {
  const accessToken = window.localStorage.getItem('accessToken')

  if (accessToken) {
    /*
    console.log('request.url', request.url)

    if (!request.url.startsWith('/user/auth')) {

      let token = parseJwt(accessToken)
      let now = Math.round(new Date().valueOf() / 1000)

      console.log(token.exp - now)

      // if (token.exp - now < 1300) {
      if (true || now > token.exp) {

        return refreshToken(request)
      }
    }
    */

    request.headers.Authorization = 'Bearer ' + accessToken
  }

  return request
})

http.interceptors.response.use(
  (response) => response,
  (request) => {
    if (request.response) {
      if (request.response.status === 401) {
        if (
          typeof request.response.data !== 'undefined' &&
          typeof request.response.data.reasons !== 'undefined' &&
          typeof request.response.data.reasons[0] !== 'undefined'
        ) {
          if (request.response.data.reasons[0] === 'Signature has expired.') {
            return refreshToken(request.config)
          }
        }
      }

      if (request.response.status === 404) {
        router.replace('/not-found')
      }
    }

    throw request
  }
)

async function refreshToken(request) {
  const refreshingToken = window.localStorage.getItem('refreshToken')

  if (refreshingToken) {
    return Api.auth
      .refresh({ refresh_token: refreshingToken })
      .then((response) => {
        window.localStorage.setItem('accessToken', response.data.access_token)

        if (response.data.refresh_token) {
          window.localStorage.setItem(
            'refreshToken',
            response.data.refresh_token
          )
        }

        request.headers.Authorization = 'Bearer ' + response.data.access_token

        return http(request)
      })
      .catch((error) => {
        console.error(error)
        window.localStorage.removeItem('accessToken')
        window.localStorage.removeItem('refreshToken')
        // alert('Refresh failed')
        window.location.reload(false)
      })
  }
}

const Api = {
  auth: {
    login: (data) => {
      return http.post('/user/auth', data)
    },

    refresh: (data) => {
      return http.post('/user/auth/refresh', data)
    },

    forgotPassword: (data) => {
      return http.post('/user/forgot_password', data)
    },

    changeForgottenPassword: (data) => {
      return http.put('/user/change_forgotten_password', data)
    }
  },

  user: {
    me: (data) => {
      return http.get('/user/auth/me')
    },

    index: () => {
      return http.get('/user')
    },

    store: (data) => {
      return http.post('/user', data)
    },

    show: (id) => {
      return http.get('/user/' + id)
    },

    update: (id, data) => {
      return http.put('/user/' + id, data)
    },

    destroy: (id) => {
      return http.delete('/user/' + id)
    },

    changePassword: (data) => {
      return http.put('/user/change_password', data)
    },

    favorite: {
      add: (favorite) => http.post('/user/favorite', { favorite }),
      edit: (favId, favTitle) =>
        http.put('/user/favorite/edit/' + favId, { favTitle }),
      delete: (favId) => http.delete('/user/favorite/' + favId),
      getAll: () => {
        try {
          return http.get('/user/favorite/all')
        } catch (error) {
          console.error(error)
        }
      }
    }
  },

  list: {
    all: () => {
      return http.get('/data/list/all')
    }
  },

  search: {
    suggest: (data) => {
      return http.post('/search/suggest', data)
    }
  },

  util: {
    contact: (data) => {
      return http.post('/util/contact', data)
    },

    banner: () => {
      return http.get('/util/banner')
    }
  },

  stats: {
    chart: (id, params) => {
      return http.get('/stat/' + id, { params })
    },

    meta: (id, params) => {
      return http.get('/stat/' + id + '/meta', { params })
    },

    landingpage: (entity, code) => {
      return http.get('/stat/landingpage/' + entity + '/' + code)
    },

    pznInfo: (pzn) => {
      return http.get('/stat/info/pzn/' + pzn)
    }
  },

  benchmarks: {
    chart: (entity, data) => {
      return http.post('/bench/' + entity, data)
    },

    search: (entity, data) => {
      let url = null

      if (entity === 'diagnosis') {
        url = '/bench/suggest/icd'
      } else {
        url = 'bench/suggest/product'
      }

      return http.post(url, data)
    },

    tree: (entity, data) => {
      const slug = entity === 'diagnosis' ? 'icd' : 'product'

      return http.post('bench/bench_overview/' + slug, data)
    }
  }
}

export default Api
