
<script>
import Master from '../pages/Page'
import Banner from './Banner'
import logo from '../assets/images/ixsightnow-logo.png'
import iconMagnifier from '../assets/images/icon-magnifier2.png'

export default {

  name: 'Header',
  extends: Master,
  components: { Banner },

  data() {
    return {
      navOpen: false
    }
  },

  watch: {

    $route() {
      this.navOpen = false
    }
  },

  computed: {
    logo() {
      return logo
    },

    searchIcon() {
      return iconMagnifier
    },

    showBanner() {
      return this.$store.state.banner.show
    },

    user() {
      return this.$store.state.user.instance
    }
  },

  methods: {

    showAuth() {
      this.$store.commit('showAuth', 'login')
    },

    showSignup() {
      this.$store.commit('showAuth', 'signup')
    },

    logout() {
      this.$store.dispatch('user/logout')
      this.$router.push({ name: 'home' })
        .catch(() => {})
    }

  }
}

</script>

<template>

  <header>
    <Banner v-if="showBanner" />

    <nav class="navbar navbar-expand-lg align-items-end">

      <router-link :to="{name:'home'}" class="logo">
        <img :src="logo" alt="ixsight now logo">
      </router-link>

      <div class="nav-item d-lg-none ml-auto">
        <router-link :to="{name:'search'}" class="nav-link nav-link-search">
          <img :src="searchIcon" alt="magnifier icon">
        </router-link>
      </div>

      <button class="navbar-toggler" @click="navOpen=!navOpen">
        <span class="fa fa-bars"></span>
      </button>

      <div class="navbar-collapse" :class="{show:navOpen}">

        <ul class="navbar-nav main-nav mr-auto" v-if="user.check">
          <li class="nav-item">
            <router-link :to="{name:'search'}" class="nav-link">{{ $t('nav.statistics') }}</router-link>
          </li>
          <li class="nav-item">
            <router-link :to="{name:'pzn-info'}" class="nav-link">{{ $t('nav.pzn_info') }}</router-link>
          </li>
          <li class="nav-item">
            <router-link :to="{name:'benchmarks.index'}" class="nav-link">{{ $t('nav.benchmarks') }}</router-link>
          </li>
          <li class="nav-item">
            <router-link :to="{name:'studies'}" class="nav-link">{{ $t('nav.studies') }}</router-link>
          </li>
        </ul>

        <ul class="navbar-nav ml-auto">
          <li class="nav-item d-none d-lg-block">
            <router-link :to="{name:'search'}" class="nav-link nav-link-search">
              <img :src="searchIcon" alt="magnifier icon">
            </router-link>
          </li>
          <li class="nav-item" v-if="!user.check">
            <router-link :to="{name:'contact'}" class="nav-link">{{ $t('nav.contact') }}</router-link>
          </li>
          <li class="nav-item acc-btn dropdown d-none d-lg-block" v-if="user.check">
            <Dropdown
              :dropdown-id="'accountDD'"
              :title="$t('nav.account')"
              icon="fa fa-user-o"
              look="nav-item"
              :right="true"
            >
              <router-link :to="{name:'account'}" class="dropdown-item">{{ $t('account.edit') }}</router-link>
              <router-link to="/account/favorites" class="dropdown-item">{{ $t('account.favorites') }}</router-link>
              <span @click="logout" class="dropdown-item">{{ $t('auth.logout') }}</span>
              <div class="dropdown-divider" v-if="user.isAdmin || user.isIxAdmin"></div>
              <router-link v-if="user.isAdmin || user.isIxAdmin" :to="{name:'users'}" class="dropdown-item">{{ $t('users.manage') }}</router-link>
            </Dropdown>
          </li>

          <li class="nav-item d-lg-none" v-if="user.check">
            <router-link :to="{name:'account'}" class="nav-link">Account {{ $t('account.edit') }}</router-link>
          </li>
          <li class="nav-item d-lg-none" v-if="user.check">
            <span @click="logout" class="nav-link">{{ $t('auth.logout') }}</span>
          </li>
          <li class="nav-item d-lg-none">
            <router-link v-if="user.isAdmin || user.isIxAdmin" :to="{name:'users'}" class="nav-link">{{ $t('users.manage') }}</router-link>
          </li>

          <li class="nav-item" v-if="!user.check" style="position:relative; top:4px;">
            <span class="btn btn-secondary" @click="showAuth">{{ $t('auth.login') }}</span>
          </li>
        </ul>

      </div>
    </nav>

  </header>

</template>

<!--<style scoped lang="scss"></style>-->
