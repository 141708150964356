
<script>
import Master from '../pages/Page'
import FloatingBtns from './FloatingBtns'

export default {

  name: 'Footer',
  extends: Master,

  components: { FloatingBtns },

  data() {
    return {
    }
  },

  computed: {
    user() {
      return this.$store.state.user.instance
    },

    pdfPath() {
      if (!this.user.check) {
        return '/files/ixsight-now_Anleitung-und-Methodik_public.pdf'
      }
      return '/files/ixsight-now_Anleitung-und-Methodik_full.pdf'
    }
  }
}

</script>

<template>

  <footer class="bg-gradient">
    <div class="container">
      <div class="row">
        <div class="col-12 d-flex flex-column flex-md-row justify-content-center align-items-center">
          <a href="https://intermedix.de" target="_blank">Zur intermedix Website</a>
          <a :href=pdfPath  target="_blank">{{ $t('nav.method') }}</a>
          <router-link :to="{name:'terms'}">{{ $t('nav.terms') }}</router-link>
          <router-link :to="{name:'privacy'}">{{ $t('nav.privacy') }}</router-link>
          <router-link :to="{name:'contact'}">{{ $t('nav.contact') }}</router-link>
          <router-link :to="{name:'imprint'}">{{ $t('nav.imprint') }}</router-link>
          <router-link :to="{name:'contact'}">{{ $t('nav.report') }}</router-link>
        </div>
      </div>
    </div>

    <FloatingBtns />
  </footer>

</template>
