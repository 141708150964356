/*
 * OLD
 * USER in main STORE:
 * GETTERS: user (CHECKED),
 * MUTATIONS: user (CHECKED), ADD_FAVORITE (CHECKED), REMOVE_FAVORITE (CH)
 * ACTIONS: user (CH), updateUser (CH), deleteUser, login, logout
 * */

import User from '../models/User'
import Api from '../Api'
import { shouldShowBanner } from '../utils'

export default {
  state: () => ({
    instance: new User()
  }),

  getters: {
    id: (state) => state.instance.id,
    check: (state) => state.instance.check
  },

  mutations: {
    // user
    INIT: (state, value) => {
      state.instance = value
    },

    ADD_FAVORITE: (state, favorite) => {
      state.instance.favorites = [favorite, ...state.instance.favorites]
    },

    EDIT_FAVORITE: (state, { id, newTitle }) => {
      const favIndex = state.instance.favorites.findIndex(
        (fav) => fav.id === id
      )
      state.instance.favorites[favIndex].title = newTitle
    },

    DELETE_FAVORITE: (state, id) => {
      state.instance.favorites = state.instance.favorites.filter(
        (fav) => fav.id !== id
      )
    }
  },

  actions: {
    init({ commit }, token) {
      return new Promise((resolve, reject) => {
        window.localStorage.setItem('accessToken', token)

        Api.user
          .me()
          .then((response) => {
            if (!response.data) {
              return reject(
                new Error('Key "data" not available on response object.')
              )
            }

            const id = response.data.me.id

            Api.user.show(id).then((response) => {
              commit('INIT', new User(response.data))
              resolve()
            })
          })
          .catch((err) => {
            window.localStorage.removeItem('accessToken')
            window.localStorage.removeItem('refreshToken')
            reject(err)
          })
      })
    },

    delete({ getters, commit }) {
      return new Promise((resolve, reject) => {
        Api.user
          .destroy(getters.id)
          .then((response) => {
            window.localStorage.removeItem('accessToken')
            window.localStorage.removeItem('refreshToken')
            commit('INIT', new User())
            resolve()
          })
          .catch(reject)
      })
    },

    login({ commit, dispatch }, data) {
      return new Promise((resolve, reject) => {
        // console.log('data', data)
        Api.auth
          .login(data)
          .then((response) => {
            if (response && response.data) {
              window.localStorage.setItem(
                'accessToken',
                response.data.access_token
              )
              window.localStorage.setItem(
                'refreshToken',
                response.data.refresh_token
              )

              dispatch('init', response.data.access_token)
                .then(resolve)
                .catch(reject)
              commit(
                'TOGGLE_BANNER',
                { show: shouldShowBanner() },
                { root: true }
              )
            } else {
              reject(new Error('Login response is missing data.'))
            }
          })
          .catch((error) => {
            if (error.response && error.response.data) {
              reject(error.response.data.error) // 'login_incorrect'
            } else {
              reject(new Error('An unexpected error occurred.'))
            }
          })
      })
    },

    logout({ commit }) {
      window.localStorage.removeItem('accessToken')
      window.localStorage.removeItem('refreshToken')
      commit('TOGGLE_BANNER', { show: false }, { root: true })
      commit('INIT', new User())
    },

    update({ getters, commit }, data) {
      return new Promise((resolve, reject) => {
        Api.user
          .update(getters.id, data)
          .then((response) => {
            commit('INIT', new User(response.data))
            resolve()
          })
          .catch(reject)
      })
    }
  }
}
