<template>
  <div>
    <label>{{ $t('filter.labels.' + name) }}:</label>
    <Dropdown
      :dropdown-id="name"
      :title="getMultiChoiceDropdownLabel(name, selectedItems, items)"
      :prevent-close="true"
      look="form-control select-dropdown multi-choice-dd"
    >
      <div class="dd-header">
        <div class="d-flex flex-row justify-content-around">
          <span class="lightblue text-small text-link" @click="selectAll">{{ $t('filter.labels.select_all') }}</span>
          <span class="lightblue text-small text-link" @click="selectNone">{{ $t('filter.labels.select_none') }}</span>
        </div>

        <div v-if="hasSearch" class="form-white mt-2">
<!--          <input type="text" class="form-control form-control-sm" v-model="icdSearch" placeholder="Suche...">-->
        </div>

      </div>

      <div class="list">
        <div class="custom-control custom-checkbox" v-for="({ value, desc }, i) in items" :key="i">
          <input
            type="checkbox"
            class="custom-control-input"
            :id="'check_' + value"
            :value="value"
            :checked="selectedItems.map(item => item.value).includes(value)"
            @change="handleChange"
          >
          <label
            class="custom-control-label"
            :for="'check_' + value"
          >
            {{ desc }}
          </label>
        </div>
      </div>
    </Dropdown>
  </div>
</template>

<script>
import Dropdown from '../Dropdown'
// import { mapState } from 'vuex'

export default {
  name: 'MultiChoiceFilter',

  props: {
    name: { type: String, required: true },
    hasSearch: { type: Boolean, default: false }
  },

  components: { Dropdown },

  computed: {
    capName() {
      return this.name.charAt(0).toUpperCase() + this.name.slice(1)
    },

    selectedItems() {
      return this.$store.state.filter['selected' + this.capName + 's']
    },

    items() {
      return this.$store.state.filter[this.name + 's']
    }
  },

  methods: {
    getMultiChoiceDropdownLabel(type, selectedItems, allItems) {
      if (!selectedItems.length) {
        return this.$t('filter.labels.none')
      }

      if (selectedItems.length === allItems.length) {
        return this.$t('filter.labels.all') + ' (' + allItems.length + ')'
      }

      if (selectedItems.length === 1) {
        return selectedItems[0].desc
      }

      return this.$t('filter.labels.some') + ' (' + selectedItems.length + ')'
    },

    selectAll() {
      this.$store.commit('filter/selectAll', this.name)
    },

    selectNone() {
      this.$store.commit('filter/selectNone', this.name)
    },

    handleChange(event) {
      this.$store.commit('filter/toggleOne', {
        name: this.name,
        val: event.target.value
      })
    }
  }
}
</script>

<style scoped lang="scss">
#region {
  .list {
    height: 110px;
    overflow: scroll;

    .custom-checkbox {
      margin: 0.4rem 0;
    }
  }
}
</style>
