<script>
import Page from './Page'

export default {
  name: 'Imprint',
  extends: Page
}
</script>

<template>
  <div class="page">
    <div class="screen bg-gradient form-light flex-center text-left">
      <div class="container">
        <div class="row">
          <div class="col-12">
            <h1>Impressum</h1>

            <p class="lead"><strong>Sitz der Gesellschaft:</strong></p>
            <p class="lead">
              intermedix Deutschland GmbH<br />Maria Trost 21<br />56070 Koblenz
            </p>

            <p class="lead">
              Geschäftsführer: Markus Huppertz, Dr. Ulrich Thomé<br />
              Sitz und Amtsgericht der Gesellschaft Koblenz<br />
              Handelsregisternummer: HRB 6710<br />
              Steuernummer: UST ID DE150093774<br />
            </p>

            <p class="lead"><strong>Postadresse / Standort:</strong></p>

            <p class="lead">
              intermedix Deutschland GmbH<br />
              Bunsenstraße 7<br />
              82152 Martinsried / München<br />
              Fon +49 89 89 74 45 00<br />
              Fax +49 89 89 74 45 01<br />
              E-Mail: <a href="mailto:info@intermedix.de">info@intermedix.de</a
              ><br />
              Internet:
              <a href="https://www.intermedix.de" target="_blank"
                >www.intermedix.de</a
              ><br />
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
