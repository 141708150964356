<script>
import { mapGetters } from 'vuex'
import Alert from '../components/Alert'
import Api from '../Api'
import ContactForm from './ContactForm'

export default {
  name: 'Auth',
  components: { ContactForm, Alert },

  data() {
    return {
      mode: 'login',
      alert: null,
      loading: false,
      showPassword: false,
      login: {
        username: null,
        password: null
      },
      forgot: {
        email: null
      }
    }
  },

  watch: {
    open(value) {
      if (value === true) {
        value = 'login'
      }

      if (value) {
        this.mode = value
      }

      if (this.mode === 'login') {
        this.$nextTick(() => {
          if (this.$refs.inputEmail) {
            this.$refs.inputEmail.focus()
          }
        })
      }
    }
  },

  computed: {
    ...mapGetters(['nextRoute']),

    open: {
      get() {
        return this.$store.getters.showAuth
      },

      set(value) {
        return this.$store.commit('showAuth', value)
      }
    }
  },

  methods: {
    close() {
      this.open = false
      this.mode = 'login'
      this.alert = null
      this.loading = false
    },

    submitLogin() {
      this.alert = null
      this.loading = true

      this.$store
        .dispatch('user/login', this.login)
        .then(() => {
          this.open = false

          if (this.nextRoute) {
            this.$router.push({
              name: this.nextRoute.name,
              params: this.nextRoute.params
            })
            this.$store.commit('nextRoute', null)
          }
        })
        .catch((error) => {
          this.alert = { type: 'danger', message: 'auth.' + error }
          this.loading = false
        })
        .finally(() => {
          this.loading = false
        })
    },

    submitForgot() {
      this.alert = null

      Api.auth.forgotPassword(this.forgot).then((response) => {
        this.alert = {
          type: 'success',
          message: 'auth.forgot_password_success'
        }
      })
    },

    changeMode(mode) {
      this.mode = mode
    }
  }
}
</script>

<template>
  <transition name="fade" appear>
    <div class="modal" v-if="open" @mousedown.self="close">
      <div class="box box-auth bg-gradient form-light">
        <div class="close" @click="close"><i class="fa fa-times"></i></div>

        <Alert :alert="alert"></Alert>

        <div class="box-scroll">
          <transition name="fade" mode="out-in">
            <div class="loading flex-center" v-if="loading">
              <i class="fa fa-spinner fa-pulse fa-4x fa-fw"></i>
            </div>

            <form
              v-else-if="mode === 'login'"
              @submit.prevent="submitLogin"
              autocomplete="off"
            >
              <div class="form-group">
                <label for="email">E-Mail</label>
                <input
                  autocomplete="email"
                  type="email"
                  class="form-control form-control-lg"
                  id="email"
                  placeholder="E-Mail"
                  ref="inputEmail"
                  v-model="login.username"
                  required
                />
              </div>
              <div class="form-group position-relative">
                <label for="password">Passwort</label>
                <input
                  autocomplete="password"
                  :type="showPassword ? 'text' : 'password'"
                  class="form-control form-control-lg"
                  id="password"
                  placeholder="Passwort"
                  v-model="login.password"
                  required
                />
                <span
                  class="toggle-pwd"
                  :title="
                    showPassword ? 'Passwort verbergen' : 'Passwort anzeigen'
                  "
                >
                  <i
                    class="fa"
                    :class="showPassword ? 'fa-eye-slash' : 'fa-eye'"
                    @click="showPassword = !showPassword"
                  ></i>
                </span>
                <div class="flex-row justify-content-end mt-1 mr-1">
                  <span class="text-small text-link" @click="mode = 'forgot'"
                    >Passwort vergessen?</span
                  >
                </div>
              </div>
              <button type="submit" class="btn btn-secondary btn-lg btn-block">
                {{ $t('auth.login') }}
              </button>
              <div class="lead text-center mt-4">
                Noch kein Kunde?
                <strong class="text-link" @click="mode = 'signup'"
                  >Jetzt registrieren</strong
                >
              </div>
            </form>

            <form v-else-if="mode === 'forgot'" @submit.prevent="submitForgot">
              <div class="form-group">
                <label for="email">E-Mail</label>
                <input
                  autocomplete="username"
                  type="email"
                  class="form-control form-control-lg"
                  id="email"
                  placeholder="E-Mail"
                  v-model="forgot.email"
                  required
                />
                <div class="flex-row justify-content-end mt-1 mr-1">
                  <span class="text-small text-link" @click="mode = 'login'">{{
                    $t('auth.login')
                  }}</span>
                </div>
              </div>
              <button type="submit" class="btn btn-secondary btn-lg btn-block">
                Zurücksetzen
              </button>
            </form>

            <ContactForm
              v-else-if="mode === 'signup'"
              :showLoginBtn="true"
              @changeMode="changeMode"
            ></ContactForm>
          </transition>
        </div>
      </div>
    </div>
  </transition>
</template>
