import Vue from 'vue'
import VueI18n from 'vue-i18n'
Vue.use(VueI18n)

export const i18n = {
  locale: 'de',
  fallbackLocale: 'xx',
  messages: {
    de: {
      lang: {
        de: 'Deutsch',
        en: 'Englisch'
      },
      nav: {
        home: 'Home',
        statistics: 'Statistiken',
        monitor: 'Monitor',
        studies: 'Studien',
        method: 'Methodik',
        pzn_info: 'PZN-Info',
        benchmarks: 'Benchmarks',
        contact: 'Kontakt',
        data: 'Daten',
        privacy: 'Datenschutz',
        terms: 'AGB',
        policy: 'Datenschutzerklärung',
        imprint: 'Impressum',
        report: 'Fehler melden',
        account: 'Mein Account'
      },
      labels: {
        back: 'Zurück',
        save: 'Speichern',
        reset: 'Zurücksetzen',
        submit: 'Abschicken',
        are_you_sure: 'Sind Sie sicher?'
      },
      auth: {
        password: 'Passwort',
        password_new: 'Neues Passwort',
        password_new_confirm: 'Neues Passwort wiederholen',
        password_current: 'Altes Passwort',
        login: 'Anmelden',
        logout: 'Abmelden',
        login_incorrect: 'Ungültige Zugangsdaten',
        forgot_password_success: 'Sie haben Post',
        reset_password: 'Passwort zurücksetzen',
        reset_password_success: 'Passwort zurückgesetzt',
        user_expired:
          'Leider ist Ihr Account abgelaufen. Kontaktieren Sie uns für eine Verlängerung des Zugriffs auf die Plattform. Wir beraten Sie gerne.'
      },
      account: {
        edit: 'Bearbeiten',
        updated: 'Ihr Account wurde gespeichert',
        password_updated: 'Ihr Passwort wurde gespeichert',
        favoriteAdded: 'Diese Statistik wurde zu Ihren Favoriten hinzugefügt.',
        favoriteRemoved: 'Der Favorit wurde entfernt.',
        favoriteRemoveAlert:
          'Wollen Sie die Statistik aus Ihren Favoriten entfernen?',
        favorites: 'Favoriten',
        menu: {
          favorites: 'Favoriten verwalten'
        }
      },
      user: {
        id: 'ID',
        email: 'E-Mail',
        firstname: 'Vorname',
        lastname: 'Nachname',
        company: 'Unternehmen',
        validFrom: 'gültig ab',
        expiration: 'gültig bis',
        role: 'Rolle',
        created: 'Erstellt',
        activated: 'aktiviert',
        newsletter: 'Newsletter abonnieren',
        account_type: 'Firmenaccount Typ',
        user_id: 'Nutzer-ID',
        valid_until: 'Account gültig bis'
      },
      users: {
        manage: 'Benutzer verwalten',
        index: '{count} Benutzer',
        edit: 'Benutzer bearbeiten',
        updated: 'Benutzer gespeichert',
        create: 'Benutzer erstellen',
        created: 'Benutzer erstellt',
        delete: 'Benutzer löschen',
        deleted: 'Benutzer gelöscht'
      },
      roles: {
        client_basic: 'Basis-Account',
        client_company: 'Firmen-Account',
        client_pro: 'Pro-Account',
        docmetric: 'docmetric',
        test: 'Test',
        ixadmin: 'IX-Admin',
        admin: 'Admin'
      },
      contact: {
        firstname: 'Vorname',
        lastname: 'Nachname',
        email: 'E-Mail-Adresse',
        phone: 'Telefon',
        phonePrefix: 'Tel.-Vorwahl',
        phoneNumber: 'Tel.-Nummer',
        body: 'Ihre Nachricht',
        comment: 'ixsight Anfrage Kommentar',
        contactMethod: 'ixsight Anfrage Kanalwunsch',
        success: 'Vielen Dank für Ihre Nachricht.'
      },
      signup: {
        email: 'E-Mail',
        company: 'Unternehmen',
        industry: 'Ihre Branche',
        success: 'Vielen Dank, wir melden uns per E-Mail bei Ihnen.',
        submit: 'Anfordern'
      },
      errors: {
        email_already_exists:
          'Ein Benutzer mit diese E-Mail Adresse existiert bereits.',
        signature_invalid: 'Ungültiger oder abgelaufener E-Mail Link',
        passwords_not_equal: 'Ihre Passwort-Bestätigung stimmt nicht überein.',
        password_length: 'Ihr Passwort muss mindestens 8 Zeichen lang sein',
        password_incorrect: 'Ihr Passwort ist nicht korrekt'
      },
      search: {
        title: 'ixsight now',
        placeholder:
          'Diagnosen, Verschreibungen, Wirkstoffe und mehr finden...',
        all: 'Alles',
        labels: {
          ICD: 'ICD',
          PZN: 'PZN',
          ATC: 'ATC',
          product: 'Produkt'
        }
      },
      statistics: {
        title: 'Statistiken',
        base_information: 'Basisinformationen',
        show_details: 'Zur Statistik',
        no_data: 'Leider konnten keine Inhalte zu {term} gefunden werden.',
        back_to_search: 'Zurück zur Suche',
        back_to_overview: 'Zu den Statistiken',
        filter: 'Filter',
        source: 'Quelle',
        context: 'Kontext',
        infotext: 'Hinweis',
        pzn_tree: 'PZN-Info',
        atc_tree: 'ATC-Info',
        pzn_tree_info:
          'Hier finden Sie warenwirtschaftliche Informationen zu einzelnen PZN',
        dataAvailability: 'Daten verfügbar seit'
      },
      filter: {
        labels: {
          all: 'Alle',
          some: 'Diverse',
          none: 'Keine',
          choose: 'Bitte auswählen',
          germany: 'Deutschland',
          reset: 'Filter zurücksetzen',
          specialization: 'Fachgruppe',
          cubeSpec: 'Fachgruppe',
          region: 'Region',
          agegroup: 'Patientenaltersgruppe',
          gender: 'Patientengeschlecht',
          insurancetype: 'Versicherungsart',
          timeinterval: 'Intervall',
          format: 'Format',
          startdate: 'Start-Datum',
          enddate: 'End-Datum',
          startmonth: 'Start-Monat',
          endmonth: 'End-Monat',
          icd: 'ICD',
          icd5: 'Subkategorien',
          icds: 'ICD-Kategorie',
          atc: 'ATC',
          pzn: 'PZN',
          reimport: 'Arzneimittel-Typ',
          product: 'Produkt',
          type: 'Typ',
          who: 'Diagnose-Wirkstoff-Abgleich',
          extrapolate: 'Hochrechnung',
          select_all: 'Alle auswählen',
          select_none: 'Keine auswählen',
          salesRegulation: 'Regulation',
          prescription_type: 'Verkaufstyp'
        },
        infos: {
          who: 'Nur Diagnosen anzeigen, für die das Produkt auf als geeignet eingestuft wurde',
          extrapolate:
            'Bei deaktivierter Hochrechnung werden absolute Werte des CGM Apothekenpanels gezeigt'
        },
        agegroups: {
          '0-29': 'Unter 30 Jahre',
          '30-59': '30-60 Jahre',
          '60-199': 'Über 60 Jahre'
        },
        genders: {
          f: 'Weiblich',
          m: 'Männlich'
        },
        insurancetype: {
          GKV: 'GKV', // public insurance
          PKV: 'PKV' // private insurance
        },
        salesRegulations: {
          rx: 'RX', // prescription drug
          otc: 'OTC' // over-the-counter drug
        },
        reimport: {
          yes: 'Reimport', // drug can be sold abroad and then imported back to Germany with lower price
          no: 'Original'
        },
        timeintervals: {
          week: 'Woche',
          month: 'Monat',
          quarter: 'Quartal'
        },
        formats: {
          absolute: 'Units',
          percent: '%'
        }
      },
      benchmarks: {
        types: {
          units: 'Einheiten',
          revenue: 'Umsatz'
        },
        entities: {
          diagnosis: 'Diagnoseeintragungen',
          prescription: 'Verordnungen',
          sales: 'Umsätze und Abverkäufe'
        },
        search_titles: {
          diagnosis: 'Diagnoseauswahl',
          prescription: 'Produktauswahl',
          sales: 'Produktauswahl'
        },
        search_subtitles: {
          diagnosis: 'Verordnungen welcher Diagnosen sollen verglichen werden?',
          prescription:
            'Verordnungen welcher Produkte sollen verglichen werden?',
          sales:
            'Verkäufe und Umsätze welcher Produkte sollen verglichen werden?'
        },
        titles: {
          diagnosis: 'ixsight Benchmarks | Diagnosen',
          prescription: 'ixsight Benchmarks | Verordnungen',
          sales: 'ixsight Benchmarks | Verkäufe und Umsätze'
        },
        subtitles: {
          diagnosis: 'Diagnosen, in absolut',
          prescription: 'Verordnungen, in absolut',
          sales: 'Verkäufe und Umsätze, in absolut'
        },
        info: {
          diagnosis:
            'Werden Diagnosen trotz vorheriger Auswahl nicht angezeigt, wurden sie von Ärzten des CGM Ärztepanels im abgebildeten Zeitraum nicht eingetragen.',
          prescription:
            'Werden Produkte trotz vorheriger Auswahl nicht angezeigt, wurden sie von Ärzten des CGM Ärztepanels im abgebildeten Zeitraum nicht verordnet.',
          sales:
            'Werden Produkte trotz vorheriger Auswahl nicht angezeigt, wurden sie von Apotheken des CGM Apothekenpanels im abgebildeten Zeitraum nicht verkauft.'
        },
        search: {
          provider: 'Anbieter',
          product_group: 'Produktgruppe',
          product: 'Produkt',
          pzn: 'PZN',
          atc3: 'ATC 3',
          atc4: 'ATC 4',
          atc5: 'ATC 5',
          atc7: 'ATC 7'
        },
        groups: {
          provider_id: 'Anbieter',
          product_id: 'Produkte',
          combined_product_id: 'Produktgruppen',
          pzn: 'PZN',
          atc3: 'ATC 3',
          atc4: 'ATC 4',
          atc5: 'ATC 5',
          atc7: 'ATC 7'
        },
        labels: {
          anbieter: 'Anbieter',
          produktgruppe: 'Produktgruppe',
          produkt: 'Produkt',
          pzn: 'PZN',
          atc: 'ATC',
          atc_name: 'ATC Name',
          icd: 'ICD',
          icd3: 'ICD3',
          desc: 'Bezeichnung'
        },
        results: 'Keine Ergebnisse | Ein Ergebniss | {count} Ergebnisse',
        entries: 'Keine Einträge | Ein Eintrag | {count} Einträge',
        select_all: 'Alle auswählen',
        select_none: 'Keine auswählen',
        reset_filter: 'Neue Suche',
        remove_all: 'Alle löschen',
        submit_selection: 'Auswahl übernehmen',
        open_search: 'Auswahl',
        group_title: 'Was wollen Sie sehen?',
        no_data:
          'Leider konnten keine Inhalte zu Ihrer Auswahl gefunden werden.'
      }
    },

    en: {
      lang: {
        de: 'German',
        en: 'English'
      }
    }
  }
}

export default new VueI18n(i18n)
