<template>
  <div class="banner">
    <p>{{msg}}</p>
  </div>
</template>

<script>
export default {
  name: 'Banner',

  computed: {
    msg() {
      return this.$store.state.banner.msg || 'An jedem Montag sowie an jedem 1. Tag eines Monats aktualisieren wir die Hochrechnung und Teil der Daten auf ixsight now. In dieser Zeit kann die Plattform mit wenigen Einschränkungen genutzt werden. Es können zwischenzeitlich unvollständige Statistiken ausgegeben werden.'
    }
  }
}
</script>

<style lang="scss" scoped>
@import 'src/assets/sass/variables';

.banner {
  background-color: $pink;
  color: $white;
  display: block;
  font-size: 18px;
  padding: 10px 20px;
  width: 100%;

  p {
    margin-bottom: 0;
  }
}
</style>
