/* eslint-disable */
//

/*
REF: https://cgm01.sharepoint.com/:x:/r/sites/ORGdocmetric/_layouts/15/Doc.aspx?sourcedoc=%7BEAE1620F-C2E9-4BA6-B64C-4F752F1E4A8C%7D&file=ixsight%20health%20data%20Structure%20Bug%20Fixing%20Features.xlsx&action=default&mobileredirect=true&cid=03af7d2b-284c-477c-bfed-b87b4cb88dac

LEGEND:
- OT = Over Time (im Zeitverlauf
- PROD = Product (PZN) | Like Bayer-Aspirin
- SUB = Substance
- CO  = Comorbid [Diag]
- PRES = Prescribed
- DS = Drugstore
- PROV = Provider (like Bayer)
- SPEC = Specialization (Fachgruppen)
- GEN = General
- TEL = Telematik
- DIST = Distribution
*/

export const StatIndex = {
  BILLING_BY_ICD: 1,
  DIAG_BY_ICD_OT: 2,
  DIAG_BY_AGE_AND_SEX: 3,
  CO_DIAG_BY_ICD: 4,
  PRES_PROD_BY_ICD_OT: 5,
  PRES_PROD_BY_ICD: 6,
  PRES_SUB_BY_ICD: 7,
  PRES_SUB_BY_ICD_OT: 8,
  PRES_PROV_BY_ICD: 9,
  PRES_PROV_BY_ICD_OT: 10,

  DS_PROD_SALES_OT: 17,
  DS_PROD_REVENUE_OT: 18,
  PRES_PROD_OT: 21,
  DIAG_BY_PRES_PROD: 22,
  DIAG_BY_PRES_PROD_OT: 23,
  PRES_PROD_BY_SPEC: 24, // Backlog (deactivate)
  PRES_PROD_BY_SPEC_OT: 25, // Backlog (deactivate)
  CO_PRES_PROD_OT: 26,
  PRES_ATC_OT: 27,
  PRES_ATC_BY_PROV: 28,
  PRES_ATC_BY_PROV_OT: 29,
  DIAG_BY_PRES_ATC: 30,
  DIAG_BY_PRES_ATC_OT: 31,
  PRES_ATC_BY_SPEC: 32, // Backlog (deactivate)
  PRES_ATC_BY_SPEC_OT: 33, // Backlog (deactivate)
  CO_PRES_ATC: 34,

  DOC_GEN_PROD: 51, // Allgemeines Informationsverhalten vom Arzt
  CALL_CUBE_1: 55, // Aufrufe von "Fachinformation" zu [Produkt]
  CALL_CUBE_2: 63, // Aufrufe von "medizinischen/wissenschaftlichen Hinweisen" zu [Produkt]
  CALL_CUBE_3: 64, // Aufrufe von "Nutzenbewertung" zu [Produkt]
  CALL_CUBE_4: 65, // Aufrufe von "Äquivalenzrecherche, Preisvergleich" in AMDB zu [Produkt]

  DOC_GEN_ATC: 66, // Allgemeines Informationsverhalten vom Arzt
  CALL_CUBE_5: 67, // Aufrufe von "Äquivalenzrecherche, Preisvergleich" in AMDB zu [ATC]
  CALL_CUBE_6: 68, // Aufrufe von "Fachinformation" zu [ATC]
  CALL_CUBE_7: 69, // Aufrufe von "medizinischen/wissenschaftlichen Hinweisen" zu [ATC]
  CALL_CUBE_8: 70, // Aufrufe von "Nutzenbewertung" zu [ATC]

  BILLING_BY_PRES_PROD: 71,
  DS_ATC_REVENUE_OT: 72,
  DS_ATC_SALES_BY_PROV_OT: 73,

  PROD_PRICE_OT: 74,
  PROD_PRICE_BY_KVREGION: 75,
  PRES_ATC_BY_PROD: 76, // DEACTIVATED
  PRES_ATC_BY_PROD_OT: 77, // DEACTIVATED
  DIST_OF_PRES_PROD_ON_DOCS: 78,
  PRES_ATC_BY_PZN: 79,
  PRES_ATC_BY_PZN_OT: 80
}

// Fachgruppen
export const SpecializationGroup = {
  AUGENHEILKUNDE: '05',
  FRAUENHEILKUNDE: '15',
  HNO: '19',
  GESCHLECHTSKRANKHEITEN_UND_DERMATOLOGIE: '21', // aka
  HAPI: 'hapi',
  ORTHOPAEDIE: '10',
  PNEUMOLOGIE: '30',
  KINDERARZT: '34',
  UROLOGIE: '67'
}

// ------------------------------------------------------

export const aisStats = [
  // 1 bis 10:
  StatIndex.BILLING_BY_ICD,
  StatIndex.DIAG_BY_ICD_OT,
  StatIndex.DIAG_BY_AGE_AND_SEX,
  StatIndex.CO_DIAG_BY_ICD,
  StatIndex.PRES_PROD_BY_ICD_OT,
  StatIndex.PRES_PROD_BY_ICD,
  StatIndex.PRES_SUB_BY_ICD,
  StatIndex.PRES_SUB_BY_ICD_OT,
  StatIndex.PRES_PROV_BY_ICD,
  StatIndex.PRES_PROV_BY_ICD_OT,

  // 21,22,23,26,27,28,29,30,31,34
  StatIndex.PRES_PROD_OT,
  StatIndex.DIAG_BY_PRES_PROD,
  StatIndex.DIAG_BY_PRES_PROD_OT,
  StatIndex.CO_PRES_PROD_OT,
  StatIndex.PRES_ATC_OT,
  StatIndex.PRES_ATC_BY_PROV,
  StatIndex.PRES_ATC_BY_PROV_OT,
  StatIndex.DIAG_BY_PRES_ATC,
  StatIndex.DIAG_BY_PRES_ATC_OT,
  StatIndex.CO_PRES_ATC,

  // 71,76,77,78,79,80
  StatIndex.BILLING_BY_PRES_PROD,
  StatIndex.PRES_ATC_BY_PROD,
  StatIndex.PRES_ATC_BY_PROD_OT,
  StatIndex.DIST_OF_PRES_PROD_ON_DOCS,
  StatIndex.PRES_ATC_BY_PZN,
  StatIndex.PRES_ATC_BY_PZN_OT
]
