import moment from 'moment'
import { i18n } from '../../i18n'

export const addFilterToExport = (filter, specs, regions) => {
  filter = { ...filter }

  if (filter.items) {
    delete filter.items
  }

  if (specs && filter.specialization) {
    filter.specialization = specs.map((item) => item.desc).join(',')
  }

  if (regions && filter.region) {
    filter.region = regions.map((item) => item.desc).join(',')
  }

  const result = {
    head: ['Die ausgewählten Filter:'],
    body: Object.entries(filter)
      .filter((arr) => arr[0] !== 'full')
      .map((arr) => {
        let key = arr[0]
        let val = arr[1] || 'Alle'

        switch (key) {
          case 'date1':
            key = 'startdate'
            val = toGermanDate(val)
            break
          case 'date2':
            key = 'enddate'
            val = toGermanDate(val)
            break
          case 'salesregulation':
            key = 'salesRegulation'
            break
          case 'gender':
            if (val === 'm') val = 'männlich'
            if (val === 'f') val = 'weiblich'
            break
        }

        return [i18n.messages.de.filter.labels[key] || key, val]
      })
  }

  return result
}

export const fillGapOnXAxis = (res, startDate, endDate, interval) => {
  const resXValues = [...res.data.xAxis.categories]
  let xValues = []

  let intervalLength = 0
  let dateFormat = ''
  switch (interval) {
    case 'week':
      dateFormat = 'YYYY-ww'
      intervalLength = 52
      break
    case 'month':
      dateFormat = 'YYYY-MM'
      intervalLength = 12
      break
    case 'quarter':
      dateFormat = 'YYYY-0Q'
      if (resXValues[0].split('-')[1].length === 1) {
        dateFormat = 'YYYY-Q'
      }
      intervalLength = 4
      break
  }

  startDate = moment(startDate).format(dateFormat)
  endDate = moment(endDate).format(dateFormat)

  const skippedAtStart = startDate < resXValues[0]
  const skippedAtEnd = endDate > resXValues[resXValues.length - 1]

  if (skippedAtStart) {
    resXValues.unshift(startDate)
    setYOfNewXValuesToZero(1, 0, res)
  }
  if (skippedAtEnd) {
    resXValues.push(endDate)
    const maxIndex = resXValues.length - 1
    setYOfNewXValuesToZero(1, maxIndex, res)
  }

  resXValues.forEach((x, index) => {
    xValues.push(x)

    const isLastElement = index === resXValues.length - 1
    if (isLastElement) return

    const nextX = resXValues[index + 1]

    const countSkipped = getNum(nextX) - getNum(x) - 1
    const skipped = countSkipped > 0
    const yearChanged = countSkipped < 0
    if (skipped) {
      const yIndex = xValues.length
      setYOfNewXValuesToZero(countSkipped, yIndex, res)
      xValues = addSkippedX(
        xValues,
        getYear(x),
        getNum(x) + 1,
        getNum(nextX) - 1
      )
    } else if (yearChanged) {
      const withoutSkip = countSkipped === -intervalLength // example: x = 2020-12 , nextX = 2021-01

      if (withoutSkip) {
        // do nothing
      } else {
        // example: x = 2020-09 , nextX = 2021-04
        // fill from 2020-09 to 2020-12 (according to example)
        const yIndex = xValues.length
        setYOfNewXValuesToZero(
          Math.abs(intervalLength - getNum(x)),
          yIndex,
          res
        )
        xValues = addSkippedX(
          xValues,
          getYear(x),
          getNum(x) + 1,
          intervalLength
        )

        // fill from 2021-01 to 2021-04 (according to example)
        const yIndex2 = xValues.length
        setYOfNewXValuesToZero(getNum(nextX) - 1, yIndex2, res)
        xValues = addSkippedX(xValues, getYear(nextX), 1, getNum(nextX) - 1)
      }
    }
  })

  return xValues
}

const setYOfNewXValuesToZero = (count, index, res) => {
  res.data.series.forEach((item) => {
    const zeros = new Array(count).fill(0)
    item.data.splice(index, 0, ...zeros)
  })
}

const toGermanDate = (date) =>
  new Date(date).toLocaleDateString('de-DE', {
    year: 'numeric',
    month: '2-digit',
    day: '2-digit'
  })

const addSkippedX = (arr, year, from, to) => {
  for (let i = from; i < to + 1; i++) {
    let skippedXVal = ''
    if (i < 10) {
      skippedXVal = `${year}-0${i}`
    } else {
      skippedXVal = `${year}-${i}`
    }
    arr.push(skippedXVal)
  }
  return arr
}

const getYear = (val) => +val.split('-')[0] // e.g. val = 2021-10 => 2021

const getNum = (val) => +val.split('-')[1] // e.g. val = 2021-10 => 10
