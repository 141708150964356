
<script>

import Page from './Page'

export default {

  name: 'Success',
  extends: Page
}

</script>

<template>

  <div class="page">

    <div class="screen bg-gradient">
      <div class="container pt-5">
        <div class="row justify-content-center">
          <div class="col-12 col-lg-6">
            <h2>Vielen Dank für Ihre Nachricht. Wir werden Ihr Anliegen umgehend bearbeiten und uns bei Ihnen melden.</h2>
            <router-link :to="{name:'home'}" class="btn btn-secondary btn-lg mr-4">Zur Startseite <i class="fa fa-angle-right ml-1"></i></router-link>
            <router-link :to="{name:'search'}" class="btn btn-secondary btn-lg">Zur Suche <i class="fa fa-angle-right ml-1"></i></router-link>
          </div>
        </div>
      </div>
    </div>

  </div>

</template>
