
<script>

import Page from './Page'
import Api from '../Api'
import FavoriteModal from '../components/FavoriteModal'

export default {

  name: 'Account',
  components: { FavoriteModal },
  extends: Page,

  data() {
    return {
      mode: 'details',
      contact: {},
      passwords: {},
      alert: null,

      favModalOpen: false,
      selectedFav: { id: '', title: '' }
    }
  },

  computed: {
    favorites() {
      return this.$store.state.user.instance.favorites
    }
  },

  created() {
    this.contact = {

      firstname: this.user.firstname,
      lastname: this.user.lastname
    }
  },

  mounted() {
    const mode = this.$route.params.mode
    if (mode) this.setMode(mode)
  },

  methods: {
    async deleteFavorite(id) {
      const confirmed = confirm(this.$t('account.favoriteRemoveAlert').toString())
      if (!confirmed) return

      try {
        await Api.user.favorite.delete(id)
        this.$store.commit('user/DELETE_FAVORITE', id)
      } catch (err) {
        console.error(err)
      }
    },

    async editFavorite(newTitle) {
      if (newTitle && newTitle !== this.selectedFav.title) {
        try {
          await Api.user.favorite.edit(this.selectedFav.id, newTitle)
          this.$store.commit('user/EDIT_FAVORITE', { id: this.selectedFav.id, newTitle })
          this.$store.commit(
            'SHOW_NOTIFICATION',
            {
              msg: 'Der Titel wurde aktualisiert.',
              icon: 'check'
            }
          )
        } catch (err) {
          console.error(err)
        }
      }
      this.favModalOpen = false
    },

    goToFavorite(favUrl) {
      this.$router.push(favUrl)
    },

    async setMode(mode) {
      this.mode = mode
      this.alert = null
    },

    saveContact() {
      this.$store.dispatch('user/update', this.contact).then(() => {
        this.alert = { type: 'success', message: 'account.updated' }
      })
    },

    savePassword() {
      this.alert = null

      if (!this.passwords.new || this.passwords.new.length < 8) {
        this.alert = { type: 'danger', message: 'errors.password_length' }
        return
      }

      if (this.passwords.new !== this.passwords.new_confirm) {
        this.alert = { type: 'danger', message: 'errors.passwords_not_equal' }
        return
      }

      Api.user.changePassword(this.passwords).then(() => {
        this.alert = { type: 'success', message: 'account.password_updated' }
        this.passwords = {}
      }).catch(this.alertHttpError)
    },

    selectFavToEdit(favItem) {
      this.favModalOpen = true
      this.selectedFav.id = favItem.id
      this.selectedFav.title = favItem.title
    }
  }
}

</script>

<template>

  <LayoutDetail>
    <template v-slot:side>

      <h3>Mein Account</h3>
      <hr>
      <div class="side-nav">
        <div
          class="item"
          :class="{ active: mode === 'favorites' }"
          @click="setMode('favorites')"
        >
          {{ $t('account.menu.favorites') }}
        </div>
        <div class="item" :class="{active:mode==='details'}" @click="setMode('details')">Account Details</div>
        <div class="item" :class="{active:mode==='contact'}" @click="setMode('contact')">Meine Kontaktdaten</div>
        <div class="item" :class="{active:mode==='password'}" @click="setMode('password')">Passwort ändern</div>
      </div>

    </template>
    <template v-slot:main>

      <Alert :alert="alert"></Alert>

      <div class="row justify-content-center form-white">

        <div v-if="mode === 'favorites'" class="col-12">
          <div class="card p-0">
            <div class="card-head card-title bg-gradient">
              Favoriten verwalten
            </div>
            <div class="card-body">
              <template v-if="favorites && favorites.length > 0">
                <div class="favorite" v-for="fav in favorites" :key="fav.id">
                  <div class="title" @click="goToFavorite(fav.url)">{{fav.title}}</div>
                  <div class="favorite-ctrl">
                    <i class="fa fa-pencil" aria-hidden="true" @click="selectFavToEdit(fav)"></i>
                    <span class="x" @click="deleteFavorite(fav.id)">x</span>
                  </div>
                </div>
                <FavoriteModal
                  v-if="favModalOpen"
                  action-name="Aktualisieren"
                  :name="selectedFav.title"
                  @on-action="editFavorite"
                  @on-close="favModalOpen = false"
                  :styles="{ right: '2%', top: '15%', textAlign: 'right' }"
                />
              </template>
              <template v-else>
                <p>Kein Favorit gespeichert.</p>
              </template>
            </div>
          </div>
        </div>

        <div class="col-12" v-if="mode==='details'">
          <div>
            <div class="card p-0">
              <div class="card-head card-title bg-gradient">
                Account Details
              </div>
              <div class="card-body">
                <div class="flex-row justify-content-between text-large">
                  <div>
                    <span>{{ $t('user.account_type') }}:</span>&nbsp;<span class="lightblue">{{ $t('roles.'+user.role) }}</span>
                  </div>
                  <div>
                    <span>{{ $t('user.user_id') }}:</span>&nbsp;<span class="lightblue">{{ user.id }}</span>
                  </div>
                  <div v-if="user.expiration">
                    <span>{{ $t('user.valid_until') }}:</span>&nbsp;<span class="lightblue">{{ user.expiration || nice_date }}</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="col-12 col-lg-7 col-xl-5" v-if="mode==='contact'">
          <form @submit.prevent="saveContact">
            <div class="card p-0">
              <div class="card-head card-title bg-gradient">
                Meine Kontaktdaten
              </div>
              <div class="card-body">
                <div class="form-group">
                  <label for="firstname">{{ $t('user.firstname') }}:</label>
                  <input type="text" class="form-control form-control-lg" id="firstname" v-model="contact.firstname">
                </div>
                <div class="form-group">
                  <label for="lastname">{{ $t('user.lastname') }}:</label>
                  <input type="text" class="form-control form-control-lg" id="lastname" v-model="contact.lastname">
                </div>
                <button type="submit" class="btn btn-secondary btn-block btn-lg mt-4">Speichern</button>
              </div>
            </div>
          </form>
        </div>

        <div class="col-12 col-lg-7 col-xl-5" v-else-if="mode==='password'">
          <form @submit.prevent="savePassword">
            <div class="card p-0">
              <div class="card-head card-title bg-gradient">
                Passwort ändern
              </div>
              <div class="card-body">
                <div class="form-group">
                  <label for="password_current">{{ $t('auth.password_current') }}: <abbr>*</abbr></label>
                  <input type="password" class="form-control form-control-lg" id="password_current" v-model="passwords.current">
                </div>
                <div class="form-group">
                  <label for="password_new">{{ $t('auth.password_new') }}: <abbr>*</abbr></label>
                  <input type="password" class="form-control form-control-lg" id="password_new" v-model="passwords.new">
                </div>
                <div class="form-group">
                  <label for="password_new_confirm">{{ $t('auth.password_new_confirm') }}: <abbr>*</abbr> </label>
                  <input type="password" class="form-control form-control-lg" id="password_new_confirm" v-model="passwords.new_confirm">
                </div>
                <button type="submit" class="btn btn-secondary btn-block btn-lg mt-4">{{ $t('labels.save') }}</button>
              </div>
            </div>
          </form>
        </div>

      </div>

    </template>
  </LayoutDetail>

</template>

<style lang="scss" scoped>
@import 'src/assets/sass/variables';

.favorite {
  align-items: center;
  border: 1px solid #ddd;
  border-radius: 5px;
  display: flex;
  justify-content: space-between;
  margin-bottom: 10px;
  padding: 10px;
  position: relative;
  width: 800px;

  .title {
    cursor: pointer;
    padding: 8px 0;

    &:hover {
      font-weight: bold;
    }
  }
}

.favorite-ctrl {
  align-items: center;
  display: flex;

  .fa-pencil {
    cursor: pointer;
  }

  .x {
    color: $pink;
    cursor: pointer;
    font-size: 20px;
    margin-left: 10px;
    padding: 5px;
    transition: 400ms;

    &:hover {
      transform: rotate(180deg);
    }
  }
}
</style>
