<script>

import Page from './Page'

export default {
  name: 'Terms',
  extends: Page,

  data() {
    return {

    }
  }
}

</script>

<template>

  <LayoutOverview>
    <template v-slot:top>
      <h1 class="page-title mb-3">Allgemeine Geschäftsbedingungen (AGB) für<br>ixsight now</h1>
      <h2>ein Produkt der intermedix Deutschland GmbH, Koblenz</h2>
    </template>
    <template v-slot:main>

      <p><a class="btn btn-outline-primary" href="/files/2021-04_intermedix_AGBs_ixsight_health-data.pdf" target="_blank"><i class="fa fa-download"></i> Download PDF</a></p>

      <p><strong>ixsight now</strong> ist eine Online Market Intelligence Plattform, auf der relevante Daten aus dem deutschen ambulanten Gesundheitsmarkt benutzerfreundlich und aktuell bereitgestellt werden. <strong>ixsight now</strong> ist Teil des Produkt- und Leistungsangebots der intermedix Deutschland GmbH für die pharmazeutische Industrie und Unternehmen des Gesundheitswesens.</p>

      <h3>§ 1 Geltungsbereich der AGB</h3>
      <ol>
        <li>Diese Bestimmungen regeln die Nutzung des durch die intermedix Deutschland GmbH (im folgenden „intermedix“) gegenüber dem Vertragspartner (im folgenden „Kunde“)  vertriebenen Produkts ixsight now sowie der zugehörigen Webseiten, soweit nicht individualvertraglich anders vereinbart oder durch Gesetz zwingend anders vorgeschrieben. Entgegenstehenden Bedingungen des Kunden wird hiermit widersprochen.</li>
        <li>Besucher der Produktwebseiten stimmen durch die Nutzung der Geltung dieser Bestimmungen auch für den Fall zu, dass ein besonderer Vertrag hierzu nicht zustande kommt oder seine rechtliche Wirksamkeit später beseitig wird. Sie gelten auch dann, wenn der Besucher mit Hilfe von autonom agierender Software oder in anderer Form automatisiert Zugriff auf die Seite nimmt.</li>
        <li>Das Angebot über das Produkt ixsight now richtet sich nur an Unternehmer im Sinne des § 14 BGB und nicht an Verbraucher.</li>
      </ol>

      <h3>§ 2 Leistungen von intermedix</h3>
      <ol>
        <li>intermedix ist frei in der Wahl der technischen Mittel zur Erbringung der vereinbarten Leistungen, insbesondere der eingesetzten Technologie und Infrastruktur.</li>
        <li>Von intermedix zur Verfügung gestellte kostenlose Zusatzleistungen werden keine Vertragsbestandteile. intermedix kann diese Leistungen jederzeit nach freiem Ermessen ändern und/oder einstellen. Auch die wiederholte Bereitstellung solcher Leistungen bedeutet keine Verpflichtung für die Zukunft.</li>
      </ol>

      <h3>§ 3 Angebote und Vertragsinhalt</h3>
      <ol>
        <li>Das Angebot von Leistungen über die Website des Produkts ixsight now sowie die in Prospekten, Preislisten, Katalogen, Rundschreiben usw. enthaltenen Angaben, insbesondere Abbildungen, Beschreibungen, technische Daten und Leistungsbeschreibungen, sind unverbindlich, sofern sie nicht als verbindliches Angebot gesondert beschrieben sind.</li>
        <li>Der Kunde gibt entweder schriftlich oder online durch Betätigung des Kaufbuttons ein verbindliches Angebot ab. Das Angebot des Kunden wird durch das Übersenden einer Auftragsbestätigung, Rechnung oder Bestellbestätigung, in der Inhalt und Umfang der bestellten Leistung zusammengefasst ist, von intermedix angenommen.</li>
      </ol>

      <h3>§ 4 Nutzungsrechte</h3>
      <ol>
        <li>Der Kunde ist nicht berechtigt, die Leistungen von intermedix Dritten zur Nutzung zu überlassen.</li>
        <li>Eine Verwendung der Informationen des Produkts für eigene Werbezwecke des Kunden sind mit intermedix abzustimmen.</li>
      </ol>

      <h3>§ 5 Zahlungen, Preisanpassung und Laufzeit</h3>
      <ol>
        <li>Sofern nicht individuell oder in den Produktblättern abweichend vereinbart, sind Zahlungen bei Abschluss der Buchung für die gesamte Laufzeit fällig.</li>
        <li>Die Rechnungen von intermedix sind, soweit keine abweichende Vereinbarung getroffen wurde, spätestens innerhalb von 14 Tagen ab Rechnungsdatum ohne Abzug zu zahlen.</li>
        <li>Bei Zahlungsverzug des Kunden stehen intermedix die gesetzlichen Verzugszinsen zu.</li>
        <li>Einwendungen gegen eine Rechnung hat der Kunde, sofern er Unternehmer ist, in schriftlich oder Textform innerhalb von 14 Tagen ab Rechnungsdatum anzuzeigen.</li>
        <li>intermedix behält sich vor, beim vorliegenden Dauerschuld-verhältnis über das Produkt ixsight now die Vergütung mit schriftlicher Anzeige und einer Ankündigungsfrist von drei Monaten bei Veränderung der die Kosten der Leistungen beeinflussenden Faktoren (Umsetzung gesetzlicher oder behördlicher, die Leistung betreffender Vorgaben, Personal-, Material- und Arbeitsmittel- kosten, Preiserhöhung von Lieferanten) entsprechend der Veränderung dieser Faktoren und ihrem Anteil an der Vergütung anzupassen. Eine Änderung der Vergütung kann durch intermedix mit schriftlicher Anzeige innerhalb der gleichen Frist auch erfolgen, wenn und soweit die vereinbarte Vergütung aus anderen Gründen nicht mehr marktüblich oder angemessen ist. intermedix setzt in diesem Fall die Änderung der Vergütung nach billigem Ermessen (§ 315 BGB) fest. Die geänderte Vergütung wird in keinem Fall die zum Zeitpunkt des Wirksamwerdens der Änderung die für die betroffenen Leistungen allgemein geltende Listenpreise der intermedix überschreiten. Wird die Vergütung für die betroffene Leistung innerhalb eines Vertragsjahres insgesamt um mehr als zehn Prozent erhöht, kann der Kunde den Vertrag unter Einhaltung einer Frist von einem Monat zum angekündigten Zeitpunkt des Inkrafttretens der Vergütungserhöhung kündigen.</li>
        <li>Die Mindestvertragslaufzeit beträgt 12 Monate nach Vertrags-abschluss, sofern nichts anderes vereinbart wurde. Nach Ablauf der Mindestvertragslaufzeit verlängert sich der Vertrag automatisch um denselben Zeitraum, wenn die Vereinbarung nicht sechs Wochen vor Ablauf gekündigt wird.</li>
        <li>Das Recht zur fristlosen Kündigung aus wichtigen Gründen bleibt unberührt. Ein wichtiger Grund, der intermedix zur fristlosen Kündigung berechtigt, liegt insbesondere dann vor, wenn der Kunde mit der Zahlung der Vergütung mehr als zwei Monate oder mit Teilbeträgen, deren Gesamtsumme mehr als zwei Monatsentgelte betragen, in Verzug ist. Ist intermedix zur fristlosen Kündigung berechtigt, hat intermedix einen sofort fälligen Schadensersatzanspruch gegen den Kunden in Höhe der noch ausstehenden - soweit noch nicht fälligen, unter Abzug sämtlicher ersparter Kosten und Aufwendungen - Vergütung sowie den nachgewiesenen Kosten aus der Beendigung, sofern nicht der Kunde einen niedrigeren oder intermedix einen höheren Schaden nachweist. Statt zu kündigen kann intermedix bei Vorliegen eines wichtigen Grundes den Zugang sperren, bis der wichtige Grund vom Kunden beseitigt wurde.</li>
        <li>Jede Kündigung bedarf der Textform.</li>
      </ol>

      <h3>§ 6 Pflichten des Kunden</h3>
      <ol>
        <li>Der Kunde sichert zu, dass die von ihm mitgeteilten Kontaktdaten richtig und vollständig sind und verpflichtet sich, intermedix unverzüglich über Änderungen der mitgeteilten Daten, zu unterrichten. Bei Identitätsmissbrauch ist intermedix berechtigt, den Zugang zu sperren.</li>
        <li>Ist die mitgeteilte E-Mailadresse eines Kunden falsch, gleichgültig ob von ihm falsch eingegeben oder durch Wechsel falsch geworden, so ist intermedix berechtigt, den Kunden per Post zu kontaktieren und für die dafür entstehenden Kosten pauschal Ersatz zu verlangen. Der Kunde und intermedix gehen davon aus, dass eine Kostenpauschale von zehn Euro pro Brief angemessen ist. Dem Kunden steht der Nachweis geringerer Kosten offen.</li>
        <li>Der Kunde hat intermedix offensichtliche Mängel innerhalb einer Frist von zwei Wochen ab Erhalt der Leistung, bei verdeckten Mängeln innerhalb von zwei Wochen ab Erkennen, anzuzeigen. Mängelhaftungsansprüche sind nach Ablauf dieser Frist ausgeschlossen. Zur Fristwahrung reicht die rechtzeitige Absendung der Mängelanzeige.</li>
        <li>Der Kunde versichert, dass er keine Inhalte auf dem vertrags-gegenständlichen Account speichert oder im Rahmen der Nutzung der Plattform keine Äußerungen oder Handlungen vornimmt, deren Bereitstellung, Veröffentlichung und Nutzung gegen gesetzliche Verbote, die guten Sitten oder Rechte Dritter verstößt. Die Nutzung darf nur zu gesetzlich erlaubten Zwecken und unter Beachtung sämtlicher gesetzlicher Bestimmungen erfolgen.</li>
        <li>Der Kunde ist verpflichtet, die Zugangsdaten gegenüber unbefugten Dritten geheim zu halten und Passwörter regelmäßig zu ändern. Der Kunde ist verpflichtet sich, intermedix unverzüglich zu informieren, sobald er davon Kenntnis erlangt, dass unbefugten Dritten die Login-Daten bekannt sind. Der Kunde haftet für einen von ihm zu vertretenden Missbrauch. Im Falle einer unberechtigten Nutzungsüberlassung ist intermedix berechtigt, den Zugang zu sperren.</li>
        <li>Ist intermedix nach diesen AGB berechtigt, den Zugang zu sperren, so ist der Kunde über die Sperrung unter Angabe der Gründe unverzüglich zu benachrichtigen und aufzufordern, den Verstoß abzustellen oder die Rechtmäßigkeit nachweisbar darzulegen. Die Sperrung ist aufzuheben, sobald innerhalb einer angemessenen Frist der Verstoß abgestellt wurde oder der Kunde nachgewiesen hat, dass kein Verstoß vorlag. Erfolgt der Nachweis nicht, ist intermedix zur außerordentlichen Kündigung des Vertragsverhältnisses berechtigt.</li>
      </ol>

      <h3>§ 7 Haftung</h3>
      <ol>
        <li>Auswertungen des Produkts ixsight now enthalten verallgemeinerte Informationen, die keine sichere Grundlage für Entscheidungen im Einzelfall darstellen und zudem fehlerhaft oder interpretationsbedürftig sein können, ohne dass dies bei der Nutzung ohne weiteres erkennbar ist. intermedix haftet daher nicht für Schäden, die durch die Verwendung der auf den Websites angebotenen Statistiken und Auswertungen entstehen. Der Kunde hat zu beachten, dass angezeigte Informationen und Dokumente je nach Aktualisierungszyklus zwischenzeitlich einem Update unterliegen können.</li>
        <li>Die Haftung für anfängliche Mängel gem. § 536a Abs. 1 1.Alt. BGB ist ausgeschlossen.</li>
        <li>intermedix behält sich vor, über sein Produkt auf die Angebote oder Informationen von Dritten hinzuweisen. Diese Hinweise stellen keine Empfehlung dar und intermedix macht sich die Informationen des Dritten damit nicht zu eigen.</li>
        <li>Für Schäden aufgrund von Vorsatz oder grober Fahrlässigkeit, aus der Verletzung des Lebens, des Körpers oder der Gesundheit, im Rahmen des Produkthaftungsgesetzes sowie bei der Nichterfüllung gegebenenfalls übernommener Garantien, haftet die intermedix gemäß den gesetzlichen Bestimmungen.</li>
        <li>Bei leicht fahrlässiger Verletzung wesentlicher Vertrags-pflichten (= Kardinalpflichten) ist die Haftung auf den vertragstypischen vorhersehbaren Schaden begrenzt, wobei der Begriff der wesentlichen Vertragspflichten abstrakt solche Pflichten bezeichnet, deren Erfüllung die ordnungsgemäße Durchführung des Vertrags überhaupt erst ermöglicht und auf deren Einhaltung die jeweils andere Partei regelmäßig vertrauen darf. Eine darüberhinausgehende Haftung für leicht fahrlässigen Verletzungen besteht nicht.</li>
        <li>Im Falle des Datenverlustes und damit verbundener Folgeschäden haftet intermedix nur im Umfang derjenigen Kosten, die bei dem Kunden für die Wiederherstellung der Daten aus den vertragsgemäßen Sicherungskopien des Kunden anfallen.</li>
        <li>Die vorstehenden Haftungsbeschränkungen gelten auch für die persönliche Haftung der Mitarbeiter, Vertreter und Organe der intermedix.</li>
      </ol>

      <h3>§ 8 Geheimhaltung</h3>
      <p>Die Parteien verpflichten sich, über alle ihnen im Rahmen der Vertragsbeziehung zur Kenntnis gelangten vertraulichen Vorgänge, insbesondere Geschäfts- oder Betriebsgeheimnisse strengstes Stillschweigen zu bewahren und diese weder weiterzugeben noch auf sonstige Art zu verwerten. Dies gilt gegenüber jeglichen nichtberechtigten Dritten, d. h. auch gegenüber nichtberechtigten Mitarbeitern, sofern die Weitergabe von Informationen nicht zur ordnungsgemäßen Erfüllung der vertraglichen Verpflichtungen erforderlich ist.</p>

      <h3>§ 9 Sonstiges</h3>
      <ol>
        <li>intermedix behält sich vor, diese AGB jederzeit (z.B. bei Veränderung der Gesetzeslage, höchstrichterlichen Rechtsprechung oder der Marktgegebenheiten) unter Wahrung einer angemessenen Ankündigungsfrist von mindestens 6 Wochen zu ändern. Die Ankündigung erfolgt durch Veröffentlichung der geänderten Allgemeinen Geschäftsbedingungen unter Angabe des Zeitpunkts des Inkrafttretens im Internet auf der Webseite www.ixsight.de sowie durch separaten Hinweis auf den Rechnungen oder sonstigen Mitteilungen. Widerspricht der Kunde nicht innerhalb von sechs Wochen nach Ankündigung der Änderungen, so gelten die abgeänderten Geschäftsbedingungen als angenommen. In der Ankündigung der Änderung wird gesondert auf die Bedeutung der Sechswochenfrist hingewiesen.</li>
        <li>Der Kunde kann Rechte aus dem Vertrag nur mit Zustimmung von intermedix auf Dritte übertragen.</li>
        <li>Zurückbehaltungs- oder Aufrechnungsrechte des Kunden sind aus-geschlossen, es sei denn, die Gegenforderung des Kunden ist von intermedix anerkannt oder rechtskräftig festgestellt.</li>
        <li>Sofern der Kunde Kaufmann ist, gilt der Geschäftssitz von intermedix als vereinbarter Erfüllungsort und Gerichtsstand.</li>
        <li>Sofern der Kunde Unternehmer ist, gilt das Recht der Bundesrepublik Deutschland unter Ausschluss von UN-Kaufrecht.</li>
        <li>intermedix ist von der Verpflichtung zur Leistung aus diesem Vertrag befreit, wenn und soweit die Nichterfüllung auf das Eintreten von Umständen höherer Gewalt nach Vertragsabschluss zurückzuführen ist. Entsprechend entfällt die Gegenleistungspflicht des Kunden. Als Umstände höherer Gewalt gelten zum Beispiel Kriege, Streiks, Unruhen, Enteignungen, kardinale Rechtsänderungen, Epidemien, Sturm, Überschwemmungen und sonstige Naturkatastrophen sowie sonstige von intermedix nicht zu vertretende Umstände. Jede Vertragspartei hat die andere Vertragspartei über den Eintritt eines Falles von höherer Gewalt unverzüglich in Kenntnis zu setzen.</li>
      </ol>

      <p>Stand: April 2021</p>

    </template>
  </LayoutOverview>

</template>