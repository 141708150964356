import Vue from 'vue'
import VueRouter from 'vue-router'

import Store from '../store/store'
import Home from '../pages/Home'
import Account from '../pages/Account'
import Users from '../pages/Users'
import NotFound from '../pages/NotFound'
import ResetPassword from '../pages/ResetPassword'
import Search from '../pages/Search'
import Contact from '../pages/Contact'
import Success from '../pages/Success'
import Imprint from '../pages/Imprint'
// import About from '../pages/About'
import PznInfo from '../pages/PznInfo'
import StatisticOverview from '../pages/StatisticOverview'
import StatisticDetail from '../pages/StatisticDetail/StatisticDetail'
import Method from '../pages/Method'
import Studies from '../pages/Studies'
import Terms from '../pages/Terms'
import Privacy from '../pages/Privacy'
import BenchmarksOverview from '../pages/BenchmarksOverview'
import BenchmarksDetail from '../pages/BenchmarksDetail'

Vue.use(VueRouter)

const SHOULD_AUTH = true

const router = new VueRouter({
  base: '/',
  mode: 'history',
  scrollBehavior: function (to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition
    } else if (to.hash) {
      setTimeout(() => {
        const element = document.getElementById(to.hash.replace(/#/, ''))
        if (element && element.scrollIntoView) {
          element.scrollIntoView({ block: 'start', behavior: 'smooth' })
        }
      }, 250)
    }
  },
  routes: [
    { path: '', component: Home, name: 'home', meta: { title: 'ixsight now' } },
    {
      path: '/account/:mode?',
      component: Account,
      name: 'account',
      meta: { title: 'Account', auth: SHOULD_AUTH }
    },
    {
      path: '/users',
      component: Users,
      name: 'users',
      meta: { title: 'Benutzer', auth: SHOULD_AUTH }
    },
    {
      path: '/reset_password/:token',
      component: ResetPassword,
      name: 'resetPassword',
      meta: { title: 'Passwort' }
    },
    // { path: '/about', component: About, name: 'about', meta: { title: 'Über uns' } },
    {
      path: '/contact',
      component: Contact,
      name: 'contact',
      meta: { title: 'Kontakt' }
    },
    {
      path: '/success',
      component: Success,
      name: 'success',
      meta: { title: 'Kontakt' }
    },
    {
      path: '/imprint',
      component: Imprint,
      name: 'imprint',
      meta: { title: 'Impressum' }
    },
    {
      path: '/method',
      component: Method,
      name: 'method',
      meta: { title: 'Methodik' }
    },
    {
      path: '/studies',
      component: Studies,
      name: 'studies',
      meta: { title: 'Studien' }
    },
    { path: '/terms', component: Terms, name: 'terms', meta: { title: 'AGB' } },
    {
      path: '/privacy',
      component: Privacy,
      name: 'privacy',
      meta: { title: 'Datenschutz' }
    },
    {
      path: '/pzn/:pzn?',
      component: PznInfo,
      name: 'pzn-info',
      meta: { title: 'PZN-Info', auth: SHOULD_AUTH }
    },
    {
      path: '/healthdata',
      component: Search,
      name: 'search',
      meta: { title: 'Suche' }
    },
    {
      path: '/healthdata/:entity/:code',
      component: StatisticOverview,
      name: 'statistics.index',
      meta: { title: 'Statistiken' }
    },
    {
      path: '/healthdata/:entity/:code/:id',
      component: StatisticDetail,
      name: 'statistics.show',
      meta: { title: 'Statistiken', auth: SHOULD_AUTH }
    },
    {
      path: '/benchmarks',
      component: BenchmarksOverview,
      name: 'benchmarks.index',
      meta: { title: 'Benchmarks', auth: SHOULD_AUTH }
    },
    {
      path: '/benchmarks/:entity',
      component: BenchmarksDetail,
      name: 'benchmarks.show',
      meta: { title: 'Benchmarks', auth: SHOULD_AUTH }
    },
    {
      path: '/files/*ixsight_health_data-Anleitung_und_Methodik_public.pdf',
      beforeEnter() {
        location.href = '/files/ixsight-now_Anleitung-und-Methodik_public.pdf'
      }
    },
    {
      path: '/files/*ixsight_health_data-Anleitung_und_Methodik_full.pdf',
      beforeEnter() {
        location.href = '/files/ixsight-now_Anleitung-und-Methodik_full.pdf'
      }
    },
    {
      path: '/not-found',
      component: NotFound,
      name: 'notfound',
      meta: { title: 'Not Found!' }
    },
    { path: '*', component: NotFound }
  ]
})

router.beforeEach((to, from, next) => {
  if (!Store.getters.isReady) {
    Store.dispatch('getReady').then(() => {
      return middleware(to, from, next)
    })
  } else {
    return middleware(to, from, next)
  }
})

router.afterEach((to, from) => {
  const title = to.meta.title || 'health data'

  document.title = title + ' - ixsight'

  if (typeof window._paq === 'object' && window._paq) {
    window._paq.push(['setCustomUrl', to.path])
    window._paq.push(['setDocumentTitle', title])
    window._paq.push(['trackPageView'])
  }

  if (to.name !== from.name) {
    setTimeout(() => {
      window.scrollTo(0, 0)
    }, 200)
  }
})

function middleware(to, from, next) {
  if (to.meta.auth) {
    if (!Store.getters['user/check']) {
      Store.commit('nextRoute', to)
      Store.commit('showAuth', 'login')
      return
    }
  }

  return next()
}

export default router
